import React from 'react';
import { Container } from 'reactstrap';

import Tabs from './tabs';
import CardsList from './cards-list';
import FiltersMenu from './filters-menu';
import MandateModal from './mandate-modal';
import Paginator from './paginator';

const Mandate = (props) => {
  return (
    <Container fluid>
      <MandateModal {...props} />
      <Tabs tid={props.filters?.status} onChange={props.onFiltersChange} />
      <FiltersMenu {...props} />
      <CardsList {...props} />
      <Paginator {...props} />
    </Container>
  );
};

export default Mandate;
