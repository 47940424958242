import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    listVerificationsRequest: ['filters'],
    listVerificationsSuccess: ['data'],
    listVerificationsFailure: ['error'],

    addVerificationRequest: ['data'],
    addVerificationSuccess: ['data'],
    addVerificationFailure: ['error'],

    exemptVerificationRequest: ['data'],
    exemptVerificationSuccess: ['data'],
    exemptVerificationFailure: ['error'],
  },
  {}
);
