import { call, put, takeLeading } from 'redux-saga/effects';

import {
  Creators as CountriesCreators,
  Types as CountryTypes,
} from '../../redux/countries/actions';
import { toastErrorResponse } from '../errors';
import { getCountries as getCountryApi } from '../../api/operators';

export function* getCountries() {
  try {
    const response = yield call(getCountryApi);
    yield put(
      CountriesCreators.getCountriesSuccess(response.data.data.results)
    );
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(CountriesCreators.getCountriesFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* watchCountries() {
  yield takeLeading(CountryTypes.GET_COUNTRIES_REQUEST, getCountries);
}
