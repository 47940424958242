import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Form, FormGroup, Input, Button, FormFeedback } from 'reactstrap';
import { Card } from 'react-bootstrap';
import useSiteForm from './use-site-form';
import FloatedLabelInput from '../../../components/FloatedLabelInput';

import styles from './sites.module.scss';
import { APPLICATION_STATUSES } from '../../../constants';

const SiteForm = ({
  site,
  countries,
  operators,
  operator,
  operatorData,
  hasNewSite,
  hasNewSiteSet,
}) => {
  const [isEditable, isEditableSet, formik, deleteSite] = useSiteForm({
    site,
    hasNewSite,
    hasNewSiteSet,
  });
  React.useEffect(
    (value) => {
      if (site.is_new) {
        isEditableSet(true);
      }
    },
    [site, isEditableSet]
  );

  return (
    <Card className={styles.card}>
      <Card.Header className={styles.cardHeader}>
        {' '}
        {site.company_name ? site.company_name : 'New Site'}
        {!site.is_new &&
        operatorData.application_status === APPLICATION_STATUSES.REQUESTED ? (
          <Button className={styles.isEditable} onClick={deleteSite}>
            <FontAwesomeIcon className={styles.headerIcon} icon={faTrash} />
          </Button>
        ) : null}
        <Button
          className={styles.isEditable}
          onClick={() => isEditableSet(!isEditable)}
        >
          {isEditable ? (
            <FontAwesomeIcon className={styles.headerIcon} icon={faMinus} />
          ) : null}
          {!isEditable ? (
            <FontAwesomeIcon className={styles.headerIcon} icon={faPlus} />
          ) : null}
        </Button>
      </Card.Header>
      {isEditable ? (
        <Card.Body>
          <Form onSubmit={formik.handleSubmit}>
            <FloatedLabelInput
              label="company.name"
              name="company_name"
              value={formik.values.company_name}
              onChange={formik.handleChange}
              error={formik.errors.company_name}
              disabled={
                operatorData.application_status !==
                APPLICATION_STATUSES.REQUESTED
              }
            />
            <FloatedLabelInput
              label="site.contact_name"
              name="contact_person_name"
              value={formik.values.contact_person_name}
              error={formik.errors.contact_person_name}
              onChange={formik.handleChange}
              disabled={
                operatorData.application_status !==
                APPLICATION_STATUSES.REQUESTED
              }
            />
            <FloatedLabelInput
              label="site.email"
              name="contact_person_email"
              value={formik.values.contact_person_email}
              error={formik.errors.contact_person_email}
              onChange={formik.handleChange}
              disabled={
                operatorData.application_status !==
                APPLICATION_STATUSES.REQUESTED
              }
            />
            <FloatedLabelInput
              label="site.phone"
              name="contact_person_msisdn"
              value={formik.values.contact_person_msisdn}
              error={formik.errors.contact_person_msisdn}
              onChange={formik.handleChange}
              disabled={
                operatorData.application_status !==
                APPLICATION_STATUSES.REQUESTED
              }
            />
            <FormGroup>
              <label>Type of operation</label>
              <Input
                name="type_of_operation"
                value={formik.values.type_of_operation}
                onChange={formik.handleChange}
                invalid={formik.errors.type_of_operation}
                type="select"
                disabled={
                  operatorData.application_status !==
                  APPLICATION_STATUSES.REQUESTED
                }
              >
                <option value=""></option>
                {operators.map((value, key) => {
                  return (
                    <option key={key} value={value}>
                      {' '}
                      {value}{' '}
                    </option>
                  );
                })}
              </Input>
              <FormFeedback>{formik.errors.type_of_operation}</FormFeedback>
            </FormGroup>

            <FormGroup>
              <label>Country</label>
              <Input
                type="select"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                invalid={formik.errors.country}
                disabled={
                  operatorData.application_status !==
                  APPLICATION_STATUSES.REQUESTED
                }
              >
                <option value=""></option>
                {countries.map((value, key) => {
                  return (
                    <option key={key} value={value.id}>
                      {' '}
                      {value.name}{' '}
                    </option>
                  );
                })}
              </Input>
              <FormFeedback>{formik.errors.country}</FormFeedback>
            </FormGroup>

            <FormGroup>
              <label> Address</label>
              <Input
                type="textarea"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                invalid={formik.errors.address}
                disabled={
                  operatorData.application_status !==
                  APPLICATION_STATUSES.REQUESTED
                }
              />
              <FormFeedback>{formik.errors.address}</FormFeedback>
            </FormGroup>

            <FormGroup>
              {operatorData.application_status ===
                APPLICATION_STATUSES.REQUESTED && (
                <Button type="submit">Save</Button>
              )}
            </FormGroup>
          </Form>
        </Card.Body>
      ) : null}
    </Card>
  );
};

export default SiteForm;
