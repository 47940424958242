/* Base Urls */
export const BASE_URL = process.env.REACT_APP_API_URL;
// export const BASE_URL = 'http://127.0.0.1:8000';

/* Auth endpoints */
export const LOGIN_URL = `${BASE_URL}/authenticate`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}/users/request/password`;
export const RESET_PASSWORD_URL = `${BASE_URL}/users/reset/password`;
/* User endponts */
export const USERS_URL = `${BASE_URL}/users/user`;
export const USERS_CHANGE_PASSWORD_URL = `${BASE_URL}/users/changepass`;
export const USERS_ACTIVATE_URL = `${BASE_URL}/users/activate`;
export const USERS_DEACTIVATE_URL = `${BASE_URL}/users/deactivate`;
export const GROUPS_ADD_USER_URL = `${BASE_URL}/users/addgroup`;
export const GROUPS_REMOVE_USER_URL = `${BASE_URL}/users/removegroup`;
/* Group endponts */
export const GROUPS_URL = `${BASE_URL}/users/groups`;
export const GROUPS_PERMISSIONS_URL = `${BASE_URL}/users/permissions`;
export const GROUPS_ACTIVATE_URL = `${BASE_URL}/users/group/activate`;
/* Operators endponts */
export const OPERATORS_USER_URL = `${BASE_URL}/users/operator/`;
export const OPERATORS_URL = `${BASE_URL}/operators/operator`;
export const OPERATORS_COMMIT_URL = `${BASE_URL}/operators/application_commit`;
export const OPERATORS_VERIFICATION_URL = `${BASE_URL}/operators/verification`;
export const OPERATORS_URL_MANDATE = `${BASE_URL}/operators/application_mandate`;
export const OPERATORS_URL_STATUS = `${BASE_URL}/operators/application_status`;
export const OPERATORS_COUNTRIES_URL = `${BASE_URL}/operators/countries`;
export const OPERATORS_AIRPORTS_URL = `${BASE_URL}/operators/airports`;
export const OPERATORS_SITES_URL = `${BASE_URL}/operators/sites`;
export const OPERATORS_COLLECTOR_URL = `${BASE_URL}/operators/collectors`;
export const OPERATORS_ORGANIZATIONS_URL = `${BASE_URL}/operators/organizations`;

/* SCHEDULE endpoints */
export const SCHEDULE_URL = `${BASE_URL}/operators/schedule`;
