export default {
  next: 'Next',

  'users.create': 'Create user',
  'groups.create': 'Create group',
  'groups.update': 'Edit group',
  'groups.edit': 'Edit group',
  'users.login': 'Log In',
  'users.first.name': 'First Name',
  'users.last.name': 'Last Name',
  'users.phone.number': 'Phone Number',
  'users.email': 'Email',
  'users.email.address': 'Email Address',
  'users.password': 'Password',
  'users.old_password': 'Old Password',
  'users.new.password': 'New Password',
  'users.confirm_password': 'Retype New Password',
  'users.reset.password.success.header': 'Your password has been reset!',
  'users.reset.password.header': 'Reset Password',
  'users.reset.password.subheader': 'Enter your new password below',
  'users.reset.password.hint':
    'Hint: The password should be at least seven characters long. To make it stronger, use upper and lower case letters, numbers, and symbols like ! " ? $ % ^ & ).',
  'users.reset.password.submit': 'Reset',
  'users.forgot.password': 'Forgot Password',
  'users.forgot.password.header': 'Forgot Password',
  'users.forgot.password.desc':
    'Please enter your email. {break} You will receive a link to create a new password via email.',
  'users.forgot.password.submit': 'Get New Password',
  'users.login.coverimg.header': 'Verification Planning {break} Management',
  'users.resend.password.header': 'Get new confirmation link',
  'users.resend.password.hint': "Didn't get link?",
  'users.resend.password.submit': 'Resend Link',
  'users.tabs.groups.title': 'Groups',
  'users.tabs.users.title': 'Users',
  'users.tabs.groups.subtitle':
    'Create new groups, customize group permissions, add or remove users from groups.',
  'users.tabs.users.subtitle': 'Create new users and add users to groups.',

  'company.name': 'Company name',
  'company.organization_name': 'Organization name',
  'company.address': 'Company Address',
  'company.country': 'Country',
  'company.approximate_time_to_airport': 'Approximate time to airport',
  'company.type_of_operation': 'Type of operation',
  'company.nearest_airport': 'Nearest airport',
  'company.nearest_time_to_airport': 'Nearest time to Airport',
  'site.contact_name': 'Contact Person Full Name',
  'site.email': 'Contact Person Email',
  'site.phone': 'Contact Person Phone ',
  'collectors.vrac_vanilla_volume': 'Vrac vanilla',
  'collectors.green_vanilla_volume': 'Green vanilla',
  'collectors.numbers_of_farmers': 'Number of Farmers',
  'collectors.average_total_surface_cultivated':
    'Total Surface Area Cultivated',
  'applications.status.all': 'All',
  'applications.status.requested': 'Requested',
  'applications.status.applied': 'Applied',
  'applications.status.committed': 'Committed',
  'applications.status.rejected': 'Rejected',

  'mandate.status.all': 'All',
  'mandate.status.pending': 'Pending',
  'mandate.status.mandated': 'Mandated',

  'results.status.all': 'All',
  'results.status.notstarted': 'Not Started',
  'results.status.inprocess': 'In Process',
  'results.status.exempted': 'Exempted',
  'results.status.finalized': 'Finalized',
};
