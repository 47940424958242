import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { Creators as VerificationsCreators } from 'services/redux/verifications/actions';
import { htmlFormatDate, getTomorrow } from 'services/intl/datetimeformat';

import styles from './exempt-modal.module.scss';

const validationSchema = Yup.object({
  verification_validity: Yup.date()
    .min(
      htmlFormatDate(getTomorrow()),
      'Verification Validity must be future date.'
    )
    .required('Verification Validity Date is required field.'),
  next_verification: Yup.date()
    .min(
      htmlFormatDate(getTomorrow()),
      'Next Verification must be future date.'
    )
    .required('Next Verification Date is required field.'),
});

const initialValues = {
  outcomes: 0,
  verification_validity: '',
  next_verification: '',
  status: 'exempted',
  operator: '',
};

export const ExemptModal = (props) => {
  const { toggleExemptModal } = props;
  const dispatch = useDispatch();

  const onSubmit = React.useCallback(
    (values) => {
      dispatch(VerificationsCreators.exemptVerificationRequest(values));
      toggleExemptModal();
    },
    [dispatch, toggleExemptModal]
  );

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      ...initialValues,
      operator: props.verification?.id,
      id: props.verification?.verification?.id,
    },
    onSubmit,
  });

  return (
    <Modal
      centered
      isOpen
      toggle={toggleExemptModal}
      className={styles.modal}
      size="lg"
    >
      <Form onSubmit={formik.handleSubmit}>
        <ModalHeader toggle={toggleExemptModal} className={styles.modalHeader}>
          Exempt Verification
        </ModalHeader>

        <ModalBody className={styles.modalBody}>
          <Row>
            <Col>
              <FormGroup>
                <Label>Company name</Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>{props.verification?.company_name}</Label>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label>Verification Validity Date</Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Input
                  type="date"
                  name="verification_validity"
                  min={htmlFormatDate(new Date())}
                  value={formik.values.verification_validity}
                  onChange={formik.handleChange}
                  invalid={!!formik.errors.verification_validity}
                />
                <FormFeedback>
                  {formik.errors.verification_validity}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label>Next Verification Date</Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Input
                  type="date"
                  name="next_verification"
                  min={htmlFormatDate(new Date())}
                  value={formik.values.next_verification}
                  onChange={formik.handleChange}
                  invalid={!!formik.errors.next_verification}
                />
                <FormFeedback>{formik.errors.next_verification}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter className={styles.modalFooter}>
          <Button type="submit" color="primary">
            Exempt
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default (props) => {
  if (!props.isExemptedOpen) {
    return null;
  }

  return <ExemptModal {...props} />;
};
