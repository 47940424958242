import React from 'react';
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';
import classnames from 'classnames';
import CsvDownloader from 'react-csv-downloader';
import { FaSearch, FaFileExport } from 'react-icons/fa';

import { ReactComponent as MandateIcon } from 'assets/icons/mandate.svg';
import { htmlFormatDate } from 'services/intl/datetimeformat';

import styles from './filters-menu.module.scss';

const COLUMNS = [
  {
    id: 'company_name',
    displayName: 'Company Name',
  },
  {
    id: 'profile',
    displayName: 'Profile',
  },
  {
    id: 'status',
    displayName: 'Status',
  },
  {
    id: 'change_status_date',
    displayName: 'Change of Status Date',
  },
  {
    id: 'outcomes',
    displayName: 'Outcomes',
  },
  {
    id: 'verification_validity',
    displayName: 'Verification Validity',
  },
  {
    id: 'next_verification',
    displayName: 'Next Verification',
  },
];

const toCSVData = (data) => {
  return data.map((row) => ({
    company_name: row.company_name,
    profile: row.company_type || row.type_of_operation,
    status:
      row.verification_status === 'in progress'
        ? 'in process'
        : row.verification_status,
    change_status_date: htmlFormatDate(row.verification?.change_status_date),
    outcomes: row.verification?.outcomes,
    verification_validity: row.verification?.verification_validity,
    next_verification: row.verification?.next_verification,
  }));
};

const FiltersMenu = (props) => {
  const {
    onFiltersChange,
    toggleAddModal,
    toggleExemptModal,
    verifications,
  } = props;
  const [csvData, csvDataSet] = React.useState([]);

  const onSearch = React.useCallback(
    (e) => {
      onFiltersChange({ search: e.target.value });
    },
    [onFiltersChange]
  );

  const status = props.verification?.verification_status;

  const canVerify = status === 'in progress' || status === 'exempted';
  const canExempt = status === 'in progress' || status === 'finalized';

  React.useEffect(() => {
    csvDataSet(toCSVData(verifications));
  }, [verifications, csvDataSet]);

  return (
    <Container className={styles.container} fluid>
      <Row>
        <Col lg={8}>
          <div
            className={classnames(styles.actionTab, {
              [styles.active]: canVerify,
            })}
            onClick={canVerify ? toggleAddModal : undefined}
          >
            <MandateIcon />
            Add results
          </div>
          <div
            className={classnames(styles.actionTab, {
              [styles.active]: canExempt,
            })}
            onClick={canExempt ? toggleExemptModal : undefined}
          >
            <MandateIcon />
            Exempt
          </div>

          <div className={classnames(styles.actionTab, styles.active)}>
            <CsvDownloader
              datas={csvData}
              filename="results.csv"
              separator=","
              columns={COLUMNS}
            >
              <FaFileExport /> Export Csv
            </CsvDownloader>
          </div>
        </Col>

        <Col lg={4}>
          <InputGroup
            className={classnames('mb-2 mr-sm-2 mb-sm-0', styles.input)}
          >
            <Input
              type="text"
              name="search"
              value={props.filters?.search}
              placeholder="search"
              onChange={onSearch}
            />
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                {' '}
                <FaSearch />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default FiltersMenu;
