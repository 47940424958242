import React from 'react';
import { withLayout } from 'containers/Layout';
import { useDispatch, useSelector } from 'react-redux';

import { Creators as OperatorsCreators } from 'services/redux/operators/actions';
import OperatorsVerification from './components';
import { ROUTES } from 'constants.js';
import { history } from 'history.js';

const defaultFilters = {
  page_size: 10,
  page: 1,
  status: 'mandated',
  search: '',
};

const Screen = () => {
  const dispatch = useDispatch();
  const operators = useSelector((store) => store.operators.list);
  const [selectedOperator, selectedOperatorSet] = React.useState('');
  const [filters, filtersSet] = React.useState(defaultFilters);
  const pagination = useSelector((store) => store.operators.pagination);

  const goToOperatorCompanies = React.useCallback(() => {
    if (selectedOperator) {
      history.push(
        ROUTES.PLAN_VERFICATION.COMPANIES.replace(':id', selectedOperator)
      );
    }
  }, [selectedOperator]);

  const onFiltersChange = React.useCallback(
    (values) => {
      if ('page' in values && +values.page !== +filters.page) {
        filtersSet({ ...filters, ...values });
      } else {
        filtersSet({ ...defaultFilters, status: filters.status, ...values });
      }
      selectedOperatorSet('');
    },
    [filters, filtersSet, selectedOperatorSet]
  );

  React.useEffect(() => {
    dispatch(
      OperatorsCreators.listOperatorsRequest({
        ...filters,
      })
    );
  }, [filters, dispatch]);

  return (
    <OperatorsVerification
      selected={selectedOperator}
      selectedSet={selectedOperatorSet}
      operators={operators}
      goToOperatorCompanies={goToOperatorCompanies}
      filters={filters}
      onFiltersChange={onFiltersChange}
      pagination={pagination}
    />
  );
};

export default withLayout(Screen);
