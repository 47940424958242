import React from 'react';
import { Container, Row } from 'reactstrap';

import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';

import styles from './Layout.module.scss';

const Layout = (props) => {
  const [isSidebarOpen, setSidebarOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Navbar
        uid={props.auth.user?.user?.id}
        onLanguage={props.onLanguage}
        language={props.language}
        logout={props.logout}
        isSidebarOpen={isSidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />

      <Container className={styles.layoutContainer} fluid>
        <Row>
          <Sidebar isOpen={isSidebarOpen} />
          <section className={styles.contextContainer}>
            {props.children}
          </section>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Layout;
