import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    scheduleSiteRequest: ['payload'],
    scheduleSiteSuccess: ['response'],
    scheduleSiteFailure: ['error'],

    editScheduleSiteRequest: ['payload'],
    editScheduleSiteSuccess: ['response'],
    editScheduleSiteFailure: ['error'],
  },
  {}
);
