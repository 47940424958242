import React from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';

import { ROUTES } from 'constants.js';
import FiltersMenu from './filters-menu';
import CardsList from './cards-list';
import Paginator from './paginator';
import VerificationModal from './verification-modal';

import styles from './index.module.scss';

const CompaniesVerification = (props) => {
  return (
    <Container fluid>
      <Link to={ROUTES.PLAN_VERFICATION.OPERATORS}>
        Verification Planning > Operator Verification Planning
      </Link>
      <header className={styles.header}>
        {props.operator.company_name}
        <small>
          Select the companies you want to verify under this operator
        </small>
      </header>
      <VerificationModal {...props} />
      <FiltersMenu {...props} />
      <CardsList {...props} />
      <Paginator {...props} />
    </Container>
  );
};

export default CompaniesVerification;
