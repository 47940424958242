import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    LIST_COLLECTORS_REQUEST
    LIST_COLLECTORS_SUCCESS
    LIST_COLLECTORS_FAILURE

    CREATE_COLLECTOR_REQUEST
    CREATE_COLLECTOR_SUCCESS
    CREATE_COLLECTOR_FAILURE

    GET_COLLECTOR_REQUEST
    GET_COLLECTOR_SUCCESS
    GET_COLLECTOR_FAILURE

    UPDATE_COLLECTOR_FAILURE
    UPDATE_COLLECTOR_SUCCESS
    UPDATE_COLLECTOR_REQUEST
    
    DELETE_COLLECTOR_FAILURE
    DELETE_COLLECTOR_SUCCESS
    DELETE_COLLECTOR_REQUEST


  `,
  {}
);
