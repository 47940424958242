import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import styles from './actions-modal.module.scss';

const RejectModal = (props) => {
  return (
    <Modal
      centered
      isOpen
      toggle={props.toggleRejectModal}
      className={styles.modal}
    >
      <ModalHeader
        toggle={props.toggleRejectModal}
        className={styles.modalHeader}
      >
        Reject Application
      </ModalHeader>
      <ModalBody className={styles.modalBody}>
        <p>
          You are about to reject an application for verification.
          <br />
          This action cannot be undone.
          <br />
          Please confirm that you wish to reject this application.
        </p>

        <Button color="primary" onClick={props.rejectApplication}>
          Reject Application
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default (props) => {
  if (!props.rejectModal) {
    return null;
  }

  return <RejectModal {...props} />;
};
