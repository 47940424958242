import { call, put, takeLeading } from 'redux-saga/effects';

import {
  Creators as AirportsCreators,
  Types as AirportsTypes,
} from '../../redux/airports/actions';
import { toastErrorResponse } from '../errors';
import { getAirports as getAirportApi } from '../../api/operators';

export function* getAirport() {
  try {
    const response = yield call(getAirportApi);
    yield put(AirportsCreators.getAirportSuccess(response.data.data.results));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(AirportsCreators.getAirportFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* watchAirports() {
  yield takeLeading(AirportsTypes.GET_AIRPORT_REQUEST, getAirport);
}
