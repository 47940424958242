import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { withLayout } from 'containers/Layout';
import CreateUser from './components';
import { Creators as UsersCreators } from 'services/redux/users/actions';
import { Creators as GroupsCreators } from 'services/redux/groups/actions';

const validationSchema = Yup.object({
  first_name: Yup.string().required('first name is a required field'),
  last_name: Yup.string().required('last name is a required field'),
  msisdn: Yup.string().required('phone number is required'),
  email: Yup.string().email('Invalid email address').required(),
  language: Yup.string().required(),
});

const initialValues = {
  first_name: '',
  last_name: '',
  msisdn: '',
  email: '',
  language: 'en',
  user_groups: [],
};

const Screen = (props) => {
  const dispatch = useDispatch();
  const users = useSelector(({ users }) => users);
  const groups = useSelector(({ groups }) => groups);

  const onSubmit = React.useCallback(
    (values) => {
      const newUser = { ...values };
      newUser.user_groups = newUser.user_groups.map(({ value }) => value);
      console.log(newUser);
      dispatch(UsersCreators.createUserProfileRequest(newUser));
    },
    [dispatch]
  );

  const onSearchGroups = React.useCallback(
    (search) => {
      dispatch(GroupsCreators.listGroupsRequest({ search }));
    },
    [dispatch]
  );

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });

  const onAddGroup = React.useCallback(
    (group) => {
      const groups = [...formik.values.user_groups];
      const isAdded = groups.find(({ value }) => value === group.value);
      if (!isAdded) {
        groups.push(group);
      }

      formik.setFieldValue('user_groups', groups);
    },
    [formik]
  );

  const onRemove = React.useCallback(
    (id) => {
      const groups = formik.values.user_groups.filter(
        ({ value }) => value.toString() !== id.toString()
      );
      formik.setFieldValue('user_groups', groups);
    },
    [formik]
  );

  return (
    <CreateUser
      formik={formik}
      users={users}
      groups={groups}
      onSearchGroups={onSearchGroups}
      onAddGroup={onAddGroup}
      onRemove={onRemove}
      {...props}
    />
  );
};

export default withLayout(Screen);
