import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    loginRequest: ['email', 'password'],
    loginSuccess: ['responseData'],
    loginFailure: ['error'],

    forgotPasswordRequest: ['email'],
    forgotPasswordSuccess: ['responseData'],
    forgotPasswordFailure: ['error'],

    resetPasswordRequest: ['reset_code', 'password'],
    resetPasswordSuccess: ['responseData'],
    resetPasswordFailure: ['error'],

    changeLanguageRequest: ['lang'],
    changeLanguageSuccess: ['responseData'],
    changeLanguageFailure: ['error'],

    logout: null,
  },
  {}
);
