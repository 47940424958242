import { connect } from 'react-redux';
import { Creators } from '../../services/redux/auth/actions';
import ResendPasswordScreen from './components/resend-password-screen';

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResendPassword: ({ email }) => {
      dispatch(Creators.forgotPasswordRequest(email));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResendPasswordScreen);
