import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Creators } from '../../../services/redux/sites/actions';
import { COMPANY_TYPE } from '../../../constants';

const validationSchema = Yup.object({
  company_name: Yup.string().min('4').required('Company Name is required '),
  address: Yup.string().nullable().required('Address is required'),
  type_of_operation: Yup.string()
    .nullable()
    .required('Type of operation required'),
  contact_person_email: Yup.string()
    .email('Contact Person Email must be a valid email')
    .required('Contact Person Email is required'),
  contact_person_msisdn: Yup.string().required('Contact Phone  is required '),
  contact_person_name: Yup.string().required('Contact Person Name is required'),
  country: Yup.string().required('Country must be valid'),
});

export const initialValues = {
  company_name: '',
  type_of_operation: '',
  country: '',
  address: '',
  contact_person_name: '',
  contact_person_email: '',
  contact_person_msisdn: '',
  id: '',
  operator: '',
  company_type: COMPANY_TYPE.SITES,
  is_new: false,
};

const useSiteForm = ({ site = {}, hasNewSite, hasNewSiteSet } = {}) => {
  const dispatch = useDispatch();

  const onSubmit = React.useCallback(
    (values) => {
      if (values.is_new) {
        values.company_type = COMPANY_TYPE.SITES;
        dispatch(Creators.createSiteRequest(values));

        if (hasNewSite > 1) {
          hasNewSiteSet(hasNewSite - 1);
        }
      } else {
        dispatch(Creators.updateSiteRequest(values));
      }
    },
    [dispatch, hasNewSite, hasNewSiteSet]
  );

  const deleteSite = () => {
    dispatch(Creators.deleteSiteRequest(site));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      ...initialValues,
      ...site,
    },
    onSubmit,
  });
  const [isEditable, isEditableSet] = React.useState(false);

  return [isEditable, isEditableSet, formik, deleteSite];
};

export default useSiteForm;
