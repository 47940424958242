import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    listOperatorsRequest: ['filters'],
    listOperatorsSuccess: ['data'],
    listOperatorsFailure: ['error'],

    createOperatorRequest: ['operator'],
    createOperatorSuccess: ['operator'],
    createOperatorFailure: ['error'],

    commitOrRejectOperatorRequest: ['data'],
    commitOrRejectOperatorSuccess: ['operator'],
    commitOrRejectOperatorFailure: ['error'],

    getOperatorRequest: ['id'],
    getOperatorSuccess: ['operator'],
    getOperatorFailure: ['error'],

    updateOperatorRequest: ['operator'],
    updateOperatorSuccess: ['operator'],
    updateOperatorFailure: ['error'],

    mandateOperatorRequest: ['operator'],
    mandateOperatorSuccess: ['operator'],
    mandateOperatorFailure: ['error'],

    statusOperatorRequest: ['operator'],
    statusOperatorSuccess: ['operator'],
    statusOperatorFailure: ['error'],
  },
  {}
);
