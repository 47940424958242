import React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  FormFeedback,
  FormText,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import styles from './verification.module.scss';
import { Creators as OperatorsCreator } from 'services/redux/operators/actions';
import { APPLICATION_STATUSES } from '../../../constants';
const validationSchema = Yup.object({
  scope: Yup.string().nullable().required('Scope is required'),
  verification_type: Yup.string()
    .nullable()
    .required('Verification type required'),
});

const initialValues = {
  scope: '',
  verification_type: '',
  operator: '',
};

const VerificationScreen = (props) => {
  const { goNext, goBack } = props;
  const productscope = [
    { name: 'Full', value: 'full' },
    { name: 'Part', value: 'part' },
  ];

  const verificationTypes = [
    { name: 'Exporter', value: 'exporter' },
    {
      value: 'collector',
      name: 'Collector',
    },
    { value: 'exp_collector', name: 'Exporter Collector' },
    { value: 'supply', name: 'Supply Chain' },
  ];

  const dispatch = useDispatch();

  const operatorData = useSelector((store) => store.operators.list[0] || {});

  React.useEffect(() => {
    dispatch(OperatorsCreator.getOperatorRequest(props.operator));
  }, [dispatch, props.operator]);

  const onSubmit = React.useCallback(
    (values) => {
      dispatch(OperatorsCreator.updateOperatorRequest(values));
      goNext();
    },
    [dispatch, goNext]
  );

  const formik = useFormik({
    onSubmit,
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...operatorData,
    },
  });

  return (
    <Container fluid>
      <Form className={styles.top40} onSubmit={formik.handleSubmit}>
        <Row>
          <Col lg={12} md={12} xl={12}>
            <FormGroup>
              <label> Verification Types</label>
              <Input
                type="select"
                name="verification_type"
                value={formik.values.verification_type}
                onChange={formik.handleChange}
                invalid={formik.errors.verification_type}
                disabled={
                  operatorData.application_status !==
                  APPLICATION_STATUSES.REQUESTED
                }
              >
                <option value=" "> </option>
                {verificationTypes.map((value, key) => {
                  return (
                    <option key={key} value={value.value}>
                      {' '}
                      {value.name}{' '}
                    </option>
                  );
                })}
              </Input>
              <FormFeedback>{formik.errors.verification_type}</FormFeedback>

              <FormText className={styles.instructions}>
                <strong>
                  <small>Notice:</small>
                  Type of verification:
                </strong>
                <ul>
                  <li>- supply chain: from exporter to farmers</li>
                  <li>- exporter: only exporter is verified</li>
                  <li>- collector: only collector is verified</li>
                  <li>- exporter and collectors are verified</li>
                </ul>
              </FormText>
            </FormGroup>

            <FormGroup>
              <label>Scope</label>
              <Input
                type="select"
                name="scope"
                value={formik.values.scope}
                onChange={formik.handleChange}
                invalid={formik.errors.scope}
                disabled={
                  operatorData.application_status !==
                  APPLICATION_STATUSES.REQUESTED
                }
              >
                <option value=" "> </option>
                {productscope.map((value, key) => {
                  return (
                    <option key={key} value={value.value}>
                      {' '}
                      {value.name}{' '}
                    </option>
                  );
                })}
              </Input>
              <FormFeedback>{formik.errors.scope}</FormFeedback>
              <FormText className={styles.instructions}>
                <strong>
                  <small>Scope:</small>
                  Only at exporter level:
                </strong>
                <ul>
                  <li>
                    - Full: all the vanilla purchase by the exporter falls under
                    the scope of the SVI traceability program and is verified
                    traceable
                  </li>
                  <li>
                    - Part: part of the vanilla purchase by the exporter falls
                    under the scope of the SVI traceability program and is
                    verified traceable
                  </li>
                </ul>
              </FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Container fluid className={styles.top20}>
            <Button className={styles.submitButtonBack} onClick={goBack}>
              Back
            </Button>
            {operatorData.application_status !==
              APPLICATION_STATUSES.REQUESTED && (
              <Button className={styles.submitButton} onClick={goNext}>
                Next
              </Button>
            )}
            {operatorData.application_status ===
              APPLICATION_STATUSES.REQUESTED && (
              <Button className={styles.submitButton} type="submit">
                Next
              </Button>
            )}
          </Container>
        </Row>
      </Form>
    </Container>
  );
};
export default VerificationScreen;
