import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { Creators as ScheduleSites } from 'services/redux/schedule/actions';

import styles from './verification-modal.module.scss';

const validationSchema = Yup.object({
  priority: Yup.string().required('Priority must be selected'),
  request_doc_review_date: Yup.string().nullable(),
  // .required('Request date for document review is required field'),
  effective_doc_review_date: Yup.string().nullable(), //.required('Effective review date is required field')
  request_physical_audit_date: Yup.string().nullable(),
  // .required('Request date physical audit is required field'),
  effective_physical_audit_date: Yup.string().nullable(), // .required('Effective physical audit date is required field')
});

const initialValues = {
  priority: 'medium',
  request_doc_review_date: '',
  effective_doc_review_date: '',
  request_physical_audit_date: '',
  effective_physical_audit_date: '',
};

const VerificationModal = (props) => {
  const dispatch = useDispatch();
  const {
    isEditModal,
    isScheduleModal,
    toggleEditModal,
    toggleScheduleModal,
    selected,
    operator,
    operatorCompanies,
  } = props;

  const closeModal = React.useCallback(() => {
    if (isEditModal) {
      toggleEditModal();
    } else if (isScheduleModal) {
      toggleScheduleModal();
    }
  }, [isEditModal, isScheduleModal, toggleEditModal, toggleScheduleModal]);

  const onSubmit = React.useCallback(
    (values) => {
      const data = {
        object_id: selected,
        ...values,
      };

      if (data.effective_doc_review_date === '') {
        delete data.effective_doc_review_date;
      }

      if (data.effective_physical_audit_date === '') {
        delete data.effective_physical_audit_date;
      }

      if (operator.id === selected) {
        data.content_type = 'operator';
      } else {
        data.content_type = 'operatorsites';
      }

      if (data.id) {
        dispatch(ScheduleSites.editScheduleSiteRequest(data));
      } else {
        dispatch(ScheduleSites.scheduleSiteRequest(data));
      }
      closeModal();
    },
    [dispatch, closeModal, operator, selected]
  );

  const siteObject =
    operator?.id === selected
      ? operator
      : operatorCompanies?.find((c) => c.id === selected);
  const schedule = siteObject?.schedules || {};

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      ...initialValues,
      ...schedule,
    },
    onSubmit,
  });

  const isLow = formik.values.priority === 'low';

  return (
    <Modal
      centered
      isOpen
      toggle={closeModal}
      className={styles.modal}
      size="xl"
    >
      <Form onSubmit={formik.handleSubmit}>
        <ModalHeader toggle={closeModal} className={styles.modalHeader}>
          Schedule Verification
        </ModalHeader>

        <ModalBody className={styles.modalBody}>
          <Row>
            <Col>
              <FormGroup>
                <Label>Company name</Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>{siteObject?.company_name}</Label>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label>Profile</Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>
                  {siteObject?.company_type || siteObject.type_of_operation}
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label>Priority</Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup className={styles.priority}>
                <Input
                  type="select"
                  name="priority"
                  value={formik.values.priority}
                  className={styles[formik.values.priority]}
                  onChange={formik.handleChange}
                >
                  <option className={styles.high} value={'high'}>
                    High
                  </option>
                  <option className={styles.medium} value={'medium'}>
                    Medium
                  </option>
                  <option className={styles.low} value={'low'}>
                    Low
                  </option>
                </Input>
                <FormFeedback>{formik.errors.priority}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          {!isLow && (
            <Row>
              <Col>
                <FormGroup>
                  <Label>
                    {' '}
                    Request date for document review (from ecocert)
                  </Label>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Input
                    type="date"
                    name="request_doc_review_date"
                    value={formik.values.request_doc_review_date}
                    onChange={formik.handleChange}
                    invalid={!!formik.errors.request_doc_review_date}
                  />
                  <FormFeedback>
                    {formik.errors.request_doc_review_date}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          )}

          {!isLow && (
            <Row>
              <Col>
                <FormGroup>
                  <Label> Effective review date </Label>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Input
                    type="date"
                    name="effective_doc_review_date"
                    value={formik.values.effective_doc_review_date}
                    onChange={formik.handleChange}
                  />
                  <FormFeedback>
                    {formik.errors.effective_doc_review_date}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          )}

          {!isLow && (
            <Row>
              <Col>
                <FormGroup>
                  <Label> Request date physical audit </Label>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Input
                    type="date"
                    name="request_physical_audit_date"
                    value={formik.values.request_physical_audit_date}
                    onChange={formik.handleChange}
                    invalid={!!formik.errors.request_physical_audit_date}
                  />
                  <FormFeedback>
                    {formik.errors.request_physical_audit_date}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          )}
          {!isLow && (
            <Row>
              <Col>
                <FormGroup>
                  <Label> Effective physical audit date </Label>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Input
                    type="date"
                    name="effective_physical_audit_date"
                    value={formik.values.effective_physical_audit_date}
                    onChange={formik.handleChange}
                  />
                  <FormFeedback>
                    {formik.errors.effective_physical_audit_date}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <FormGroup>
                <Label>Auditor in charge</Label>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>
                  {operator.auditor.first_name} {operator.auditor.last_name}
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter className={styles.modalFooter}>
          <Button type="submit" color="primary">
            Schedule
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default (props) => {
  const isModalOpen = props.isEditModal || props.isScheduleModal;
  if (!isModalOpen) {
    return null;
  }

  return <VerificationModal {...props} />;
};
