export const APPLICATION_STATUSES = {
  ALL: '',
  REQUESTED: 'requested',
  APPLIED: 'applied',
  COMMITTED: 'committed',
  REJECTED: 'rejected',
  MANDATED: 'mandated',
};

export const COMPANY_TYPE = {
  SITES: 'site',
  ORGANIZATION: 'organization',
  COLLECTOR: 'collector',
};

export const APPLICATION_STATUSES_LIST = [
  APPLICATION_STATUSES.ALL,
  APPLICATION_STATUSES.REQUESTED,
  APPLICATION_STATUSES.APPLIED,
  APPLICATION_STATUSES.COMMITTED,
  APPLICATION_STATUSES.REJECTED,
  APPLICATION_STATUSES.MANDATED,
];

export const MANDATE_STATUSES_LIST = [
  APPLICATION_STATUSES.ALL,
  APPLICATION_STATUSES.COMMITTED,
  APPLICATION_STATUSES.MANDATED,
];

export const RESULTS_STATUSES = {
  ALL: 'All',
  NOT_STARTED: 'Not Started',
  IN_PROCESS: 'In process',
  EXEMPTED: 'Exempted',
  FINALIZED: 'Finalized',
};

export const RESULTS_STATUSES_LIST = [
  RESULTS_STATUSES.ALL,
  RESULTS_STATUSES.NOT_STARTED,
  RESULTS_STATUSES.IN_PROCESS,
  RESULTS_STATUSES.EXEMPTED,
  RESULTS_STATUSES.FINALIZED,
];

export const ROUTES = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESEND_PASSWORD: '/resend-password',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_SUCCESS: '/reset-password/success',
  APPLICATIONS: {
    BASE: '/applications',
    LIST: '/applications/list',
  },
  MANDATE: {
    BASE: '/mandate',
    LIST: '/mandate/list',
  },
  PLAN_VERFICATION: {
    BASE: '/plan-verification',
    OPERATORS: '/plan-verification/operators',
    COMPANIES: '/plan-verification/operators/:id/companies',
  },
  RESULTS: {
    BASE: '/results',
  },
  COMPANIES: {
    BASE: '/companies',
    CREATE: '/companies/create',
  },
  USER_MANAGEMENT: {
    BASE: '/user-management',

    USERS: '/user-management/users',
    CREATE_USER: '/user-management/users/create',
    USERS_PROFILE: '/user-management/users/:id/profile',
    GROUPS: '/user-management/groups',
  },
  OPERATOR: {
    BASE: '/operators',
    PROFILE: '/operators/profile/:id',
    BASICINFO: '/operators/info',
  },
};
