import { apiRequest } from '../apiRequest';
import {
  OPERATORS_AIRPORTS_URL,
  OPERATORS_COUNTRIES_URL,
  OPERATORS_URL_MANDATE,
  OPERATORS_URL,
  OPERATORS_USER_URL,
  OPERATORS_SITES_URL,
  OPERATORS_COLLECTOR_URL,
  OPERATORS_ORGANIZATIONS_URL,
  OPERATORS_URL_STATUS,
  OPERATORS_COMMIT_URL,
} from '../urls';

export const createOperator = (operator) => {
  return apiRequest('POST', OPERATORS_USER_URL, operator);
};

export const mandateOperator = (operator) => {
  return apiRequest('POST', OPERATORS_URL_MANDATE, operator);
};

export const statusOperator = (operator) => {
  return apiRequest('POST', OPERATORS_URL_STATUS, operator);
};

export const listOperators = (filters) => {
  return apiRequest('GET', OPERATORS_URL, null, filters);
};

export const getOperator = (id) => {
  return apiRequest('GET', `${OPERATORS_URL}/${id}`);
};

export const updateOperator = (operator) => {
  return apiRequest('PATCH', `${OPERATORS_URL}/${operator.id}`, operator);
};

export const commitOrRejectOperator = (data) => {
  return apiRequest('POST', OPERATORS_COMMIT_URL, data);
};

export const getCountries = () => {
  return apiRequest('GET', `${OPERATORS_COUNTRIES_URL}`);
};

export const getAirports = () => {
  return apiRequest('GET', `${OPERATORS_AIRPORTS_URL}`);
};
export const createSites = (site) => {
  return apiRequest('POST', OPERATORS_SITES_URL, site);
};

export const getSites = (filters) => {
  return apiRequest('GET', `${OPERATORS_SITES_URL}`, null, filters);
};

export const updateSite = (site) => {
  return apiRequest('PATCH', `${OPERATORS_SITES_URL}/${site.id}`, site);
};

export const deleteSite = (site) => {
  return apiRequest('DELETE', `${OPERATORS_SITES_URL}/${site.id}`, site);
};

export const createCollector = (collector) => {
  return apiRequest('POST', OPERATORS_COLLECTOR_URL, collector);
};

export const getCollectors = (filters) => {
  return apiRequest('GET', `${OPERATORS_COLLECTOR_URL}`, null, filters);
};

export const updateCollector = (collector) => {
  return apiRequest(
    'PATCH',
    `${OPERATORS_COLLECTOR_URL}/${collector.id}`,
    collector
  );
};

export const deleteCollector = (collector) => {
  return apiRequest(
    'DELETE',
    `${OPERATORS_COLLECTOR_URL}/${collector.id}`,
    collector
  );
};

export const createOrganization = (collector) => {
  return apiRequest('POST', OPERATORS_ORGANIZATIONS_URL, collector);
};
export const getOrganizations = (filters) => {
  return apiRequest('GET', `${OPERATORS_ORGANIZATIONS_URL}`, null, filters);
};

export const updateOrganization = (organizaton) => {
  return apiRequest(
    'PATCH',
    `${OPERATORS_ORGANIZATIONS_URL}/${organizaton.id}`,
    organizaton
  );
};

export const deleteOrganization = (organizaton) => {
  return apiRequest(
    'DELETE',
    `${OPERATORS_ORGANIZATIONS_URL}/${organizaton.id}`,
    organizaton
  );
};
