import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar as RSNavbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Input,
} from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { FaRegUser } from 'react-icons/fa';

import styles from './Navbar.module.scss';
import { ROUTES } from 'constants.js';

const Navbar = (props) => {
  const toggle = () => props.setSidebarOpen(!props.isSidebarOpen);

  const onLanguage = React.useCallback(
    (e) => {
      const lang = e.target.value;
      if (lang) {
        props.onLanguage && props.onLanguage(lang);
      }
    },
    [props]
  );

  return (
    <div>
      <RSNavbar className={styles.navbarContainer} dark fixed="top" expand="md">
        <NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <div className={styles.logo}>
            <img
              src={require('assets/imgs/navbar-logo.png')}
              className={styles.logo}
              alt="Apex logo"
            />
          </div>
        </NavbarBrand>

        <Nav
          className={classnames('ml-auto', styles.nav)}
          navbar
          horizontal="horizontal"
        >
          <NavItem className={styles.navItem}>
            <NavLink
              tag={Link}
              to={ROUTES.USER_MANAGEMENT.USERS_PROFILE.replace(
                ':id',
                props.uid
              )}
              className={styles.navLink}
            >
              <FaRegUser />
            </NavLink>
          </NavItem>

          <NavItem className={styles.navItem}>
            <NavLink className={styles.navLink}>
              <Input
                type="select"
                name="limit"
                className={styles.langInput}
                value={props.language}
                onChange={onLanguage}
              >
                <option value={'EN'}>EN</option>
                <option value={'FR'}>FR</option>
              </Input>
            </NavLink>
          </NavItem>

          <NavItem className={styles.navItem}>
            <NavLink onClick={props.logout}>Logout</NavLink>
          </NavItem>
        </Nav>
      </RSNavbar>
    </div>
  );
};

Navbar.propTypes = {
  uid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  language: PropTypes.string.isRequired,
  onLanguage: PropTypes.func.isRequired,
};

export default Navbar;
