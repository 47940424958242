import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  list: [],
  selected: {},
  permissions: [],
  pagination: {
    status: '',
    search: '',
    limit: 10,

    count: 0,
    num_pages: 1,
    current_page: 1,
  },
};

const LOADING_STATE = {
  isLoading: true,
  isError: false,
};

const SUCCESS_STATE = {
  isLoading: false,
  isError: false,
};

const ERROR_STATE = {
  isLoading: false,
  isError: true,
};

export const listGroupsRequest = (state = INITIAL_STATE, action) => {
  let newState = {
    pagination: {},
  };

  if (
    action.filters &&
    ('limit' in action.filters || 'current_page' in action.filters)
  ) {
    if ('limit' in action.filters) {
      action.filters.current_page = 1;
    }

    Object.assign(newState, state, LOADING_STATE);
    Object.assign(newState.pagination, state.pagination, action.filters);
  } else if (action.filters) {
    Object.assign(newState, INITIAL_STATE, LOADING_STATE, {
      pagination: { ...state.pagination, ...action.filters },
    });
  } else if (!action.filters) {
    Object.assign(newState, INITIAL_STATE, LOADING_STATE);
  }

  return newState;
};

export const listGroupsSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.responseData;

  let newState = {
    ...state,
    list: data.results,
    ...SUCCESS_STATE,
  };

  Object.assign(newState.pagination, state.pagination, data.pagination);

  return newState;
};

export const listGroupsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const getGroupRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const getGroupSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    selected: { ...action.group },
    ...SUCCESS_STATE,
  };
};

export const getGroupFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const updateGroupRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const updateGroupSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.map((group) => {
    if (action.group.id === group.id) {
      return action.group;
    }
    return group;
  });

  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const updateGroupFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

// deleting a group
export const deleteGroupRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const deleteGroupSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.filter(({ id }) => id.toString() !== action.id);

  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const deleteGroupFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

// udpating a group state
export const updateGroupStatusRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const updateGroupStatusSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.map((group) => {
    if (group.id.toString() === action.group.id.toString()) {
      group.is_active = !group.is_active;
    }
    return group;
  });

  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const updateGroupStatusFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const listGroupsPermissionsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const listGroupsPermissionsSuccess = (state = INITIAL_STATE, action) => {
  const categories = action.permissions.reduce((permissions, permission) => {
    const { category } = permission;
    permissions[category] = Array.isArray(permissions[category])
      ? [...permissions[category], permission]
      : [permission];
    return permissions;
  }, []);

  const permissions = Object.keys(categories).map((category) => {
    return {
      category,
      permissions: categories[category],
    };
  });

  return {
    ...state,
    ...SUCCESS_STATE,
    permissions,
  };
};

export const listGroupsPermissionsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const createGroupRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const createGroupSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...SUCCESS_STATE,
  };
};

export const createGroupFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const HANDLERS = {
  [Types.LIST_GROUPS_REQUEST]: listGroupsRequest,
  [Types.LIST_GROUPS_SUCCESS]: listGroupsSuccess,
  [Types.LIST_GROUPS_FAILURE]: listGroupsFailure,

  [Types.GET_GROUP_REQUEST]: getGroupRequest,
  [Types.GET_GROUP_SUCCESS]: getGroupSuccess,
  [Types.GET_GROUP_FAILURE]: getGroupFailure,

  [Types.UPDATE_GROUP_REQUEST]: updateGroupRequest,
  [Types.UPDATE_GROUP_SUCCESS]: updateGroupSuccess,
  [Types.UPDATE_GROUP_FAILURE]: updateGroupFailure,

  [Types.DELETE_GROUP_REQUEST]: deleteGroupRequest,
  [Types.DELETE_GROUP_SUCCESS]: deleteGroupSuccess,
  [Types.DELETE_GROUP_FAILURE]: deleteGroupFailure,

  [Types.UPDATE_GROUP_STATUS_REQUEST]: updateGroupStatusRequest,
  [Types.UPDATE_GROUP_STATUS_SUCCESS]: updateGroupStatusSuccess,
  [Types.UPDATE_GROUP_STATUS_FAILURE]: updateGroupStatusFailure,

  [Types.LIST_GROUPS_PERMISSIONS_REQUEST]: listGroupsPermissionsRequest,
  [Types.LIST_GROUPS_PERMISSIONS_SUCCESS]: listGroupsPermissionsSuccess,
  [Types.LIST_GROUPS_PERMISSIONS_FAILURE]: listGroupsPermissionsFailure,

  [Types.CREATE_GROUP_REQUEST]: createGroupRequest,
  [Types.CREATE_GROUP_SUCCESS]: createGroupSuccess,
  [Types.CREATE_GROUP_FAILURE]: createGroupFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
