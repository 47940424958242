import React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  FormFeedback,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import styles from './basicinfo.module.scss';
import FloatedLabelInput from 'components/FloatedLabelInput';
import { Creators as OperatorsCreator } from 'services/redux/operators/actions';
import { Creators as AirportsCreator } from 'services/redux/airports/actions';
import { Creators as CountryCreator } from 'services/redux/countries/actions';
import TimePicker from 'react-bootstrap-time-picker';
import { timeFromInt } from 'time-number';
import { APPLICATION_STATUSES } from '../../../constants';

const validationSchema = Yup.object({
  company_name: Yup.string()
    .min(4, 'Company name should have minimum 4 length')
    .required('Company name is a required field'),
  approximate_time_to_airport: '',
  address: Yup.string().required('Address is required'),
  type_of_operation: Yup.string().required('Type of operation required'),
  nearest_airport: '',
  country: '',
});

const initialValues = {
  company_name: '',
  country: '',
  nearest_airport: '',
  address: '',
  type_of_operation: '',
  approximate_time_to_airport: '00:00',
};

const usePrevious = (value) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const BasicScreen = (props) => {
  const dispatch = useDispatch();

  const { goNext } = props;

  const operatorData = useSelector((store) => store.operators.list[0] || {});

  const isLoading = useSelector(({ operators: { isLoading } }) => isLoading);
  const wasLoading = usePrevious(isLoading);
  const isError = useSelector(({ operators: { isError } }) => isError);
  const [isSubmitted, isSubmittedSet] = React.useState(false);

  const countries = useSelector((store) => store.countries.list);

  const airports = useSelector((store) => store.airports.list);

  const onSubmit = React.useCallback(
    (values) => {
      // isSubmittedSet(true);
      dispatch(OperatorsCreator.updateOperatorRequest(values));
      goNext();
    },
    [dispatch, goNext]
  );

  React.useEffect(() => {
    if (isSubmitted && wasLoading && !isLoading && !isError) {
      goNext();
      isSubmittedSet(false);
    }
  }, [wasLoading, isLoading, isError, isSubmitted, goNext]);

  const operators = ['production', 'export', 'processing'];

  const formik = useFormik({
    onSubmit,
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...operatorData,
    },
  });

  const handleTimeChange = React.useCallback(
    (value) => {
      formik.setFieldValue('approximate_time_to_airport', timeFromInt(value));
    },
    [formik]
  );

  /** Hook  useEffect **/
  React.useEffect(() => {
    dispatch(OperatorsCreator.getOperatorRequest(props.operator));
  }, [dispatch, props.operator]);

  React.useEffect(() => {
    dispatch(CountryCreator.getCountriesRequest());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(AirportsCreator.getAirportRequest());
  }, [dispatch]);

  return (
    <Container fluid>
      <Form className={styles.top40} onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12} md={6} lg={6}>
            <FloatedLabelInput
              label="company.name"
              name="company_name"
              value={formik.values.company_name}
              onChange={formik.handleChange}
              error={formik.errors.company_name}
              disabled={
                operatorData.application_status !==
                APPLICATION_STATUSES.REQUESTED
              }
            />
            <FormGroup>
              <label>Country</label>
              <Input
                type="select"
                name="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                disabled={
                  operatorData.application_status !==
                  APPLICATION_STATUSES.REQUESTED
                }
              >
                {countries.map((value, key) => {
                  return (
                    <option key={key} value={value.id}>
                      {' '}
                      {value.name}{' '}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>

            <FormGroup>
              <label>Nearest Airport</label>
              <Input
                type="select"
                value={formik.values.airport}
                onChange={formik.handleChange}
                name="nearest_airport"
                disabled={
                  operatorData.application_status !==
                  APPLICATION_STATUSES.REQUESTED
                }
              >
                {airports.map((value, key) => {
                  return (
                    <option key={key} value={value.id}>
                      {' '}
                      {value.name}{' '}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>

            <FormGroup>
              <label> Time to the Nearest Hours</label>
              <TimePicker
                format={24}
                start="00:00"
                end="23:59"
                step={30}
                value={formik.values.approximate_time_to_airport}
                onChange={handleTimeChange}
                disabled={
                  operatorData.application_status !==
                  APPLICATION_STATUSES.REQUESTED
                }
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <FormGroup>
              <label> Address</label>
              <Input
                type="textarea"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                invalid={!!formik.errors.address}
                disabled={
                  operatorData.application_status !==
                  APPLICATION_STATUSES.REQUESTED
                }
              />
              <FormFeedback>{formik.errors.address}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <label>Type of operation</label>
              <Input
                name="type_of_operation"
                value={formik.values.type_of_operation}
                onChange={formik.handleChange}
                error={formik.errors.type_of_operation}
                type="select"
                disabled={
                  operatorData.application_status !==
                  APPLICATION_STATUSES.REQUESTED
                }
              >
                {operators.map((value, key) => {
                  return (
                    <option key={key} value={value}>
                      {' '}
                      {value}{' '}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12}>
            {operatorData.application_status !==
              APPLICATION_STATUSES.REQUESTED && (
              <Button className={styles.submitButton} onClick={goNext}>
                Next
              </Button>
            )}
            {operatorData.application_status ===
              APPLICATION_STATUSES.REQUESTED && (
              <Button className={styles.submitButton} type="submit">
                Next
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default BasicScreen;
