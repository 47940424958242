import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  LIST_USERS_REQUEST
  LIST_USERS_SUCCESS
  LIST_USERS_FAILURE

  GET_USER_REQUEST
  GET_USER_SUCCESS
  GET_USER_FAILURE

  DELETE_USER_REQUEST
  DELETE_USER_SUCCESS
  DELETE_USER_FAILURE

  UPDATE_USER_STATUS_REQUEST
  UPDATE_USER_STATUS_SUCCESS
  UPDATE_USER_STATUS_FAILURE

  CREATE_USER_PROFILE_REQUEST
  CREATE_USER_PROFILE_SUCCESS
  CREATE_USER_PROFILE_FAILURE

  UPDATE_USER_PROFILE_REQUEST
  UPDATE_USER_PROFILE_SUCCESS
  UPDATE_USER_PROFILE_FAILURE

  UPDATE_USER_PASSWORD_REQUEST
  UPDATE_USER_PASSWORD_SUCCESS
  UPDATE_USER_PASSWORD_FAILURE

  GROUP_ADD_USER_REQUEST
  GROUP_ADD_USER_SUCCESS
  GROUP_ADD_USER_FAILURE

  GROUP_REMOVE_USER_REQUEST
  GROUP_REMOVE_USER_SUCCESS
  GROUP_REMOVE_USER_FAILURE

`,
  {}
);
