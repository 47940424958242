import { call, put, takeLeading, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { Creators, Types } from '../../redux/schedule/actions';
import { Creators as OperatorsCreators } from '../../redux/operators/actions';
import { Creators as SitesCreators } from '../../redux/sites/actions';

import {
  schedule as scheduleApi,
  editSchedule as editScheduleApi,
} from '../../api/schedule';
import { toastErrorResponse } from '../errors';

export function* schedule(actions) {
  try {
    const sites = yield select((store) => store.sites);

    const response = yield call(scheduleApi, actions.payload);
    yield put(Creators.scheduleSiteSuccess(response));

    if (actions.payload.content_type === 'operator') {
      yield put(
        OperatorsCreators.getOperatorRequest(actions.payload.object_id)
      );
    } else {
      yield put(
        SitesCreators.listSitesRequest({
          ...sites.filters,
        })
      );
    }

    toast.success('Scheduled successfully');
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(Creators.scheduleSiteFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* editSchedule(actions) {
  try {
    const sites = yield select((store) => store.sites);

    const response = yield call(editScheduleApi, actions.payload);
    yield put(Creators.editScheduleSiteSuccess(response));

    if (actions.payload.content_type === 'operator') {
      yield put(
        OperatorsCreators.getOperatorRequest(actions.payload.object_id)
      );
    } else {
      yield put(
        SitesCreators.listSitesRequest({
          ...sites.filters,
        })
      );
    }

    toast.success('Schedule updated successfully');
  } catch (error) {
    const error_message =
      error?.response?.data?.data?.non_field_errors || error.message;
    toastErrorResponse(error);
    yield put(Creators.editScheduleSiteFailure(error_message));
  }
}

export function* watchSchedule() {
  yield takeLeading(Types.SCHEDULE_SITE_REQUEST, schedule);
  yield takeLeading(Types.EDIT_SCHEDULE_SITE_REQUEST, editSchedule);
}
