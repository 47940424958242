import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Table } from 'reactstrap';
import classnames from 'classnames';

import FilterMenu from './components/filter-menu';
import PaginationController from './components/pagination-controller';

import styles from './index.module.scss';

const EnitityTable = (props) => {
  return (
    <Container fluid>
      <FilterMenu {...props} />

      <Row>
        <Table
          className={classnames(styles.table, styles.tableApex)}
          hover
          responsive
        >
          {props.children}
        </Table>
      </Row>

      <PaginationController {...props} />
    </Container>
  );
};

EnitityTable.propTypes = {
  ...FilterMenu.propTypes,
  ...PaginationController.propTypes,
  state: PropTypes.object.isRequired,
};

export default EnitityTable;

/*

<thead>
  <tr>
    <th>#</th>
    <th>User name</th>
    <th>Email</th>
    <th className="d-none d-sm-block">Groups</th>
    <th></th>
  </tr>
</thead>
<tbody>
  <tr>
    <th scope="row">2</th>
    <td>Jacob</td>
    <td>Thornton</td>
    <td>@fat</td>
  </tr>
  <tr className="d-none d-sm-block">
    <th scope="row">3</th>
    <td>Larry</td>
    <td>the Bird</td>
    <td>@twitter</td>
  </tr>
</tbody>

*/
