import React from 'react';

import EntityTable from 'components/EntityTable';
import EmptyGroupsList from './empty-groups';
// import GroupModal from './group-modal';
import GroupModalForm from './group-modal-form';

import styles from './groups-list.module.scss';

const GroupsList = (props) => {
  const {
    listGroups,
    groups,
    selected,
    // setSelected,
    onSelectedChange,
    onCreate,
    onEdit,
    onUpdateStatus,
    onDelete,
    modalType,
    modalTypeSet,
  } = props;

  const isEmptyList =
    !groups?.isLoading &&
    !groups?.list?.length &&
    groups.pagination.search === groups.pagination.status;

  React.useEffect(() => {
    listGroups();
  }, [listGroups]);

  if (isEmptyList) {
    return (
      <React.Fragment>
        <EmptyGroupsList onClick={onCreate} />
        <GroupModalForm type={modalType} onClose={() => modalTypeSet('')} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <GroupModalForm
        gid={selected}
        type={modalType}
        onClose={() => modalTypeSet('')}
      />

      <EntityTable
        state={groups}
        onEdit={onEdit}
        onDelete={onDelete}
        onStatus={onUpdateStatus}
        onPagination={listGroups}
        onFiltersChange={listGroups}
        onRightBtnClick={onCreate}
        rightBtnTitleID="groups.create"
        searchable
        filterable
        selectedRow={selected}
      >
        <thead>
          <tr>
            <th></th>
            <th>Group Name</th>
            <th>Group Description</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {groups.list.map((group) => {
            return (
              <tr key={group.id}>
                <th scope="row">
                  <input
                    type="checkbox"
                    value={group.id}
                    checked={group.id.toString() === selected}
                    onChange={onSelectedChange}
                  />
                </th>
                <td>{group.name}</td>
                <td>{group.description}</td>
                <td>
                  <div className={styles.borderdStatus}>
                    {group.is_active ? 'Active' : 'Inactive'}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </EntityTable>
    </React.Fragment>
  );
};

export default GroupsList;
