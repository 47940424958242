import React from 'react';
import { Row } from 'reactstrap';

import Card from './card';

const CardsList = (props) => {
  const verifications = Array.isArray(props.verifications)
    ? props.verifications
    : [];
  return (
    <Row>
      {verifications?.map((value, index) => {
        return <Card {...props} key={index} value={value} />;
      })}
    </Row>
  );
};

export default CardsList;
