import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    SCHEDULE_SITE_REQUEST
    SCHEDULE_SITE_SUCCESS
    SCHEDULE_SITE_FAILURE

    EDIT_SCHEDULE_SITE_REQUEST
    EDIT_SCHEDULE_SITE_SUCCESS
    EDIT_SCHEDULE_SITE_FAILURE
  `,
  {}
);
