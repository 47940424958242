import React from 'react';
import { withLayout } from 'containers/Layout';
import BasicScreen from './basicinfo';
import AdditionalScreen from './additional-info';
import VerificationScreen from './verification';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../../material-theme';
import styles from './operators.scss';
import SitesScreen from './sites';
import OrganizationsScreen from './organizations';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { Creators as CountryCreator } from 'services/redux/countries/actions';
import CollectorsScreen from './collectors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const getSteps = () => {
  return [
    'Basic Info',
    'Sites',
    'Verification Types',
    'Collectors',
    'Farmers organizations',
    'Additional Info',
  ];
};

const FinishScreen = () => {
  return <h4> Thank you for submitting your details </h4>;
};

const OperatorScreen = (props) => {
  const [activeStep, setActiveStep] = React.useState(1);
  const classes = useStyles();
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const operatorId = props.match.params.id;
  const dispatch = useDispatch();

  const countries = useSelector((store) => store.countries.list);
  const operators = ['production', 'export', 'processing'];

  React.useEffect(() => {
    dispatch(CountryCreator.getCountriesRequest());
  }, [dispatch]);

  const stepSection = (step) => {
    let stepComponent = null;

    if (step === steps.length) {
      stepComponent = <FinishScreen />;
    }

    if (step === 0) {
      stepComponent = <BasicScreen goNext={handleNext} operator={operatorId} />;
    }

    if (step === 1) {
      stepComponent = (
        <SitesScreen
          goNext={handleNext}
          goBack={handleBack}
          countries={countries}
          operators={operators}
          operator={operatorId}
        />
      );
    }

    if (step === 2) {
      stepComponent = (
        <VerificationScreen
          goNext={handleNext}
          goBack={handleBack}
          steps={steps}
          activeStep={activeStep}
          operator={operatorId}
        />
      );
    }

    if (step === 3) {
      stepComponent = (
        <CollectorsScreen
          goNext={handleNext}
          goBack={handleBack}
          countries={countries}
          operators={operators}
          operator={operatorId}
        />
      );
    }

    if (step === 4) {
      stepComponent = (
        <OrganizationsScreen
          goNext={handleNext}
          goBack={handleBack}
          countries={countries}
          operators={operators}
          operator={operatorId}
        />
      );
    }

    if (step === 5) {
      stepComponent = (
        <AdditionalScreen
          goNext={handleNext}
          goBack={handleBack}
          steps={steps}
          activeStep={activeStep}
          operator={operatorId}
        />
      );
    }

    return stepComponent;
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Stepper
          className={styles.stepLabel}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                Operator Details recieved
              </Typography>
            </div>
          ) : (
            <div>{stepSection(activeStep)}</div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default withLayout(OperatorScreen);
