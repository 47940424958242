import { call, put, throttle, select, takeLeading } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  Creators as GroupsCreators,
  Types as GroupsTypes,
} from '../../redux/groups/actions';
import {
  listGroups as listGroupsApi,
  createGroup as createGroupApi,
  getGroup as getGroupApi,
  updateGroup as updateGroupApi,
  deleteGroup as deleteGroupApi,
  updateGroupStatus as updateGroupStatusApi,
  listGroupsPermissions as listGroupsPermissionsApi,
} from '../../api/groups';
import { toastErrorResponse } from '../errors';

export function* listGroups() {
  try {
    const pagination = yield select(({ groups }) => groups.pagination);
    const response = yield call(listGroupsApi, pagination);
    yield put(GroupsCreators.listGroupsSuccess(response.data));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(GroupsCreators.listGroupsFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* listGroupsPermissions() {
  try {
    const response = yield call(listGroupsPermissionsApi);
    yield put(GroupsCreators.listGroupsPermissionsSuccess(response.data.data));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(GroupsCreators.listGroupsPermissionsFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* createGroup(action) {
  try {
    const response = yield call(createGroupApi, action.group);
    yield put(GroupsCreators.createGroupSuccess(response.data.data));
    toast.success(`Group created successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(GroupsCreators.createGroupFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* getGroup(action) {
  try {
    const response = yield call(getGroupApi, action.id);
    yield put(GroupsCreators.getGroupSuccess(response.data.data));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(GroupsCreators.getGroupFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* updateGroup(action) {
  try {
    const response = yield call(updateGroupApi, action.group);
    yield put(GroupsCreators.updateGroupSuccess(response.data.data));
    toast.success(`Group updated successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(GroupsCreators.updateGroupFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* deleteGroup(action) {
  try {
    yield call(deleteGroupApi, action.id);
    yield put(GroupsCreators.deleteGroupSuccess(action.id));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(GroupsCreators.deleteGroupFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* updateGroupStatus(action) {
  try {
    yield call(updateGroupStatusApi, action.group);
    yield put(GroupsCreators.updateGroupStatusSuccess(action.group));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(GroupsCreators.updateGroupStatusFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* watchGroups() {
  yield throttle(1e3, GroupsTypes.LIST_GROUPS_REQUEST, listGroups);
  yield takeLeading(
    GroupsTypes.LIST_GROUPS_PERMISSIONS_REQUEST,
    listGroupsPermissions
  );
  yield takeLeading(GroupsTypes.CREATE_GROUP_REQUEST, createGroup);
  yield takeLeading(GroupsTypes.GET_GROUP_REQUEST, getGroup);
  yield takeLeading(GroupsTypes.UPDATE_GROUP_REQUEST, updateGroup);
  yield takeLeading(GroupsTypes.DELETE_GROUP_REQUEST, deleteGroup);
  yield takeLeading(GroupsTypes.UPDATE_GROUP_STATUS_REQUEST, updateGroupStatus);
}
