import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Creators } from '../../../services/redux/sites/actions';
import { COMPANY_TYPE } from '../../../constants';
const validationSchema = Yup.object({
  company_name: Yup.string()
    .min(4, 'Company Name must be minimum length is 4 ')
    .required('Company Name is required'),
  contact_person_email: Yup.string()
    .email('Must be a valid email')
    .required('Contact person email is required'),
  contact_person_msisdn: Yup.string().required(
    'Contact person msisdn is required'
  ),
  address: Yup.string().required('Address is required'),
  country: Yup.string().required('Country is required'),
  contact_person_name: Yup.string()
    .min(4, 'Contact person name must be of a minimum length of 4 ')
    .required('Contact Person Name is required'),
  vrac_vanilla_volume: Yup.number()
    .min(0, 'Vrac Vanilla Volume must be 0 or greater')
    .typeError('Vrac Vanilla Volume must be a number')
    .required('Vrac Vanilla Volume is required'),
  green_vanilla_volume: Yup.number()
    .min(0, 'Green Vanilla Volume must be 0 or greater')
    .typeError('Green Vanilla Volume must be a number')
    .required('Green Vanilla Volume is required'),
  type_of_operation: Yup.string().required('Type of operation is required '),
});

export const initialValues = {
  company_name: '',
  type_of_operation: '',
  country: '',
  address: '',
  contact_person_name: '',
  contact_person_email: '',
  contact_person_msisdn: '',
  id: '',
  operator: '',
  vrac_vanilla_volume: '',
  green_vanilla_volume: '',
  company_type: COMPANY_TYPE.COLLECTOR,
  is_new: false,
};

const useCollectorForm = ({ site = {} } = {}) => {
  const dispatch = useDispatch();

  const onSubmit = React.useCallback(
    (values) => {
      if (values.is_new) {
        values.company_type = COMPANY_TYPE.COLLECTOR;
        dispatch(Creators.createSiteRequest(values));
      } else {
        dispatch(Creators.updateSiteRequest(values));
      }
    },
    [dispatch]
  );

  const deleteSite = () => {
    dispatch(Creators.deleteSiteRequest(site));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...site,
    },
    validationSchema,
    onSubmit,
  });
  const [isEditable, isEditableSet] = React.useState(false);

  return [isEditable, isEditableSet, formik, deleteSite];
};

export default useCollectorForm;
