import { connect } from 'react-redux';
import { Creators } from '../../services/redux/auth/actions';
import ResetPasswordScreen from './components/reset-password-screen';

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetPassword: ({ reset_code, password }) => {
      dispatch(Creators.resetPasswordRequest(reset_code, password));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordScreen);
