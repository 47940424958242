import React from 'react';
import { Row } from 'reactstrap';

import Card from './card';
import { useSelector } from 'react-redux';

const CardsList = (props) => {
  const list = useSelector((store) => store.operators.list);
  return (
    <Row>
      {list.map((i, idx) => {
        return <Card {...props} key={idx} operator={i} />;
      })}
    </Row>
  );
};

export default CardsList;
