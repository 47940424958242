import { all } from 'redux-saga/effects';

import { watchAuth } from './auth';
import { watchUsers } from './users';
import { watchGroups } from './groups';
import { watchOperators } from './operators';
import { watchCountries } from './countries';
import { watchAirports } from './airports';
import { watchSites } from './sites';
import { watchCollectors } from './collectors';
import { watchOrganizations } from './organizations';
import { watchSchedule } from './schedule';
import { watchVerifications } from './verifications';

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchUsers(),
    watchGroups(),
    watchOperators(),
    watchCountries(),
    watchAirports(),
    watchSites(),
    watchCollectors(),
    watchOrganizations(),
    watchSchedule(),
    watchVerifications(),
  ]);
}
