import React from 'react';
import { IntlProvider } from 'react-intl';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

import LoginScreen from 'containers/login-screen';
import ForgotPasswordScreen from 'containers/forgot-password-screen';
import ResendPasswordScreen from 'containers/resend-password-screen';
import ResetPasswordScreen from 'containers/reset-password-screen';
import ResetPasswordSuccessScreen from 'containers/reset-password-success-screen';
import CreateUserScreen from './containers/create-user-screen';
import OperatorScreen from './containers/operators_profile';
import BasicScreen from './containers/operators_profile/basicinfo';
import UserProfileScreen from './containers/user-profile-screen';
import UsersListingScreen from './containers/users-listing-screen';
import GroupsListingScreen from './containers/groups-listing-screen';
import CreateOperatorScreen from './containers/create-operator-screen';
import ApplicationsListingScreen from './containers/applications-listing-screen';
import MandateScreen from './containers/mandate-screen';
import OperatorsVerificationScreen from './containers/plan-verification-operators-screen';
import CompaniesVerificationScreen from './containers/plan-verification-companies-screen';
import ViewResultsScreen from './containers/view-results-screen';

import { useLanguage } from 'lang';
import { history } from './history.js';
import { ROUTES } from 'constants.js';
import { getToken } from 'services/api/axiosDefaults';

const checkAuth = () => {
  try {
    // check token validation, if auth fails return false
    let token = getToken();
    if (!token) {
      sessionStorage.setItem(
        'redirectURL',
        window.location.pathname + window.location.search
      );
      return false;
    }
    // TODO: use JWT and parse it here
    // console.log(decode(token));
    // const { exp } = decode(token);
    // if (exp < new Date().getTime() / 1000) {
    //   return false;
    // }
  } catch (e) {
    console.log('e ', e);
    return false;
  }
  return true;
};

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/' }} />
      )
    }
  />
);

function App() {
  const [language, messages] = useLanguage();
  const authState = useSelector(({ auth }) => auth);
  const hasEmail = !!!(authState.forgotPassword || {}).email;

  return (
    <IntlProvider locale={language} messages={messages}>
      <Router history={history}>
        <Switch>
          <Redirect exact from="/" to={ROUTES.LOGIN} />
          {hasEmail && (
            <Redirect
              exact
              from={ROUTES.RESEND_PASSWORD}
              to={ROUTES.FORGOT_PASSWORD}
            />
          )}

          <Route path={ROUTES.LOGIN} component={LoginScreen} />
          <Route
            path={ROUTES.FORGOT_PASSWORD}
            component={ForgotPasswordScreen}
          />
          <Route
            path={ROUTES.RESEND_PASSWORD}
            component={ResendPasswordScreen}
          />
          <Route
            exact
            path={ROUTES.RESET_PASSWORD}
            component={ResetPasswordScreen}
          />
          <Route
            path={ROUTES.RESET_PASSWORD_SUCCESS}
            component={ResetPasswordSuccessScreen}
          />

          <AuthenticatedRoute
            exact
            path={ROUTES.APPLICATIONS.LIST}
            component={ApplicationsListingScreen}
          />

          <AuthenticatedRoute
            exact
            path={ROUTES.MANDATE.LIST}
            component={MandateScreen}
          />

          <AuthenticatedRoute
            exact
            path={ROUTES.PLAN_VERFICATION.OPERATORS}
            component={OperatorsVerificationScreen}
          />

          <AuthenticatedRoute
            exact
            path={ROUTES.PLAN_VERFICATION.COMPANIES}
            component={CompaniesVerificationScreen}
          />

          <Redirect
            exact
            from={ROUTES.APPLICATIONS.BASE}
            to={ROUTES.APPLICATIONS.LIST}
          />

          <AuthenticatedRoute
            exact
            path={ROUTES.COMPANIES.CREATE}
            component={CreateOperatorScreen}
          />

          <Redirect
            exact
            from={ROUTES.USER_MANAGEMENT.BASE}
            to={ROUTES.USER_MANAGEMENT.GROUPS}
          />

          <AuthenticatedRoute
            exact
            path={ROUTES.USER_MANAGEMENT.GROUPS}
            component={GroupsListingScreen}
          />

          <AuthenticatedRoute
            exact
            path={ROUTES.USER_MANAGEMENT.USERS}
            component={UsersListingScreen}
          />

          <AuthenticatedRoute
            exact
            path={ROUTES.USER_MANAGEMENT.USERS_PROFILE}
            component={UserProfileScreen}
          />

          <AuthenticatedRoute
            exact
            path={ROUTES.USER_MANAGEMENT.CREATE_USER}
            component={CreateUserScreen}
          />

          <AuthenticatedRoute
            exact
            path={ROUTES.OPERATOR.PROFILE}
            component={OperatorScreen}
          />
          <AuthenticatedRoute
            exact
            path={ROUTES.OPERATOR.BASICINFO}
            component={BasicScreen}
          />

          <AuthenticatedRoute
            exact
            path={ROUTES.RESULTS.BASE}
            component={ViewResultsScreen}
          />
        </Switch>
      </Router>
      <ToastContainer />
    </IntlProvider>
  );
}

export default App;
