import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  LIST_GROUPS_REQUEST
  LIST_GROUPS_SUCCESS
  LIST_GROUPS_FAILURE

  GET_GROUP_REQUEST
  GET_GROUP_SUCCESS
  GET_GROUP_FAILURE

  UPDATE_GROUP_REQUEST
  UPDATE_GROUP_SUCCESS
  UPDATE_GROUP_FAILURE

  DELETE_GROUP_REQUEST
  DELETE_GROUP_SUCCESS
  DELETE_GROUP_FAILURE

  UPDATE_GROUP_STATUS_REQUEST
  UPDATE_GROUP_STATUS_SUCCESS
  UPDATE_GROUP_STATUS_FAILURE

  LIST_GROUPS_PERMISSIONS_REQUEST
  LIST_GROUPS_PERMISSIONS_SUCCESS
  LIST_GROUPS_PERMISSIONS_FAILURE

  CREATE_GROUP_REQUEST
  CREATE_GROUP_SUCCESS
  CREATE_GROUP_FAILURE

`,
  {}
);
