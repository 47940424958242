import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
  list: [],
  pagination: {
    status: '',
    search: '',
    limit: 10,

    count: 0,
    num_pages: 1,
    current_page: 1,
  },
};

const LOADING_STATE = {
  isLoading: true,
  isError: false,
};

const SUCCESS_STATE = {
  isLoading: false,
  isError: false,
};

const ERROR_STATE = {
  isLoading: false,
  isError: true,
};

export const listUsersRequest = (state = INITIAL_STATE, action) => {
  let newState = {
    pagination: {},
  };

  if (
    action.filters &&
    ('limit' in action.filters || 'current_page' in action.filters)
  ) {
    if ('limit' in action.filters) {
      action.filters.current_page = 1;
    }

    Object.assign(newState, state, LOADING_STATE);
    Object.assign(newState.pagination, state.pagination, action.filters);
  } else if (action.filters) {
    Object.assign(newState, INITIAL_STATE, LOADING_STATE, {
      pagination: { ...state.pagination, ...action.filters },
    });
  } else if (!action.filters) {
    Object.assign(newState, INITIAL_STATE, LOADING_STATE);
  }

  return newState;
};

export const listUsersSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.responseData;

  let newState = {
    ...state,
    list: data.results,
    ...SUCCESS_STATE,
  };

  Object.assign(newState.pagination, state.pagination, data.pagination);

  return newState;
};

export const listUsersFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const getUserRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const getUserSuccess = (state = INITIAL_STATE, action) => {
  const { data } = action.user;

  let newState = {
    ...state,
    list: [data],
    ...SUCCESS_STATE,
  };

  // Object.assign(newState.pagination, state.pagination, data.pagination);

  return newState;
};

export const getUserFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

// deleting a user
export const deleteUserRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const deleteUserSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.filter(({ id }) => id !== action.id);

  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const deleteUserFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

// udpating a user state
export const updateUserStatusRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const updateUserStatusSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.map((user) => {
    if (user.id === action.user.id) {
      user.is_active = !user.is_active;
    }
    return user;
  });

  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const updateUserStatusFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const createUserProfileRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const createUserProfileSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...SUCCESS_STATE,
  };
};

export const createUserProfileFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const updateUserProfileRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const updateUserProfileSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...SUCCESS_STATE,
  };
};

export const updateUserProfileFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const updateUserPasswordRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const updateUserPasswordSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...SUCCESS_STATE,
  };
};

export const updateUserPasswordFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const groupAddUserRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const groupAddUserSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.map((user) => {
    if (action.data.user === user.id) {
      const hasGroup = user.user_groups.find(
        (g) => g.id.toString() === action.data.group.toString()
      );
      if (!hasGroup) {
        user.user_groups.push({
          id: action.data.group,
          name: action.data.label,
        });
        user.user_groups = [...user.user_groups];
      }
    }
    return user;
  });

  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const groupAddUserFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const groupRemoveUserRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const groupRemoveUserSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.map((user) => {
    if (action.data.user === user.id) {
      user.user_groups = user.user_groups.filter(
        (g) => g.id.toString() !== action.data.group.toString()
      );
    }
    return user;
  });

  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const groupRemoveUserFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const HANDLERS = {
  [Types.LIST_USERS_REQUEST]: listUsersRequest,
  [Types.LIST_USERS_SUCCESS]: listUsersSuccess,
  [Types.LIST_USERS_FAILURE]: listUsersFailure,

  [Types.GET_USER_REQUEST]: getUserRequest,
  [Types.GET_USER_SUCCESS]: getUserSuccess,
  [Types.GET_USER_FAILURE]: getUserFailure,

  [Types.DELETE_USER_REQUEST]: deleteUserRequest,
  [Types.DELETE_USER_SUCCESS]: deleteUserSuccess,
  [Types.DELETE_USER_FAILURE]: deleteUserFailure,

  [Types.UPDATE_USER_STATUS_REQUEST]: updateUserStatusRequest,
  [Types.UPDATE_USER_STATUS_SUCCESS]: updateUserStatusSuccess,
  [Types.UPDATE_USER_STATUS_FAILURE]: updateUserStatusFailure,

  [Types.CREATE_USER_PROFILE_REQUEST]: createUserProfileRequest,
  [Types.CREATE_USER_PROFILE_SUCCESS]: createUserProfileSuccess,
  [Types.CREATE_USER_PROFILE_FAILURE]: createUserProfileFailure,

  [Types.UPDATE_USER_PROFILE_REQUEST]: updateUserProfileRequest,
  [Types.UPDATE_USER_PROFILE_SUCCESS]: updateUserProfileSuccess,
  [Types.UPDATE_USER_PROFILE_FAILURE]: updateUserProfileFailure,

  [Types.UPDATE_USER_PASSWORD_REQUEST]: updateUserPasswordRequest,
  [Types.UPDATE_USER_PASSWORD_SUCCESS]: updateUserPasswordSuccess,
  [Types.UPDATE_USER_PASSWORD_FAILURE]: updateUserPasswordFailure,

  [Types.GROUP_ADD_USER_REQUEST]: groupAddUserRequest,
  [Types.GROUP_ADD_USER_SUCCESS]: groupAddUserSuccess,
  [Types.GROUP_ADD_USER_FAILURE]: groupAddUserFailure,

  [Types.GROUP_REMOVE_USER_REQUEST]: groupRemoveUserRequest,
  [Types.GROUP_REMOVE_USER_SUCCESS]: groupRemoveUserSuccess,
  [Types.GROUP_REMOVE_USER_FAILURE]: groupRemoveUserFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
