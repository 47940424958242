import React from 'react';
import EntityTable from 'components/EntityTable';

import DeleteConfirmationModal from './delete-confirmation-modal';
import styles from './users-list.module.scss';

const UsersList = (props) => {
  const {
    users,
    onEdit,
    onDelete,
    onCreate,
    onUpdateStatus,
    listUsers,
    selected,
    onSelectedChange,
    isDeleteModal,
    setIsDeleteModal,
  } = props;

  React.useEffect(() => {
    listUsers();
  }, [listUsers]);

  return (
    <React.Fragment>
      <DeleteConfirmationModal
        isOpen={isDeleteModal}
        setIsDeleteModal={setIsDeleteModal}
        onDelete={onDelete}
      />
      <EntityTable
        state={users}
        onEdit={onEdit}
        onDelete={() => setIsDeleteModal(true)}
        onStatus={onUpdateStatus}
        onPagination={listUsers}
        onFiltersChange={listUsers}
        onRightBtnClick={onCreate}
        rightBtnTitleID="users.create"
        searchable
        filterable
        selectedRow={selected}
      >
        <thead>
          <tr>
            <th></th>
            <th>User Name</th>
            <th>Email Address</th>
            <th>Groups</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody className={styles.tableApex}>
          {users.list.map((user) => {
            return (
              <tr key={user.id}>
                <th scope="row">
                  <input
                    type="checkbox"
                    value={user.id}
                    checked={user.id === selected}
                    onChange={onSelectedChange}
                  />
                </th>
                <td>{`${user.first_name} ${user.last_name}`}</td>
                <td>{user.email}</td>
                <td>
                  {user?.user_groups
                    ?.reduce((acc, { name }) => {
                      return acc + ', ' + name;
                    }, '')
                    .replace(', ', '')}
                </td>
                <td>
                  <div className={styles.borderdStatus}>
                    {user.is_active ? 'Active' : 'Inactive'}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </EntityTable>
    </React.Fragment>
  );
};

export default UsersList;
