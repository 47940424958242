import { connect } from 'react-redux';
import ResetPasswordSuccessScreen from './components/reset-password-success-screen';

import { history } from 'history.js';
import { ROUTES } from 'constants.js';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: ({ reset_code, password }) => {
      history.push(ROUTES.LOGIN);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordSuccessScreen);
