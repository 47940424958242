import React from 'react';
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';
import classnames from 'classnames';
import CsvDownloader from 'react-csv-downloader';
import { FaSearch, FaFileExport } from 'react-icons/fa';
import { ReactComponent as MandateIcon } from 'assets/icons/mandate.svg';

import styles from './filters-menu.module.scss';

const COLUMNS = [
  {
    id: 'company_name',
    displayName: 'CompanyName',
  },
  {
    id: 'address',
    displayName: 'Address',
  },
  {
    id: 'type_of_operation',
    displayName: 'TypeofOperation',
  },
  {
    id: 'application_status',
    displayName: 'Status',
  },
  {
    id: 'airport_name',
    displayName: 'AirportName',
  },
  {
    id: 'country_name',
    displayName: 'CountryName',
  },
  {
    id: 'scope',
    displayName: 'Scope',
  },
  {
    id: 'verification_before',
    displayName: 'VerificationBefore',
  },
];

const FiltersMenu = (props) => {
  const { onFiltersChange, selected, toggleModal, operators } = props;

  const onSearch = React.useCallback(
    (e) => {
      onFiltersChange({ search: e.target.value });
    },
    [onFiltersChange]
  );

  return (
    <Container className={styles.container} fluid>
      <Row>
        <Col lg={8}>
          {props.filters.status === 1 && (
            <div
              className={classnames(styles.actionTab, {
                [styles.active]: !!selected,
              })}
              onClick={selected ? toggleModal : undefined}
            >
              <MandateIcon />
              Mandate Auditor
            </div>
          )}

          <div className={classnames(styles.actionTab, styles.active)}>
            <CsvDownloader
              datas={operators}
              filename="mandate.csv"
              separator=","
              columns={COLUMNS}
            >
              <FaFileExport /> Export Csv
            </CsvDownloader>
          </div>
        </Col>

        <Col lg={4}>
          <InputGroup
            className={classnames('mb-2 mr-sm-2 mb-sm-0', styles.input)}
          >
            <Input
              type="text"
              name="search"
              value={props.filters?.search}
              placeholder="search"
              onChange={onSearch}
            />
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                {' '}
                <FaSearch />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default FiltersMenu;
