import React from 'react';
import { Container, Row, Button } from 'reactstrap';

import styles from './empty-groups.module.scss';

const EmptyGroupsList = (props) => {
  return (
    <Container fluid>
      <Row>
        <Button className={styles.button} onClick={props.onClick}>
          Create Group
        </Button>
      </Row>

      <Row>
        <p className={styles.smallText}>Create your first user group!</p>
      </Row>
    </Container>
  );
};

export default EmptyGroupsList;
