import React from 'react';
import { Row, Col, Form, FormGroup, FormText, Button } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import zxcvbn from 'zxcvbn';
import { useDispatch } from 'react-redux';

import FloatedLabelInput from 'components/FloatedLabelInput';
import { Creators as UsersCreators } from 'services/redux/users/actions';

import styles from './password-form.module.scss';

const validationSchema = Yup.object({
  old_password: Yup.string().required('Old Password is a required field'),
  password: Yup.string().min(7).required('Password is a required field'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password is a required field'),
});

const initialValues = {
  old_password: '',
  password: '',
  confirm_password: '',
};

const strength = {
  0: 'Worst',
  1: 'Bad',
  2: 'Weak',
  3: 'Good',
  4: 'Strong',
};

const PasswordForm = (props) => {
  const dispatch = useDispatch();

  const onSubmit = React.useCallback(
    (values) => {
      dispatch(UsersCreators.updateUserPasswordRequest(values));
    },
    [dispatch]
  );

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });

  const {
    values: { password },
    setFieldValue,
  } = formik;

  React.useEffect(() => {
    const { score } = zxcvbn(password);
    setFieldValue('strength', score);
  }, [setFieldValue, password]);

  return (
    <Col xs={12} md={6}>
      <Form className={props.className} onSubmit={formik.handleSubmit}>
        <h5>Login and Security</h5>

        <Row className={styles.fieldsRow}>
          <Col xs={12} md={4} className={styles.passLabel}>
            <h5>Change Password</h5>
          </Col>

          <Col xs={12} md={8}>
            <FloatedLabelInput
              type="password"
              label="users.old_password"
              name="old_password"
              value={formik.values.old_password}
              onChange={formik.handleChange}
              error={formik.errors.old_password}
            />

            <FormGroup
              className={classnames(
                { [styles.passwordStrengthGroup]: !formik.errors.password },
                styles[strength[formik.values.strength]]
              )}
            >
              <FloatedLabelInput
                type="password"
                label="users.new.password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.errors.password}
              />
              <FormText
                color="muted"
                hidden={!formik.values.password || formik.errors.password}
              >
                <p className={styles.passwordStrength}>
                  {strength[formik.values.strength]}
                </p>
              </FormText>
            </FormGroup>

            <FloatedLabelInput
              type="password"
              label="users.confirm_password"
              name="confirm_password"
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              error={formik.errors.confirm_password}
            />

            <FormGroup className={styles.submit}>
              <Button type="submit">Change Password</Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </Col>
  );
};

export default PasswordForm;
