import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select, { components } from 'react-select';
import classnames from 'classnames';
import { FaSearch } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as UsersCreators } from 'services/redux/users/actions';
import { Creators as OperatorsCreators } from 'services/redux/operators/actions';

import styles from './mandate-modal.module.scss';

const validationSchema = Yup.object({
  auditor: Yup.object({
    value: Yup.string().required('Auditor is a required field'),
  }).required('Auditor is a required field'),
  mandated_hours: Yup.number()
    .positive(' Value must be a  number ')
    .required('Hours is a required field'),
});

const initialValues = {
  auditor: {
    value: '',
  },
  mandated_hours: '',
};

// <FontAwesomeIcon icon={props.selectProps.menuIsOpen ? "caret-up" : "caret-down"}/>

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FaSearch />
      </components.DropdownIndicator>
    )
  );
};

const MandateModal = (props) => {
  const { operators, selected, toggleModal } = props;
  const operator = operators.find((operator) => operator.id === selected);
  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      ...initialValues,
    },
    onSubmit(values) {
      const data = { ...values };
      data.auditor = data.auditor.value;
      data.operator = operator.id;
      dispatch(OperatorsCreators.mandateOperatorRequest(data));
      toggleModal();
    },
  });

  const options = useSelector((store) =>
    store.users.list.map((user) => ({
      label: user.first_name + ' ' + user.last_name,
      value: user.id,
    }))
  );

  const handleInputChange = React.useCallback(
    (search, { action }) => {
      if (action === 'input-change') {
        const filters = { operator: 0, search: search };
        dispatch(UsersCreators.listUsersRequest(filters));
      }
    },
    [dispatch]
  );

  const { setFieldValue } = formik;
  const onAuditorChange = React.useCallback(
    (auditor) => {
      if (auditor) {
        setFieldValue('auditor', auditor);
      }
    },
    [setFieldValue]
  );

  return (
    <Modal centered isOpen toggle={props.toggleModal} className={styles.modal}>
      <Form onSubmit={formik.handleSubmit}>
        <ModalHeader toggle={props.toggleModal} className={styles.modalHeader}>
          Mandate Auditors
        </ModalHeader>

        <ModalBody className={styles.modalBody}>
          <FormGroup>
            <Label>{operator?.company_name}</Label>
          </FormGroup>

          <FormGroup>
            <Label className={styles.label}>Auditor</Label>
            <Select
              components={{ DropdownIndicator }}
              className={classnames(styles.field, {
                'is-invalid': !!formik.errors.auditor?.value,
              })}
              value={formik.values.auditor}
              onInputChange={handleInputChange}
              onChange={onAuditorChange}
              placeholder="search"
              options={options}
              isFocused={false}
              name="auditor"
            />
            <FormFeedback>{formik.errors.auditor?.value}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label className={styles.label}>Hours</Label>
            <Input
              className={styles.field}
              type="number"
              name="mandated_hours"
              placeholder="Input Number"
              onChange={formik.handleChange}
              value={formik.values.mandated_hours}
              invalid={!!formik.errors.mandated_hours}
            />
            <FormFeedback>{formik.errors.mandated_hours}</FormFeedback>
          </FormGroup>
        </ModalBody>

        <ModalFooter className={styles.modalFooter}>
          <Button color="primary" type="submit">
            Mandate
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default (props) => {
  if (!props.isModalOpen) {
    return null;
  }

  return <MandateModal {...props} />;
};
