import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './submit-modal.module.scss';

const SubmitModal = (props) => {
  return (
    <Modal
      centered
      isOpen
      toggle={props.toggleSubmitModal}
      className={styles.modal}
    >
      <ModalHeader
        toggle={props.toggleSubmitModal}
        className={styles.modalHeader}
      >
        <h5>
          Submit Application
          <span className={styles.closeIcon}>
            {' '}
            <FontAwesomeIcon icon={faTimes} onClick={props.cancelApplication} />
          </span>{' '}
        </h5>
      </ModalHeader>

      <ModalBody className={styles.modalBody}>
        <p>
          Please Note You won't be able to edit unless you send an e-mail to
          ecocert
        </p>

        <Button onClick={props.submitApplication}>Submit</Button>
      </ModalBody>
    </Modal>
  );
};

export default (props) => {
  if (!props.submitModal) {
    return null;
  }

  return <SubmitModal {...props} />;
};
