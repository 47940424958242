import React from 'react';
import { Row } from 'reactstrap';

import Card from './card';

const CardsList = (props) => {
  return (
    <Row>
      {props.operator && (
        <Card {...props} operator={props.operator} isOperator />
      )}

      {props.operatorCompanies.map((value, index) => {
        return <Card {...props} key={index} operator={value} />;
      })}
    </Row>
  );
};

export default CardsList;
