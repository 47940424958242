import { call, put, takeLeading } from 'redux-saga/effects';

import { history } from 'history.js';
import { ROUTES } from 'constants.js';

import { Creators, Types } from '../../redux/auth/actions';
// import { Creators as UserCreators, Types as UserTypes } from '../../redux/user/actions';
import {
  login as loginApi,
  forgotPassword as forgotPasswordApi,
  resetPassword as resetPasswordApi,
} from '../../api/auth';
import { toastErrorResponse } from '../errors';

export function* login(actions) {
  try {
    const { email, password } = actions;
    const response = yield call(loginApi, { email, password });
    yield put(Creators.loginSuccess(response.data));
    const user = response.data.data.user;
    const redirectURL = sessionStorage.getItem('redirectURL');

    if (user.is_operator) {
      history.push(ROUTES.APPLICATIONS);
    }
    if (redirectURL) {
      sessionStorage.setItem('redirectURL', '');
      history.push(redirectURL);
    } else {
      history.push('/user-management');
    }
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(Creators.loginFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* forgotPassword(actions) {
  try {
    const { email } = actions;
    yield call(forgotPasswordApi, { email });
    yield put(Creators.forgotPasswordSuccess({ email }));
    history.push(ROUTES.RESEND_PASSWORD);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(Creators.forgotPasswordFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* resetPassword(actions) {
  try {
    const { reset_code, password } = actions;
    const response = yield call(resetPasswordApi, { reset_code, password });
    yield put(Creators.resetPasswordSuccess(response.data));
    history.push(ROUTES.RESET_PASSWORD_SUCCESS);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(Creators.resetPasswordFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* watchAuth() {
  yield takeLeading(Types.LOGIN_REQUEST, login);
  yield takeLeading(Types.FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeLeading(Types.RESET_PASSWORD_REQUEST, resetPassword);
}
