import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    LIST_VERIFICATIONS_REQUEST
    LIST_VERIFICATIONS_SUCCESS
    LIST_VERIFICATIONS_FAILURE

    ADD_VERIFICATION_REQUEST
    ADD_VERIFICATION_SUCCESS
    ADD_VERIFICATION_FAILURE

    EXEMPT_VERIFICATION_REQUEST
    EXEMPT_VERIFICATION_SUCCESS
    EXEMPT_VERIFICATION_FAILURE

  `,
  {}
);
