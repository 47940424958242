import React from 'react';
import { connect } from 'react-redux';

import LayoutComponent from './components/layout';
import { Creators } from '../../services/redux/auth/actions';

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    language: state.auth.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLanguage: (lang) => {
      dispatch(Creators.changeLanguageRequest(lang));
    },
    logout: () => {
      dispatch(Creators.logout());
    },
  };
};

export const Layout = connect(
  mapStateToProps,
  mapDispatchToProps
)(LayoutComponent);

export const withLayout = (WrappedComponent) => {
  return (props) => {
    return (
      <Layout {...props}>
        <WrappedComponent {...props} />
      </Layout>
    );
  };
};

export default {
  Page: Layout,
  withLayout,
};
