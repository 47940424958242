import { call, put, takeLeading } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { Types as CollectorsTypes } from '../../redux/collectors/actions';

import {
  createCollector as CreateCollectorOperatorApi,
  getCollectors as listCollectorsApi,
  updateCollector as updateCollectorApi,
  deleteCollector as deleteCollectorApi,
} from '../../api/operators';
import { toastErrorResponse } from '../errors';
import { Creators as CreatorCollectors } from '../../redux/collectors/actions';

export function* listCollectors(action) {
  try {
    console.log('success');
    const response = yield call(listCollectorsApi, action.filters);
    yield put(CreatorCollectors.listCollectorsSuccess(response.data));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(CreatorCollectors.listCollectorsFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* createCollector(action) {
  try {
    const response = yield call(CreateCollectorOperatorApi, action.collector);
    yield put(CreatorCollectors.createCollectorSuccess(response.data.data));
    toast.success(`Collector created successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(CreatorCollectors.createCollectorFailure(error_message));
    toastErrorResponse(error);
  }
}

/**
export function* getOperator({ id }) {
  try {
    const response = yield call(getOperatorApi, id);
    yield put(OperatorsCreators.getOperatorSuccess(response.data.data));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(OperatorsCreators.getOperatorFailure(error_message));
    toastErrorResponse(error);
  }
}
**/
export function* updateCollector(data) {
  try {
    console.log(data);
    const response = yield call(updateCollectorApi, data.collector);
    yield put(CreatorCollectors.updateCollectorSuccess(response.data.data));
    toast.success(`Collector profile updated successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(CreatorCollectors.updateCollectorFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* deleteCollector(data) {
  try {
    const response = yield call(deleteCollectorApi, data.collector);
    yield put(CreatorCollectors.deleteCollectorSuccess(response));
    toast.success(`Collector profile deleted successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(CreatorCollectors.deleteCollectorFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* watchCollectors() {
  yield takeLeading(CollectorsTypes.CREATE_COLLECTOR_REQUEST, createCollector);
  yield takeLeading(CollectorsTypes.LIST_COLLECTORS_REQUEST, listCollectors);
  yield takeLeading(CollectorsTypes.UPDATE_COLLECTOR_REQUEST, updateCollector);
  yield takeLeading(CollectorsTypes.DELETE_COLLECTOR_REQUEST, deleteCollector);
}
