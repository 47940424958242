import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = {
  isLoading: false,
  isError: false,
};

const LOADING_STATE = {
  isLoading: true,
  isError: false,
};

const SUCCESS_STATE = {
  isLoading: false,
  isError: false,
};

const ERROR_STATE = {
  isLoading: false,
  isError: true,
};

export const scheduleSiteRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const scheduleSiteSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...SUCCESS_STATE,
  };
};

export const scheduleSiteFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const editScheduleSiteRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const editScheduleSiteSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...SUCCESS_STATE,
  };
};

export const editScheduleSiteFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const HANDLERS = {
  [Types.SCHEDULE_SITE_REQUEST]: scheduleSiteRequest,
  [Types.SCHEDULE_SITE_SUCCESS]: scheduleSiteSuccess,
  [Types.SCHEDULE_SITE_FAILURE]: scheduleSiteFailure,

  [Types.EDIT_SCHEDULE_SITE_REQUEST]: editScheduleSiteRequest,
  [Types.EDIT_SCHEDULE_SITE_SUCCESS]: editScheduleSiteSuccess,
  [Types.EDIT_SCHEDULE_SITE_FAILURE]: editScheduleSiteFailure,
};
export default createReducer(INITIAL_STATE, HANDLERS);
