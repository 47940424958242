import { toast } from 'react-toastify';

export const toastErrorResponse = (error) => {
  const errs = error?.response?.data?.data || [];

  if (!errs.length && error?.message) {
    toast.error(error?.message);
  }

  for (const key in errs) {
    const e = errs[key];
    if (Array.isArray(e)) {
      e.forEach((err) => {
        toast.error(`${key}: ${err}`);
      });
    } else if (typeof e === 'string') {
      toast.error(e);
      // toast.error(`${key} ${e}`);
    }
  }
};
