import { apiRequest } from '../apiRequest';
import {
  USERS_URL,
  USERS_CHANGE_PASSWORD_URL,
  USERS_ACTIVATE_URL,
  USERS_DEACTIVATE_URL,
  GROUPS_ADD_USER_URL,
  GROUPS_REMOVE_USER_URL,
} from '../urls';

export const listUsers = ({
  limit: page_size,
  current_page: page = 1,
  search,
  status,
  operator = null,
}) => {
  const filters = {
    page_size,
    page,
  };

  if (search) {
    filters.search = search;
  }

  if (status === '0' || status === '1') {
    filters.status = status;
  }
  if (operator !== null) {
    filters.operator = operator;
  }

  const searchParams = new URLSearchParams(filters).toString();
  return apiRequest('GET', `${USERS_URL}?${searchParams}`);
};

export const getUser = (id) => {
  return apiRequest('GET', `${USERS_URL}/${id}`);
};

export const deleteUser = (id) => {
  return apiRequest('DELETE', `${USERS_URL}/${id}`);
};

export const updateUserStatus = (user) => {
  const URL = user.is_active ? USERS_DEACTIVATE_URL : USERS_ACTIVATE_URL;
  return apiRequest('POST', `${URL}`, { user_id: user.id });
};

export const createUserProfile = (user) => {
  return apiRequest('POST', USERS_URL, user);
};

export const updateUserProfile = ({ id, ...data }) => {
  return apiRequest('PATCH', `${USERS_URL}/${id}`, data);
};

export const updateUserPassword = (data) => {
  return apiRequest('POST', USERS_CHANGE_PASSWORD_URL, data);
};

export const groupAddUser = (data) => {
  return apiRequest('POST', GROUPS_ADD_USER_URL, data);
};

export const groupRemoveUser = (data) => {
  return apiRequest('POST', GROUPS_REMOVE_USER_URL, data);
};
