import React from 'react';
import { Container } from 'reactstrap';

import Tabs from './tabs';
import ApplicationList from './application-list';

const ApplicationsListing = (props) => {
  return (
    <Container fluid>
      <Tabs tid={props.filters.status} onChange={props.onFiltersChange} />
      <ApplicationList {...props} />
    </Container>
  );
};

export default ApplicationsListing;
