import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    listGroupsRequest: [
      'filters', // { "limit", "page", "search", "status" }
    ],
    listGroupsSuccess: ['responseData'],
    listGroupsFailure: ['error'],

    getGroupRequest: ['id'],
    getGroupSuccess: ['group'],
    getGroupFailure: ['error'],

    updateGroupRequest: ['group'],
    updateGroupSuccess: ['group'],
    updateGroupFailure: ['error'],

    deleteGroupRequest: ['id'],
    deleteGroupSuccess: ['id'],
    deleteGroupFailure: ['error'],

    updateGroupStatusRequest: ['group'],
    updateGroupStatusSuccess: ['group'],
    updateGroupStatusFailure: ['error'],

    listGroupsPermissionsRequest: [],
    listGroupsPermissionsSuccess: ['permissions'],
    listGroupsPermissionsFailure: ['error'],

    createGroupRequest: ['group'],
    createGroupSuccess: ['group'],
    createGroupFailure: ['error'],
  },
  {}
);
