import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Form,
  FormText,
  Button,
  Spinner,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';

import { LoginCoverImage } from 'components/LoginCoverImage';
import { AppDescText } from 'components/AppDescText';

import styles from './resend-password-screen.module.scss';

const LoginScreen = ({ onResendPassword: onSubmit, auth }) => {
  const formik = useFormik({
    onSubmit,
    initialValues: {
      email: auth.forgotPassword?.email,
    },
  });

  return (
    <Container fluid>
      <Row>
        <Col
          className={styles.formCol}
          md={{ size: 6, offset: 2 }}
          xl={{ size: 3, offset: 1 }}
        >
          <img
            className={styles.logoImg}
            alt="apex logo"
            src={require('assets/imgs/apex-logo.png')}
          />
          <AppDescText />

          <h2 className={styles.formHeader}>
            <FormattedMessage id={'users.resend.password.header'} />
          </h2>

          <Form onSubmit={formik.handleSubmit}>
            <FormText color="muted">
              <p className={styles.resendPasswordHint}>
                <FormattedMessage id={'users.resend.password.hint'} />
              </p>
            </FormText>

            {auth.isLoading ? (
              <Spinner className={styles.spinner} />
            ) : (
              <Button type="submit" className={styles.submitButton}>
                <FormattedMessage id={'users.resend.password.submit'} />
              </Button>
            )}
          </Form>
        </Col>

        <LoginCoverImage />
      </Row>
    </Container>
  );
};

LoginScreen.propTypes = {
  onForgotPassword: PropTypes.func,
  onLogin: PropTypes.func,
};

export default LoginScreen;
