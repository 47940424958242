import React from 'react';
import { withLayout } from 'containers/Layout';
import { useDispatch } from 'react-redux';

import { Creators as OperatorsCreators } from 'services/redux/operators/actions';

import ApplicationsListing from './components';
import { APPLICATION_STATUSES, APPLICATION_STATUSES_LIST } from 'constants.js';

const defaultFilters = {
  page_size: 10,
  page: 1,
  status: 0,
  search: '',
};

const Screen = () => {
  const dispatch = useDispatch();

  const [selectedOperator, selectedOperatorSet] = React.useState('');
  const [commitModal, commitModalSet] = React.useState(false);
  const [rejectModal, rejectModalSet] = React.useState(false);
  const [filters, filtersSet] = React.useState(defaultFilters);
  const [enableModal, enableModalSet] = React.useState(false);

  const onFiltersChange = React.useCallback(
    (values) => {
      if ('page' in values && +values.page !== +filters.page) {
        filtersSet({ ...filters, ...values });
      } else {
        filtersSet({ ...defaultFilters, status: filters.status, ...values });
      }
      selectedOperatorSet('');
    },
    [filters, filtersSet, selectedOperatorSet]
  );

  const toggleEnableModel = React.useCallback(() => {
    enableModalSet((prev) => !prev);
  }, [enableModalSet]);

  const toggleCommitModal = React.useCallback(() => {
    commitModalSet((prev) => !prev);
  }, [commitModalSet]);

  const toggleRejectModal = React.useCallback(() => {
    rejectModalSet((prev) => !prev);
  }, [rejectModalSet]);

  const commitApplication = React.useCallback(() => {
    dispatch(
      OperatorsCreators.commitOrRejectOperatorRequest({
        operator_id: selectedOperator,
        status: APPLICATION_STATUSES.COMMITTED,
      })
    );
    commitModalSet(false);
  }, [dispatch, commitModalSet, selectedOperator]);

  const enableRediting = React.useCallback(() => {
    dispatch(
      OperatorsCreators.statusOperatorRequest({
        operator_id: selectedOperator,
        status: APPLICATION_STATUSES.REQUESTED,
      })
    );
    enableModalSet(false);
  }, [dispatch, enableModalSet, selectedOperator]);

  const rejectApplication = React.useCallback(() => {
    dispatch(
      OperatorsCreators.commitOrRejectOperatorRequest({
        operator_id: selectedOperator,
        status: APPLICATION_STATUSES.REJECTED,
      })
    );
    rejectModalSet(false);
  }, [dispatch, rejectModalSet, selectedOperator]);

  React.useEffect(() => {
    const queries = {
      ...filters,
      status: APPLICATION_STATUSES_LIST[filters.status],
    };

    if (!queries.status) {
      delete queries.status;
    }

    dispatch(OperatorsCreators.listOperatorsRequest(queries));
  }, [dispatch, filters, commitModal, rejectModal]);

  return (
    <ApplicationsListing
      filters={filters}
      onFiltersChange={onFiltersChange}
      selectedOperator={selectedOperator}
      selectedOperatorSet={selectedOperatorSet}
      commitModal={commitModal}
      rejectModal={rejectModal}
      toggleCommitModal={toggleCommitModal}
      toggleRejectModal={toggleRejectModal}
      commitApplication={commitApplication}
      rejectApplication={rejectApplication}
      enableModal={enableModal}
      toggleEnableModel={toggleEnableModel}
      enableRediting={enableRediting}
    />
  );
};

export default withLayout(Screen);
