import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './index.module.scss';

export const AppDescText = () => {
  return (
    <h2 className={styles.text}>
      <FormattedMessage
        id={'users.login.coverimg.header'}
        values={{ break: null }}
      />
    </h2>
  );
};

export default AppDescText;
