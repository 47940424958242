import { apiRequest } from '../apiRequest';
import { SCHEDULE_URL } from '../urls';

export const schedule = (payload) => {
  return apiRequest('POST', SCHEDULE_URL, payload);
};

export const editSchedule = (payload) => {
  return apiRequest('PATCH', `${SCHEDULE_URL}/${payload.id}`, payload);
};
