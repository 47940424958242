import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Input,
} from 'reactstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import classnames from 'classnames';

import styles from './pagination-controller.module.scss';

const PaginationController = ({ state, onPagination }) => {
  const [pages, setPages] = React.useState([]);

  React.useEffect(() => {
    const totalPages = Array.from(Array(state.pagination.num_pages)).map(
      (_, i) => i + 1
    );
    setPages(totalPages);
  }, [state.pagination.num_pages]);

  const handlePagination = React.useCallback(
    (e) => {
      const { target } = e;
      let element =
        target.tagName !== 'BUTTON' && target.tagName !== 'SELECT'
          ? target.parentElement
          : target;

      if (element.tagName === 'SPAN') {
        element = element.parentElement;
      }

      if (element.tagName === 'BUTTON' || element.tagName === 'SELECT') {
        const { name, value } = element;
        onPagination && onPagination({ [name]: value });
      }
    },
    [onPagination]
  );

  let nextPage = parseInt(state.pagination.current_page) + 1;
  nextPage = isNaN(nextPage) ? 0 : nextPage;
  let prevPage = parseInt(state.pagination.current_page) - 1;
  prevPage = isNaN(prevPage) ? 0 : prevPage;

  return (
    <Row className={styles.row}>
      <Pagination
        aria-label="Page navigation"
        className={styles.pagination}
        onClick={handlePagination}
      >
        <PaginationItem disabled={!(state.pagination.current_page > 1)}>
          <PaginationLink first name="current_page" value={1}>
            <FaChevronLeft />
          </PaginationLink>
        </PaginationItem>

        <PaginationItem
          disabled={prevPage <= 0 || prevPage > state.pagination.current_page}
        >
          <PaginationLink previous name="current_page" value={prevPage}>
            Prev
          </PaginationLink>
        </PaginationItem>

        {pages.map((page) => {
          return (
            <PaginationItem
              key={page}
              active={state.pagination.current_page === page}
            >
              <PaginationLink name="current_page" value={page}>
                {page}
              </PaginationLink>
            </PaginationItem>
          );
        })}

        <PaginationItem disabled={nextPage > state.pagination.num_pages}>
          <PaginationLink name="current_page" value={nextPage} next>
            Next
          </PaginationLink>
        </PaginationItem>

        <PaginationItem
          disabled={
            !(state.pagination.current_page < state.pagination.num_pages)
          }
        >
          <PaginationLink
            name="current_page"
            value={state.pagination.num_pages}
            last
          >
            <FaChevronRight />
          </PaginationLink>
        </PaginationItem>
      </Pagination>

      <FormGroup className={classnames(styles.limit, styles.formControl)}>
        <Input
          type="select"
          name="limit"
          value={`${state.pagination.limit}`}
          onChange={handlePagination}
          className={styles.selectPagination}
        >
          <option value={'10'}>10 Per Page</option>
          <option value={'25'}>25 Per Page</option>
        </Input>
      </FormGroup>
    </Row>
  );
};

PaginationController.propTypes = {
  onPagination: PropTypes.func.isRequired,
};

export default PaginationController;
