import React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import styles from './additional.module.scss';
import { Creators as OperatorsCreator } from 'services/redux/operators/actions';
import { APPLICATION_STATUSES } from '../../../constants';
import SubmitModal from './components/submit-modal';
const validationSchema = Yup.object({
  additional_info: Yup.string().nullable(),
});

const initialValues = {
  additional_info: '',
};

const AdditionalScreen = (props) => {
  const dispatch = useDispatch();

  const { goNext, goBack, steps, activeStep } = props;
  const operatorData = useSelector((store) => store.operators.list[0] || {});

  const onSubmit = React.useCallback(
    (values) => {
      dispatch(OperatorsCreator.updateOperatorRequest(values));
      goNext();
    },
    [dispatch, goNext]
  );

  React.useEffect(() => {
    dispatch(OperatorsCreator.getOperatorRequest(props.operator));
  }, [dispatch, props.operator]);

  const formik = useFormik({
    onSubmit,
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...operatorData,
    },
  });

  const [submitModal, submitModalSet] = React.useState(false);

  const toggleSubmitModal = React.useCallback(() => {
    submitModalSet((prev) => !prev);
  }, [submitModalSet]);

  const cancelApplication = React.useCallback(() => {
    submitModalSet(false);
  }, [submitModalSet]);

  const submitApplication = React.useCallback(() => {
    const data = {
      application_status: 'applied',
      id: formik.values.id,
      additional_info: formik.values.additional_info,
    };
    dispatch(OperatorsCreator.updateOperatorRequest(data));
    goNext();
    toggleSubmitModal();
  }, [dispatch, goNext, formik, toggleSubmitModal]);

  return (
    <React.Fragment>
      <Container fluid>
        <Row className={styles.top30}>
          <Form
            className={styles.additionalForm}
            onSubmit={formik.handleSubmit}
          >
            <Col xs={12} lg={12} md={12}>
              <FormGroup>
                <label>
                  Please add any additional information you need us to
                  understand about your project (Optional){' '}
                </label>
                <Input
                  className={styles.additionalInfo}
                  type="textarea"
                  name="additional_info"
                  value={formik.values.additional_info}
                  onChange={formik.handleChange}
                  disabled={
                    operatorData.application_status !==
                    APPLICATION_STATUSES.REQUESTED
                  }
                />
              </FormGroup>
            </Col>
            <Col xs={12} lg={12} md={12}>
              <Button className={styles.previousButton} onClick={goBack}>
                Back
              </Button>
              {operatorData.application_status !==
                APPLICATION_STATUSES.REQUESTED && (
                <Button
                  disabled={!formik.isValid}
                  className={styles.submitButton}
                  onClick={goNext}
                >
                  {activeStep === steps.length - 1 ? 'Done' : 'Next'}
                </Button>
              )}
              {operatorData.application_status ===
                APPLICATION_STATUSES.REQUESTED && (
                <div>
                  <Button
                    disabled={!formik.isValid}
                    className={styles.submitButton}
                    onClick={toggleSubmitModal}
                  >
                    {activeStep === steps.length - 1 ? 'Submit ' : 'Next'}
                  </Button>
                  <Button
                    disabled={!formik.isValid}
                    className={styles.submitButton}
                    type="submit"
                  >
                    {activeStep === steps.length - 1 ? 'Save ' : 'Next'}
                  </Button>
                </div>
              )}
            </Col>
          </Form>
        </Row>
      </Container>
      <SubmitModal
        submitModal={submitModal}
        cancelApplication={cancelApplication}
        submitApplication={submitApplication}
      />
    </React.Fragment>
  );
};

export default AdditionalScreen;
