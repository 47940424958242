import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    listCollectorsRequest: ['filters'],
    listCollectorsSuccess: ['collectors'],
    listCollectorsFailure: ['error'],

    createCollectorRequest: ['collector'],
    createCollectorSuccess: ['collector'],
    createCollectorFailure: ['error'],

    getCollectorRequest: ['id'],
    getCollectorSuccess: ['collector'],
    getCollectorFailure: ['error'],

    updateCollectorRequest: ['collector'],
    updateCollectorSuccess: ['collector'],
    updateCollectorFailure: ['error'],

    deleteCollectorRequest: ['collector'],
    deleteCollectorSuccess: ['collector'],
    deleteCollectorFailure: ['error'],
  },
  {}
);
