import React from 'react';
import { withLayout } from 'containers/Layout';
import { useDispatch, useSelector } from 'react-redux';

import ViewResults from './components';
import { Creators as VerificationsCreators } from 'services/redux/verifications/actions';

const statuses = ['', 'not started', 'in progress', 'exempted', 'finalized'];

const defaultFilters = {
  page_size: 10,
  page: 1,
  verification: 0,
  search: '',
};

export const Screen = () => {
  const dispatch = useDispatch();
  const pagination = useSelector((store) => store.verifications.pagination);
  const verifications = useSelector((store) => store.verifications.list);
  const [filters, filtersSet] = React.useState(defaultFilters);
  const [selected, selectedSet] = React.useState('');
  const [isAddOpen, isAddOpenSet] = React.useState(false);
  const [isExemptedOpen, isExemptedOpenSet] = React.useState(false);

  const verification = verifications.find((v) => v.id === selected);

  const toggleAddModal = React.useCallback(() => {
    isAddOpenSet((v) => !v);
  }, [isAddOpenSet]);

  const toggleExemptModal = React.useCallback(() => {
    isExemptedOpenSet((v) => !v);
  }, [isExemptedOpenSet]);

  const onFiltersChange = React.useCallback(
    (values) => {
      if (
        ('page' in values && +values.page !== +filters.page) ||
        'search' in values
      ) {
        filtersSet({ ...filters, ...values });
      } else {
        filtersSet({ ...defaultFilters, ...values });
      }
      selectedSet('');
    },
    [filters, filtersSet, selectedSet]
  );

  React.useEffect(() => {
    const queries = {
      ...filters,
      verification: statuses[filters.verification],
    };

    if (queries.verification === '') {
      delete queries.verification;
    }

    dispatch(VerificationsCreators.listVerificationsRequest(queries));
  }, [dispatch, filters]);

  return (
    <ViewResults
      verifications={verifications}
      verification={verification}
      selected={selected}
      selectedSet={selectedSet}
      filters={filters}
      filtersSet={filtersSet}
      onFiltersChange={onFiltersChange}
      pagination={pagination}
      isAddOpen={isAddOpen}
      toggleAddModal={toggleAddModal}
      isExemptedOpen={isExemptedOpen}
      toggleExemptModal={toggleExemptModal}
    />
  );
};

export default withLayout(Screen);
