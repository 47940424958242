import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = {
  isLoading: false,
  list: [],
  isError: false,
};

const LOADING_STATE = {
  isLoading: true,
  isError: false,
};

const SUCCESS_STATE = {
  isLoading: false,
  isError: false,
};

const ERROR_STATE = {
  isLoading: false,
  isError: true,
};

export const listOrganizationsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const listOrganizationsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    list: action.organizations.data.results,
    ...SUCCESS_STATE,
  };
};

export const listOrganizationsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const createOrganizationRequest = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const createOrganizationSuccess = (state = INITIAL_STATE, action) => {
  action.organization.is_new = false;
  const list = [...state.list, action.organization];

  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const createOrganizationFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const getOrganizationRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const getOrganizationSuccess = (state = INITIAL_STATE, action) => {
  let newState = {
    ...state,
    list: [action.operator],
    ...SUCCESS_STATE,
  };
  return newState;
};

export const getOrganizationFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const updateOrganizationRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const updateOrganizationSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.map((organization) => {
    if (organization.id === action.organization.id) {
      return action.organization;
    }
    return organization;
  });

  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const updateOrganizationFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const deleteOrganizationRequest = (state = INITIAL_STATE, action) => {
  action.organization.is_new = false;
  const list = state.list.filter(
    (organization) => organization.id !== action.organization.id
  );
  return {
    ...state,
    list,
    ...LOADING_STATE,
  };
};

export const deleteOrganizationSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.filter(
    (value) => value.id !== action.organization.id
  );
  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const deleteOrganizationFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const HANDLERS = {
  [Types.LIST_ORGANIZATIONS_REQUEST]: listOrganizationsRequest,
  [Types.LIST_ORGANIZATIONS_SUCCESS]: listOrganizationsSuccess,
  [Types.LIST_ORGANIZATIONS_FAILURE]: listOrganizationsFailure,

  [Types.CREATE_ORGANIZATION_REQUEST]: createOrganizationRequest,
  [Types.CREATE_ORGANIZATION_SUCCESS]: createOrganizationSuccess,
  [Types.CREATE_ORGANIZATION_FAILURE]: createOrganizationFailure,

  [Types.GET_ORGANIZATION_REQUEST]: getOrganizationRequest,
  [Types.GET_ORGANIZATION_SUCCESS]: getOrganizationSuccess,
  [Types.GET_ORGANIZATION_FAILURE]: getOrganizationFailure,

  [Types.UPDATE_ORGANIZATION_REQUEST]: updateOrganizationRequest,
  [Types.UPDATE_ORGANIZATION_SUCCESS]: updateOrganizationSuccess,
  [Types.UPDATE_ORGANIZATION_FAILURE]: updateOrganizationFailure,

  [Types.DELETE_ORGANIZATION_REQUEST]: deleteOrganizationRequest,
  [Types.DELETE_ORGANIZATION_SUCCESS]: deleteOrganizationSuccess,
  [Types.DELETE_ORGANIZATION_FAILURE]: deleteOrganizationFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
