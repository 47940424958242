import React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
} from 'reactstrap';
import classnames from 'classnames';

import FloatedLabelInput from 'components/FloatedLabelInput';
import GroupsandPermissionsPanel from 'components/GroupsandPermissionsPanel';

import styles from './index.module.scss';

const CreateUser = (props) => {
  const { users, groups, onSearchGroups, formik, onAddGroup, onRemove } = props;

  return (
    <Container fluid>
      <header className={styles.header}>User Profile</header>

      <Form onSubmit={formik.handleSubmit}>
        <FormGroup className={styles.imageAvatar}>
          <img
            src={require('assets/imgs/user-avatar-394.png')}
            alt="user-avatar-394.png"
          />
        </FormGroup>
        <FormGroup row>
          <Col xs={12} md={6}>
            <FormGroup className={styles.formGroupPane}>
              <h5>Basic Information</h5>

              <FloatedLabelInput
                label="users.first.name"
                name="first_name"
                onChange={formik.handleChange}
                error={formik.errors.first_name}
              />

              <FloatedLabelInput
                label="users.last.name"
                name="last_name"
                onChange={formik.handleChange}
                error={formik.errors.last_name}
              />

              <FloatedLabelInput
                label="users.phone.number"
                name="msisdn"
                onChange={formik.handleChange}
                error={formik.errors.msisdn}
              />

              <FloatedLabelInput
                label="users.email.address"
                type="email"
                name="email"
                onChange={formik.handleChange}
                error={formik.errors.email}
              />

              <FormGroup>
                <Label>Preferred Language</Label>
                <Input
                  type="select"
                  name="language"
                  onChange={formik.handleChange}
                  invalid={!!formik.errors.language}
                >
                  <option value="en">EN</option>
                  <option value="fr">FR</option>
                </Input>
                <FormFeedback>{formik.errors.language}</FormFeedback>
              </FormGroup>
            </FormGroup>
          </Col>

          <Col xs={12} md={6}>
            <GroupsandPermissionsPanel
              edit
              onRemove={onRemove}
              onAdd={onAddGroup}
              selected={formik.values.user_groups}
              className={classnames(styles.formGroupPane, styles.groupsPane)}
              options={groups.list}
              onSearch={onSearchGroups}
            />
          </Col>
        </FormGroup>

        <Row className={styles.btnRow}>
          <FormGroup>
            <Col>
              <Button
                className={styles.submitButton}
                disabled={users.isLoading}
              >
                Create User
              </Button>
            </Col>
          </FormGroup>
        </Row>
      </Form>
    </Container>
  );
};

export default CreateUser;
