import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withLayout } from 'containers/Layout';
import Users from './components';
import { Creators as UsersCreators } from 'services/redux/users/actions';

import { history } from 'history.js';
import { ROUTES } from 'constants.js';

const Screen = () => {
  const dispatch = useDispatch();

  const users = useSelector(({ users }) => users);

  const [selected, setSelected] = React.useState('');
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);

  const listUsers = React.useCallback(
    (...props) => {
      dispatch(UsersCreators.listUsersRequest(...props));
    },
    [dispatch]
  );

  const onSelectedChange = React.useCallback(
    (e) => {
      const { value = '' } = e.target;
      if (value === selected) {
        setSelected('');
      } else {
        setSelected(value);
      }
    },
    [selected]
  );

  const onEdit = React.useCallback(() => {
    history.push(ROUTES.USER_MANAGEMENT.USERS_PROFILE.replace(':id', selected));
  }, [selected]);

  const onCreate = React.useCallback(() => {
    history.push(ROUTES.USER_MANAGEMENT.CREATE_USER);
  }, []);

  const onUpdateStatus = React.useCallback(() => {
    const user = users.list.find(({ id }) => selected === id.toString());
    if (user) {
      dispatch(UsersCreators.updateUserStatusRequest(user));
    }
  }, [dispatch, selected, users.list]);

  const onDelete = React.useCallback(() => {
    setIsDeleteModal(false);
    dispatch(UsersCreators.deleteUserRequest(selected));
    setSelected('');
  }, [setIsDeleteModal, dispatch, selected, setSelected]);

  return (
    <Users
      listUsers={listUsers}
      users={users}
      selected={selected}
      onSelectedChange={onSelectedChange}
      onEdit={onEdit}
      onCreate={onCreate}
      onUpdateStatus={onUpdateStatus}
      onDelete={onDelete}
      isDeleteModal={isDeleteModal}
      setIsDeleteModal={setIsDeleteModal}
    />
  );
};

export default withLayout(Screen);
