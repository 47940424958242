import { apiRequest } from '../apiRequest';
import { OPERATORS_URL, OPERATORS_VERIFICATION_URL } from '../urls';

export const listVerifications = (filters) => {
  return apiRequest('GET', OPERATORS_URL, null, filters);
};

export const addVerification = (data) => {
  if (data.id) {
    return apiRequest(
      'PATCH',
      `${OPERATORS_VERIFICATION_URL}/${data.id}`,
      data
    );
  }
  return apiRequest('POST', OPERATORS_VERIFICATION_URL, data);
};

export const exemptVerification = (data) => {
  if (!data.id) {
    return apiRequest('POST', `${OPERATORS_VERIFICATION_URL}`, data);
  }
  return apiRequest('PATCH', `${OPERATORS_VERIFICATION_URL}/${data.id}`, data);
};
