import React from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import {
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Input,
} from 'reactstrap';
import classnames from 'classnames';

import styles from './index.module.scss';

const Paginator = ({ page_size, num_pages, current_page, onPagination }) => {
  const [pages, setPages] = React.useState([]);

  React.useEffect(() => {
    const totalPages = Array.from(Array(num_pages)).map((_, i) => i + 1);
    setPages(totalPages);
  }, [num_pages]);

  const handlePagination = React.useCallback(
    (e) => {
      const { target } = e;
      let element =
        target.tagName !== 'BUTTON' && target.tagName !== 'SELECT'
          ? target.parentElement
          : target;

      if (element.tagName === 'SPAN') {
        element = element.parentElement;
      }

      if (element.tagName === 'BUTTON' || element.tagName === 'SELECT') {
        const { name, value } = element;
        onPagination && onPagination({ [name]: value });
      }
    },
    [onPagination]
  );

  let nextPage = parseInt(current_page) + 1;
  nextPage = isNaN(nextPage) ? 0 : nextPage;
  let prevPage = parseInt(current_page) - 1;
  prevPage = isNaN(prevPage) ? 0 : prevPage;

  return (
    <Row className={styles.container}>
      <Pagination
        aria-label="Page navigation"
        className={styles.pagination}
        onClick={handlePagination}
      >
        <PaginationItem disabled={!(current_page > 1)}>
          <PaginationLink first name="page" value={1}>
            <FaChevronLeft />
          </PaginationLink>
        </PaginationItem>

        <PaginationItem disabled={prevPage <= 0 || prevPage > current_page}>
          <PaginationLink previous name="page" value={prevPage}>
            Prev
          </PaginationLink>
        </PaginationItem>

        {pages.map((page) => {
          return (
            <PaginationItem key={page} active={+current_page === +page}>
              <PaginationLink name="page" value={page}>
                {page}
              </PaginationLink>
            </PaginationItem>
          );
        })}
        <PaginationItem disabled={nextPage >= num_pages}>
          <PaginationLink name="page" value={nextPage} next>
            Next
          </PaginationLink>
        </PaginationItem>

        <PaginationItem disabled={!(current_page < num_pages)}>
          <PaginationLink name="page" value={num_pages} last>
            <FaChevronRight />
          </PaginationLink>
        </PaginationItem>
      </Pagination>

      <FormGroup className={classnames(styles.page_size, styles.formControl)}>
        <Input
          type="select"
          name="page_size"
          value={+page_size}
          onChange={handlePagination}
          className={styles.selectPagination}
        >
          <option value={10}>10 Per Page</option>
          <option value={25}>25 Per Page</option>
        </Input>
      </FormGroup>
    </Row>
  );
};

Paginator.defaultProps = {
  page_size: 10,
};

Paginator.propTypes = {
  page_size: PropTypes.number,
  num_pages: PropTypes.number,
  current_page: PropTypes.number,
  onPagination: PropTypes.func.isRequired,
};

export default Paginator;
