import React from 'react';
import { withLayout } from 'containers/Layout';
import { Creators as OperatorsCreators } from 'services/redux/operators/actions';
import { useDispatch, useSelector } from 'react-redux';
import Mandate from './components';
import { MANDATE_STATUSES_LIST } from '../../constants';

const defaultFilters = {
  page_size: 10,
  page: 1,
  status: 0,
  search: '',
  is_mandated: '1',
};

const Screen = () => {
  const dispatch = useDispatch();
  const [isModalOpen, isModalOpenSet] = React.useState(!true);
  const [selectedOperator, selectedOperatorSet] = React.useState('');
  const [filters, filtersSet] = React.useState(defaultFilters);
  const pagination = useSelector((store) => store.operators.pagination);

  const operators = useSelector((store) => store.operators.list);

  const onFiltersChange = React.useCallback(
    (values) => {
      if ('page' in values && +values.page !== +filters.page) {
        filtersSet({ ...filters, ...values });
      } else {
        filtersSet({ ...defaultFilters, status: filters.status, ...values });
      }
      selectedOperatorSet('');
    },
    [filters, filtersSet, selectedOperatorSet]
  );

  const toggleModal = React.useCallback(() => {
    isModalOpenSet((prev) => !prev);
  }, [isModalOpenSet]);

  React.useEffect(() => {
    const queries = {
      ...filters,
      status: MANDATE_STATUSES_LIST[filters.status],
    };

    if (!queries.status) {
      delete queries.status;
    }

    dispatch(OperatorsCreators.listOperatorsRequest(queries));
  }, [filters, dispatch]);

  return (
    <Mandate
      isModalOpen={isModalOpen}
      isModalOpenSet={isModalOpenSet}
      toggleModal={toggleModal}
      selected={selectedOperator}
      selectedSet={selectedOperatorSet}
      filters={filters}
      onFiltersChange={onFiltersChange}
      pagination={pagination}
      operators={operators}
    />
  );
};

export default withLayout(Screen);
