import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import styles from './actions-modal.module.scss';

const EnableModal = (props) => {
  return (
    <Modal
      centered
      isOpen
      toggle={props.toggleEnableModel}
      className={styles.modal}
    >
      <ModalHeader
        toggle={props.toggleEnableModel}
        className={styles.modalHeader}
      >
        Enable Edit Application
      </ModalHeader>
      <ModalBody className={styles.modalBody}>
        <p>
          You are about to enable application for editing.
          <br />
          The Operator will be able to modify their details again
          <br />
          Please confirm that you wish to re-open this application for editing.
        </p>

        <Button color="primary" onClick={props.enableRediting}>
          Enable
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default (props) => {
  if (!props.enableModal) {
    return null;
  }

  return <EnableModal {...props} />;
};
