import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    getAirportRequest: ['airports'],
    getAirportSuccess: ['airports'],
    getAirportFailure: ['error'],
  },
  {}
);
