import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = {
  isLoading: false,
  list: [],
  isError: false,
};

const LOADING_STATE = {
  isLoading: true,
  isError: false,
};

const SUCCESS_STATE = {
  isLoading: false,
  isError: false,
};

const ERROR_STATE = {
  isLoading: false,
  isError: true,
};

export const listCollectorsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const listCollectorsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    list: action.collectors.data.results,
    ...SUCCESS_STATE,
  };
};

export const listCollectorsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const createCollectorRequest = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const createCollectorSuccess = (state = INITIAL_STATE, action) => {
  action.collector.is_new = false;
  const list = [...state.list, action.collector];

  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const createCollectorFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const getCollectorRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const getCollectorSuccess = (state = INITIAL_STATE, action) => {
  let newState = {
    ...state,
    list: [action.operator],
    ...SUCCESS_STATE,
  };
  return newState;
};

export const getCollectorFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const updateCollectorRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const updateCollectorSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.map((collector) => {
    if (collector.id === action.collector.id) {
      return action.collector;
    }
    return collector;
  });

  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const updateCollectorFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const deleteCollectorRequest = (state = INITIAL_STATE, action) => {
  action.collector.is_new = false;
  const list = state.list.filter(
    (collector) => collector.id !== action.collector.id
  );
  return {
    ...state,
    list,
    ...LOADING_STATE,
  };
};

export const deleteCollectorSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.filter((value) => value.id !== action.collector.id);
  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const deleteCollectorFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const HANDLERS = {
  [Types.LIST_COLLECTORS_REQUEST]: listCollectorsRequest,
  [Types.LIST_COLLECTORS_SUCCESS]: listCollectorsSuccess,
  [Types.LIST_COLLECTORS_FAILURE]: listCollectorsFailure,

  [Types.CREATE_COLLECTOR_REQUEST]: createCollectorRequest,
  [Types.CREATE_COLLECTOR_SUCCESS]: createCollectorSuccess,
  [Types.CREATE_COLLECTOR_FAILURE]: createCollectorFailure,

  [Types.GET_COLLECTOR_REQUEST]: getCollectorRequest,
  [Types.GET_COLLECTOR_SUCCESS]: getCollectorSuccess,
  [Types.GET_COLLECTOR_FAILURE]: getCollectorFailure,

  [Types.UPDATE_COLLECTOR_REQUEST]: updateCollectorRequest,
  [Types.UPDATE_COLLECTOR_SUCCESS]: updateCollectorSuccess,
  [Types.UPDATE_COLLECTOR_FAILURE]: updateCollectorFailure,

  [Types.DELETE_COLLECTOR_REQUEST]: deleteCollectorRequest,
  [Types.DELETE_COLLECTOR_SUCCESS]: deleteCollectorSuccess,
  [Types.DELETE_COLLECTOR_FAILURE]: deleteCollectorFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
