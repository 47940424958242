import React from 'react';
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';
import classnames from 'classnames';
import CsvDownloader from 'react-csv-downloader';
import { FaSearch, FaFileExport } from 'react-icons/fa';
import { ReactComponent as PlanningVerificationIcon } from 'assets/icons/planning-verification.svg';

import styles from './filters-menu.module.scss';

const COLUMNS = [
  {
    id: 'company_name',
    displayName: 'CompanyName',
  },
  {
    id: 'address',
    displayName: 'Location',
  },
  {
    id: 'type_of_operation',
    displayName: 'Profile',
  },
  {
    id: 'verification_type',
    displayName: 'Package',
  },
  {
    id: 'auditor',
    displayName: 'Auditor',
  },
];

const FiltersMenu = (props) => {
  const { onFiltersChange, selected, goToOperatorCompanies, operators } = props;
  const [flattenedOperators, flattenedOperatorsSet] = React.useState([]);

  const onSearch = React.useCallback(
    (e) => {
      onFiltersChange({ search: e.target.value });
    },
    [onFiltersChange]
  );

  React.useEffect(() => {
    let flattenedOperators = [...operators];

    flattenedOperators = flattenedOperators.map((item) => {
      const operator = {
        ...item,
        auditor: `${item?.auditor?.first_name} ${item?.auditor?.last_name}`,
      };
      return operator;
    });
    flattenedOperatorsSet(flattenedOperators);
  }, [operators, flattenedOperatorsSet]);

  return (
    <Row className={styles.container}>
      <Col lg={8}>
        <div
          className={classnames(styles.actionTab, {
            [styles.active]: !!selected,
          })}
          onClick={selected ? goToOperatorCompanies : undefined}
        >
          <PlanningVerificationIcon />
          Plan Verification
        </div>

        <div className={classnames(styles.actionTab, styles.active)}>
          <CsvDownloader
            datas={flattenedOperators}
            filename="operators.csv"
            separator=","
            columns={COLUMNS}
          >
            <FaFileExport /> Export Csv
          </CsvDownloader>
        </div>
      </Col>

      <Col lg={4}>
        <InputGroup
          className={classnames('mb-2 mr-sm-2 mb-sm-0', styles.input)}
        >
          <Input
            type="text"
            name="search"
            value={props.filters?.search}
            placeholder="search"
            onChange={onSearch}
          />
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              {' '}
              <FaSearch />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </Col>
    </Row>
  );
};

export default FiltersMenu;
