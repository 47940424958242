import axios from 'axios';
import { BASE_URL } from './urls';
import localStorage from '../localStorage';
import { history } from 'history.js';

export const getToken = () => {
  //get token from localstorage
  // let token = localStorage.getItem('access_token');
  // return token;
  return (localStorage.getUser() || {}).token;
};

export const refreshToken = () => {};

export const axiosInstance = (token) => {
  let AUTH_TOKEN = getToken() || '';
  if (token) {
    AUTH_TOKEN = token;
  }
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: 'application/json',
      ...(AUTH_TOKEN && {
        // Authorization: AUTH_TOKEN,
        Authorization: 'Token ' + AUTH_TOKEN,
        // Authorization: 'Bearer ' + AUTH_TOKEN,
      }),
    },
  });

  // maybe we can add an interceptor, which checks if token is expired and refreshes it.
  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (
        error.response.status === 401 &&
        window.location.pathname !== '/login'
      ) {
        sessionStorage.setItem(
          'redirectUrl',
          window.location.pathname + window.location.search
        );
        history.push('/login');
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      return Promise.reject(error);
    }
  );

  return instance;
};
