import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    LIST_ORGANIZATIONS_REQUEST
    LIST_ORGANIZATIONS_SUCCESS
    LIST_ORGANIZATIONS_FAILURE

    CREATE_ORGANIZATION_REQUEST
    CREATE_ORGANIZATION_SUCCESS
    CREATE_ORGANIZATION_FAILURE

    GET_ORGANIZATION_REQUEST
    GET_ORGANIZATION_SUCCESS
    GET_ORGANIZATION_FAILURE

    UPDATE_ORGANIZATION_FAILURE
    UPDATE_ORGANIZATION_SUCCESS
    UPDATE_ORGANIZATION_REQUEST
    
    DELETE_ORGANIZATION_FAILURE
    DELETE_ORGANIZATION_SUCCESS
    DELETE_ORGANIZATION_REQUEST


  `,
  {}
);
