import React from 'react';
import { TabsContainer, TabsPane } from 'components/TabsContainer';
import UsersList from './users-list';

import { ROUTES } from 'constants.js';

const Screen = (props) => {
  return (
    <TabsContainer>
      <TabsPane
        title="users.tabs.groups.title"
        subtitle="users.tabs.groups.subtitle"
        to={ROUTES.USER_MANAGEMENT.GROUPS}
      />
      <TabsPane
        title="users.tabs.users.title"
        subtitle="users.tabs.users.subtitle"
        to={ROUTES.USER_MANAGEMENT.USERS}
      >
        <UsersList {...props} />
      </TabsPane>
    </TabsContainer>
  );
};

export default Screen;
