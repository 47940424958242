import { apiRequest } from '../apiRequest';
import { LOGIN_URL, FORGOT_PASSWORD_URL, RESET_PASSWORD_URL } from '../urls';

export const login = (data) => {
  return apiRequest('POST', LOGIN_URL, data);
};

export const forgotPassword = (data) => {
  return apiRequest('POST', FORGOT_PASSWORD_URL, data);
};

export const resetPassword = (data) => {
  return apiRequest('POST', RESET_PASSWORD_URL, data);
};
