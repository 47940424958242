import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    getCountriesRequest: ['countries'],
    getCountriesSuccess: ['countries'],
    getCountriesFailure: ['error'],
  },
  {}
);
