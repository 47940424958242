import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import ApexCheckbox from 'components/ApexCheckbox';
import { formateDate } from 'services/intl/datetimeformat';

import styles from './card.module.scss';

export const Card = (props) => {
  const { selectedSet } = props;
  const onSelectedChange = React.useCallback(
    (e) => {
      const { id, checked: isChecked } = e.target;
      if (isChecked) {
        selectedSet(id);
      } else {
        selectedSet('');
      }
    },
    [selectedSet]
  );

  return (
    <Container
      className={classnames(styles.application, {
        [styles.operator]: props.isOperator,
      })}
      fluid
    >
      <div className={styles.select}>
        <ApexCheckbox
          id={props.value.id}
          checked={props.selected === props.value.id}
          onChange={onSelectedChange}
        />
      </div>
      <div className={styles.avatar} />
      <div className={styles.operator}>
        <header>
          <p>{props.value.company_name}</p>
        </header>
        <Container className={styles.container} fluid>
          <Row>
            <Col lg={3} className={styles.fieldCol}>
              <label>Profile:</label>
              <p>{props.value.company_type || props.value.type_of_operation}</p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Status:</label>
              <p
                className={classnames(
                  styles.status,
                  styles[props.value.verification_status.replace(' ', '')]
                )}
              >
                {props.value.verification_status === 'in progress'
                  ? 'in process'
                  : props.value.verification_status}
              </p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Change of Status Date:</label>
              <p>{formateDate(props.value.verification?.change_status_date)}</p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Outcomes:</label>
              <p>{props.value.verification?.outcomes}%</p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Verification Validity:</label>
              <p>
                {formateDate(props.value.verification?.verification_validity)}
              </p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Next Verification:</label>
              <p>{formateDate(props.value.verification?.next_verification)}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
};

export default Card;
