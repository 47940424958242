import { useContext } from 'react';
import { IntlContext } from 'react-intl';
import { useSelector } from 'react-redux';

import locales from './locales';

export { locales };

export const useLanguage = () => {
  const language = useSelector((state) => state.app.language);
  return [language, locales[language]];
};

export const useIntl = () => useContext(IntlContext);
