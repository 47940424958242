import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = {
  isLoading: false,
  list: [],
  pagination: {},
  isError: false,
};

const LOADING_STATE = {
  isLoading: true,
  isError: false,
};

const SUCCESS_STATE = {
  isLoading: false,
  isError: false,
};

const ERROR_STATE = {
  isLoading: false,
  isError: true,
};

export const listOperatorsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const listOperatorsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    list: action.data.results,
    pagination: action.data.pagination,
    ...SUCCESS_STATE,
  };
};

export const listOperatorsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const createOperatorRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const createOperatorSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...SUCCESS_STATE,
  };
};

export const createOperatorFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const getOperatorRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const getOperatorSuccess = (state = INITIAL_STATE, action) => {
  let newState = {
    ...state,
    list: [action.operator],
    ...SUCCESS_STATE,
  };
  return newState;
};

export const getOperatorFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const updateOperatorRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const updateOperatorSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.filter(
    (operator) =>
      operator.application_status !== action.operator.application_status
  );
  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const updateOperatorFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const mandateOperatorRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const mandateOperatorSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.filter((operator) => operator.id !== action.operator);
  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const mandateOperatorFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const statusOperatorRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const statusOperatorSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.filter((operator) => operator.id !== action.operator);
  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const statusOperatorFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const HANDLERS = {
  [Types.LIST_OPERATORS_REQUEST]: listOperatorsRequest,
  [Types.LIST_OPERATORS_SUCCESS]: listOperatorsSuccess,
  [Types.LIST_OPERATORS_FAILURE]: listOperatorsFailure,
  [Types.CREATE_OPERATOR_REQUEST]: createOperatorRequest,
  [Types.CREATE_OPERATOR_SUCCESS]: createOperatorSuccess,
  [Types.CREATE_OPERATOR_FAILURE]: createOperatorFailure,
  [Types.GET_OPERATOR_REQUEST]: getOperatorRequest,
  [Types.GET_OPERATOR_SUCCESS]: getOperatorSuccess,
  [Types.GET_OPERATOR_FAILURE]: getOperatorFailure,
  [Types.UPDATE_OPERATOR_REQUEST]: updateOperatorRequest,
  [Types.UPDATE_OPERATOR_SUCCESS]: updateOperatorSuccess,
  [Types.UPDATE_OPERATOR_FAILURE]: updateOperatorFailure,
  [Types.MANDATE_OPERATOR_REQUEST]: mandateOperatorRequest,
  [Types.MANDATE_OPERATOR_SUCCESS]: mandateOperatorSuccess,
  [Types.MANDATE_OPERATOR_FAILURE]: mandateOperatorFailure,
  [Types.STATUS_OPERATOR_REQUEST]: statusOperatorRequest,
  [Types.STATUS_OPERATOR_SUCCESS]: statusOperatorSuccess,
  [Types.STATUS_OPERATOR_FAILURE]: statusOperatorFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
