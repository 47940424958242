import React from 'react';
import { Row } from 'reactstrap';
import { useSelector } from 'react-redux';

import Paginator from 'components/Paginator';
import ApplicationCard from './application-card';
import FilterMenu from './filters-menu';
import CommitModal from './commit-modal';
import RejectModal from './reject-modal';
import EnableModal from './enable-edit-modal';
import styles from './application-list.module.scss';

const ApplicationList = (props) => {
  const operators = useSelector((store) => store.operators.list);
  const operatorsColumnName = [
    {
      id: 'company_name',
      displayName: 'CompanyName',
    },
    {
      id: 'address',
      displayName: 'Address',
    },
    {
      id: 'type_of_operation',
      displayName: 'TypeofOperation',
    },
    {
      id: 'application_status',
      displayName: 'Status',
    },
    {
      id: 'airport_name',
      displayName: 'AirportName',
    },
    {
      id: 'country_name',
      displayName: 'CountryName',
    },
    {
      id: 'scope',
      displayName: 'Scope',
    },
    {
      id: 'verification_before',
      displayName: 'VerificationBefore',
    },
  ];
  const { num_pages, current_page } = useSelector(
    (store) => store.operators.pagination
  );

  return (
    <React.Fragment>
      <Row>
        <FilterMenu
          {...props}
          operators={operators}
          columns={operatorsColumnName}
        />
      </Row>
      <Row>
        <CommitModal {...props} />
        <RejectModal {...props} />
        <EnableModal {...props} />
      </Row>

      <Row className={styles.container}>
        {operators.map((operator, idx) => {
          return (
            <ApplicationCard
              selected={props.selectedOperator === operator.id}
              selectedSet={props.selectedOperatorSet}
              key={idx}
              operator={operator}
            />
          );
        })}
      </Row>

      <Row>
        <Paginator
          page_size={+props.filters.page_size}
          num_pages={num_pages}
          current_page={current_page}
          onPagination={props.onFiltersChange}
        />
      </Row>
    </React.Fragment>
  );
};

export default ApplicationList;
