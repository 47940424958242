import React from 'react';
import { withLayout } from 'containers/Layout';
import { useDispatch, useSelector } from 'react-redux';

import CompaniesVerification from './components';
import { Creators as OperatorsCreators } from 'services/redux/operators/actions';
import { Creators as OperatorSites } from 'services/redux/sites/actions';

const defaultFilters = {
  page_size: 10,
  page: 1,
  search: '',
};

const Screen = (props) => {
  const operatorId = props.match.params.id;
  const dispatch = useDispatch();
  const [isEditModal, isEditModalSet] = React.useState(false);
  const [isScheduleModal, isScheduleModalSet] = React.useState(false);
  const [selectedOperator, selectedOperatorSet] = React.useState('');
  const operator = useSelector((store) => store.operators.list[0] || {});
  const operatorsCompanies = useSelector((store) => store.sites.list);
  const pagination = useSelector((store) => store.sites.pagination);
  const [filters, filtersSet] = React.useState(defaultFilters);

  const toggleEditModal = React.useCallback(() => {
    isEditModalSet((prev) => !prev);
  }, [isEditModalSet]);

  const toggleScheduleModal = React.useCallback(() => {
    isScheduleModalSet((prev) => !prev);
  }, [isScheduleModalSet]);

  const onFiltersChange = React.useCallback(
    (values) => {
      if ('page' in values && +values.page !== +filters.page) {
        filtersSet({ ...filters, ...values });
      } else {
        filtersSet({ ...defaultFilters, ...values });
      }
      selectedOperatorSet('');
    },
    [filters, filtersSet, selectedOperatorSet]
  );

  React.useEffect(() => {
    dispatch(OperatorsCreators.getOperatorRequest(operatorId));
  }, [dispatch, operatorId]);

  React.useEffect(() => {
    dispatch(
      OperatorSites.listSitesRequest({
        operator: operatorId,
        ...filters,
      })
    );
  }, [dispatch, filters, operatorId]);

  return (
    <CompaniesVerification
      selected={selectedOperator}
      selectedSet={selectedOperatorSet}
      operator={operator}
      isEditModal={isEditModal}
      isScheduleModal={isScheduleModal}
      toggleEditModal={toggleEditModal}
      toggleScheduleModal={toggleScheduleModal}
      operatorCompanies={operatorsCompanies}
      onFiltersChange={onFiltersChange}
      filters={filters}
      pagination={pagination}
    />
  );
};

export default withLayout(Screen);
