import React from 'react';
import { TabsContainer, TabsPane } from 'components/TabsContainer';
import GroupsList from './groups-list';

import { ROUTES } from 'constants.js';

const Screen = (props) => {
  return (
    <TabsContainer>
      <TabsPane
        title="users.tabs.groups.title"
        subtitle="users.tabs.groups.subtitle"
        to={ROUTES.USER_MANAGEMENT.GROUPS}
      >
        <GroupsList {...props} />
      </TabsPane>

      <TabsPane
        title="users.tabs.users.title"
        subtitle="users.tabs.users.subtitle"
        to={ROUTES.USER_MANAGEMENT.USERS}
      />
    </TabsContainer>
  );
};

export default Screen;
