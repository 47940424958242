import React from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

import styles from './index.module.scss';

const Permission = ({ className, selected, permission = {}, onChange }) => {
  const handleOnChange = React.useCallback(
    (e) => {
      onChange && onChange(e.target.id, e.target.checked);
    },
    [onChange]
  );

  return (
    <Label className={className} check>
      <Input
        id={permission.id}
        type="checkbox"
        checked={selected}
        onChange={handleOnChange}
      />
      {permission.name}
    </Label>
  );
};

const StepPermissions = ({ formik, permissions }) => {
  const handleChange = React.useCallback(
    (id, checked) => {
      const permission = parseInt(id);

      if (checked) {
        const isExists = formik.values.permissions.indexOf(permission) >= 0;
        if (!isExists) {
          formik.setFieldValue('permissions', [
            ...formik.values.permissions,
            permission,
          ]);
        }
      } else {
        const isExists = formik.values.permissions.indexOf(permission) >= 0;
        if (isExists) {
          formik.setFieldValue(
            'permissions',
            formik.values.permissions.filter((i) => i !== permission)
          );
        }
      }
    },
    [formik]
  );

  return (
    <Row>
      <Col>
        <FormGroup row>
          {permissions.map(({ category, permissions }, idx) => {
            return (
              <Col xs={12} lg={4} key={idx}>
                <FormGroup>
                  <Label className={styles.categoryLabel}>{category}</Label>
                  <FormGroup check>
                    {permissions.map((permission, idx) => {
                      return (
                        <Permission
                          key={idx}
                          className={styles.checkboxLabel}
                          permission={permission}
                          selected={
                            formik.values.permissions.indexOf(permission.id) >=
                            0
                          }
                          onChange={handleChange}
                        />
                      );
                    })}
                  </FormGroup>
                </FormGroup>
              </Col>
            );
          })}
        </FormGroup>
      </Col>
    </Row>
  );
};

export default StepPermissions;
