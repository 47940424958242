import { call, put, takeLeading } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { Types as SitesTypes } from '../../redux/sites/actions';

import {
  createSites as CreateSiteOperatorApi,
  getSites as listSitesApi,
  updateSite as updateSiteApi,
  deleteSite as deleteSiteApi,
} from '../../api/operators';
import { toastErrorResponse } from '../errors';
import { Creators as CreatorsSite } from '../../redux/sites/actions';

export function* listSites(action) {
  try {
    const response = yield call(listSitesApi, action.filters);
    yield put(CreatorsSite.listSitesSuccess(response.data));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(CreateSiteOperatorApi.listSitesFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* createSite(action) {
  try {
    const response = yield call(CreateSiteOperatorApi, action.site);
    yield put(CreatorsSite.createSiteSuccess(response.data.data));

    const { company_type } = response.data.data;
    if (company_type === 'collector') {
      toast.success(`Collector created successfully`);
    } else if (company_type === 'organization') {
      toast.success(`Organization created successfully`);
    } else if (company_type === 'site') {
      toast.success(`Site created successfully`);
    }
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(CreatorsSite.createSiteFailure(error_message));
    toastErrorResponse(error);
  }
}

/**
export function* getOperator({ id }) {
  try {
    const response = yield call(getOperatorApi, id);
    yield put(OperatorsCreators.getOperatorSuccess(response.data.data));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(OperatorsCreators.getOperatorFailure(error_message));
    toastErrorResponse(error);
  }
}
**/
export function* updateOperator(data) {
  try {
    const response = yield call(updateSiteApi, data.site);
    yield put(CreatorsSite.updateSiteSuccess(response.data.data));
    toast.success(`Site profile updated successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(CreatorsSite.updateSiteFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* deleteOperator(data) {
  try {
    const response = yield call(deleteSiteApi, data.site);
    yield put(CreatorsSite.deleteSiteSuccess(response));
    toast.success(`Site profile deleted successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(CreatorsSite.deleteSiteFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* watchSites() {
  yield takeLeading(SitesTypes.CREATE_SITE_REQUEST, createSite);
  yield takeLeading(SitesTypes.LIST_SITES_REQUEST, listSites);
  yield takeLeading(SitesTypes.UPDATE_SITE_REQUEST, updateOperator);
  yield takeLeading(SitesTypes.DELETE_SITE_REQUEST, deleteOperator);
}
