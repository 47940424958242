import React from 'react';
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';
import CsvDownloader from 'react-csv-downloader';
import { FaSearch, FaRegCheckCircle, FaFileExport } from 'react-icons/fa';
import classnames from 'classnames';
import { ReactComponent as EditorIcon } from 'assets/icons/editor-icon.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel-icon.svg';
import { history } from 'history.js';
import { APPLICATION_STATUSES } from 'constants.js';
import styles from './filters-menu.module.scss';
import { useAdmin, useCommit } from '../../../services/redux/auth/selectors';

const FiltersMenu = (props) => {
  const {
    selectedOperator,
    onFiltersChange,
    toggleCommitModal,
    toggleRejectModal,
    toggleEnableModel,
    operators,
    columns,
    filters,
  } = props;
  console.log(filters);
  const operator = operators.find((o) => o.id === selectedOperator) || {};

  const onEdit = React.useCallback(() => {
    history.push(`/operators/profile/${selectedOperator}`);
    // history.push(ROUTES.OPERATOR)
  }, [selectedOperator]);

  const onSearch = React.useCallback(
    (e) => {
      onFiltersChange({ search: e.target.value });
    },
    [onFiltersChange]
  );

  const commit = useCommit();
  const admin = useAdmin();

  return (
    <Container className={styles.container} fluid>
      <Row>
        <Col lg={8}>
          {(operator.application_status === APPLICATION_STATUSES.APPLIED ||
            operator.application_status === APPLICATION_STATUSES.REJECTED) &&
            admin && (
              <div
                className={classnames(styles.actionTab, {
                  [styles.active]: !!selectedOperator,
                })}
                onClick={toggleEnableModel}
              >
                <EditorIcon />
                Enable editing
              </div>
            )}

          {operator.application_status === APPLICATION_STATUSES.APPLIED &&
            commit && (
              <div
                className={classnames(styles.actionTab, {
                  [styles.active]: !!selectedOperator,
                })}
                onClick={toggleCommitModal}
              >
                <FaRegCheckCircle />
                Commit
              </div>
            )}

          {operator.application_status === APPLICATION_STATUSES.APPLIED &&
            commit && (
              <div
                className={classnames(styles.actionTab, {
                  [styles.active]: !!selectedOperator,
                })}
                onClick={toggleRejectModal}
              >
                <CancelIcon />
                Reject
              </div>
            )}
          {(filters.status === 1 || filters.status === 0) && (
            <div
              className={classnames(styles.actionTab, {
                [styles.active]: !!selectedOperator,
              })}
              onClick={selectedOperator ? onEdit : undefined}
            >
              <EditorIcon />
              Edit
            </div>
          )}

          <div className={[styles.actionTab, styles.active].join(' ')}>
            <CsvDownloader
              datas={operators}
              filename="operators.csv"
              separator=","
              columns={columns}
            >
              <FaFileExport /> Export Csv
            </CsvDownloader>
          </div>
        </Col>

        <Col lg={4}>
          <InputGroup
            className={classnames('mb-2 mr-sm-2 mb-sm-0', styles.input)}
          >
            <Input
              type="text"
              name="search"
              value={props.filters?.search}
              placeholder="search"
              onChange={onSearch}
            />
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                {' '}
                <FaSearch />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default FiltersMenu;
