import React from 'react';
import { Row, Col, FormGroup, Button } from 'reactstrap';
import Select from 'react-select';

import styles from './index.module.scss';

const GroupsandPermissionsPanel = (props) => {
  const { edit, onSearch, options: list, selected, onAdd, onRemove } = props;
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState({});

  React.useEffect(() => {
    const mapped = list.map(({ id, name }) => ({ label: name, value: id }));
    const filtered = mapped.filter((option) => {
      const isSelected =
        selected.findIndex(
          ({ id, value = '' }) =>
            (value || id).toString() === option.value.toString()
        ) >= 0;
      return !isSelected;
    });
    setOptions(filtered);
  }, [setOptions, list, selected]);

  React.useEffect(() => {
    onSearch && onSearch('');
  }, [onSearch]);

  const onChange = React.useCallback(
    (group, { action }) => {
      if (action === 'select-option') {
        setValue(group);
      }
    },
    [setValue]
  );

  const handleInputChange = React.useCallback(
    (search, { action }) => {
      if (action === 'input-change') {
        onSearch(search);
      }
    },
    [onSearch]
  );

  const onRemoveClick = React.useCallback(
    (e) => {
      onRemove && onRemove(e.target.id);
    },
    [onRemove]
  );

  const onAddClick = React.useCallback(() => {
    if (Object.keys(value).length) {
      onAdd && onAdd(value);
      setValue({});
    }
  }, [onAdd, value, setValue]);

  return (
    <FormGroup className={props.className}>
      <h5>Groups and Permissions</h5>

      <Row className={styles.tagsContainer}>
        {selected.map((group, idx) => {
          const id = group.id || group.value;
          const name = group.name || group.label;
          return (
            <Col key={idx}>
              <div className={styles.groupTag}>
                <p>{name}</p>
                {edit && (
                  <span id={id} onClick={onRemoveClick}>
                    {' '}
                    X{' '}
                  </span>
                )}
              </div>
            </Col>
          );
        })}
      </Row>

      {edit && (
        <FormGroup row>
          <Col sm={12} xl={9}>
            <Select
              value={value}
              onInputChange={handleInputChange}
              onChange={onChange}
              options={options}
              // isClearable
              isFocused={false}
            />
          </Col>
          <Col sm={12} xl={3}>
            <Button
              className={styles.addButton}
              type="button"
              onClick={onAddClick}
            >
              Add User Groups
            </Button>
          </Col>
        </FormGroup>
      )}
    </FormGroup>
  );
};

// TODO: add propTypes
// {
//   options
//   onSearch
//   onChange
//   onAdd
//   onDelete
// }

export default GroupsandPermissionsPanel;
