export const userKey = 'user';
export const languageKey = 'language';

export function getUser() {
  try {
    return JSON.parse(localStorage.getItem(userKey));
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function setUser(user) {
  try {
    return localStorage.setItem(userKey, JSON.stringify(user));
  } catch (e) {
    console.error(e);
    return null;
  }
}

export default {
  getUser,
  setUser,
};
