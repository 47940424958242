import en from './en';
import fr from './fr';

var locales = new Proxy(
  {
    // en-US
    en: en,
    // fr
    fr: fr,
  },
  {
    get(locales, language) {
      if (language.startsWith('en')) {
        return locales['en'];
      } else if (language.startsWith('fr')) {
        return locales['fr'];
      }

      return;
    },
  }
);

export default locales;
