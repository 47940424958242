import { useSelector } from 'react-redux';

export const usePermission = () => {
  // const user = useSelector((store) => store.auth);
  return (permissions) => {
    return true;
  };
};

export const useOperator = () => {
  const user = useSelector((store) => store.auth.user.user);
  if (user.is_superuser || user.is_operator) {
    return true;
  }
  return false;
};

export const useCommit = () => {
  const user = useSelector((store) => store.auth.user.user);

  if (user.is_operator) {
    return false;
  }
  return true;
};

export const useAdmin = () => {
  const user = useSelector((store) => store.auth.user.user);
  return user.is_superuser;
};

export const useMenuPermission = () => {
  const user = useSelector((store) => store.auth.user.user);
  if (user.is_operator) {
    return false;
  }
  return true;
};
