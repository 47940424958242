import { call, put, throttle, select, takeLeading } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  Creators as UsersCreators,
  Types as UsersTypes,
} from '../../redux/users/actions';
import {
  listUsers as listUsersApi,
  getUser as getUserApi,
  deleteUser as deleteUserApi,
  updateUserStatus as updateUserStatusApi,
  createUserProfile as createUserProfileApi,
  updateUserProfile as updateUserProfileApi,
  updateUserPassword as updateUserPasswordApi,
  groupAddUser as groupAddUserApi,
  groupRemoveUser as groupRemoveUserApi,
} from '../../api/users';
import { toastErrorResponse } from '../errors';
import { history } from 'history.js';
import { ROUTES } from 'constants.js';

export function* listUsers(action) {
  try {
    const pagination = yield select(({ users }) => users.pagination);
    const params = { ...action.filters, ...pagination };

    const response = yield call(listUsersApi, params);
    yield put(UsersCreators.listUsersSuccess(response.data));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(UsersCreators.listUsersFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* getUser({ id }) {
  try {
    const response = yield call(getUserApi, id);
    yield put(UsersCreators.getUserSuccess(response.data));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(UsersCreators.getUserFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* deleteUser(action) {
  try {
    yield call(deleteUserApi, action.id);
    yield put(UsersCreators.deleteUserSuccess(action.id));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(UsersCreators.deleteUserFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* updateUserStatus(action) {
  try {
    yield call(updateUserStatusApi, action.user);
    yield put(UsersCreators.updateUserStatusSuccess(action.user));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(UsersCreators.updateUserStatusFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* createUserProfile(action) {
  try {
    yield call(createUserProfileApi, action.user);
    yield put(UsersCreators.createUserProfileSuccess(action.user));
    history.push(ROUTES.USER_MANAGEMENT.USERS);
    toast.success('User created successfully');
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(UsersCreators.createUserProfileFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* updateUserPassword(action) {
  try {
    yield call(updateUserPasswordApi, action.user);
    yield put(UsersCreators.updateUserPasswordSuccess(action.user));
    toast.success('User updated successfully');
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(UsersCreators.updateUserPasswordFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* updateUserProfile(action) {
  try {
    yield call(updateUserProfileApi, action.user);
    yield put(UsersCreators.updateUserProfileSuccess(action.user));
    toast.success('User updated successfully');
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(UsersCreators.updateUserProfileFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* groupAddUser(action) {
  try {
    yield call(groupAddUserApi, action.data);
    yield put(UsersCreators.groupAddUserSuccess(action.data));
    toast.success('Group added successfully');
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(UsersCreators.groupAddUserFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* groupRemoveUser(action) {
  try {
    yield call(groupRemoveUserApi, action.data);
    yield put(UsersCreators.groupRemoveUserSuccess(action.data));
    toast.success('Group removed successfully');
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(UsersCreators.groupRemoveUserFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* watchUsers() {
  yield throttle(1e3, UsersTypes.LIST_USERS_REQUEST, listUsers);
  yield takeLeading(UsersTypes.GET_USER_REQUEST, getUser);
  yield takeLeading(UsersTypes.DELETE_USER_REQUEST, deleteUser);
  yield takeLeading(UsersTypes.UPDATE_USER_STATUS_REQUEST, updateUserStatus);
  yield takeLeading(UsersTypes.CREATE_USER_PROFILE_REQUEST, createUserProfile);
  yield takeLeading(UsersTypes.UPDATE_USER_PROFILE_REQUEST, updateUserProfile);
  yield takeLeading(
    UsersTypes.UPDATE_USER_PASSWORD_REQUEST,
    updateUserPassword
  );
  yield takeLeading(UsersTypes.GROUP_ADD_USER_REQUEST, groupAddUser);
  yield takeLeading(UsersTypes.GROUP_REMOVE_USER_REQUEST, groupRemoveUser);
}
