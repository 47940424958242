import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    LIST_SITES_REQUEST
    LIST_SITES_SUCCESS
    LIST_SITES_FAILURE

    CREATE_SITE_REQUEST
    CREATE_SITE_SUCCESS
    CREATE_SITE_FAILURE

    GET_SITE_REQUEST
    GET_SITE_SUCCESS
    GET_SITE_FAILURE

    UPDATE_SITE_FAILURE
    UPDATE_SITE_SUCCESS
    UPDATE_SITE_REQUEST
    
    DELETE_SITE_FAILURE
    DELETE_SITE_SUCCESS
    DELETE_SITE_REQUEST


  `,
  {}
);
