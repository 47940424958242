import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import ApexCheckbox from 'components/ApexCheckbox';
import { ReactComponent as Celendar4Icon } from 'assets/icons/calendar4.svg';

import styles from './card.module.scss';

const Card = (props) => {
  const { operator, selected, selectedSet } = props;

  const onSelectedChange = React.useCallback(
    (e) => {
      const { id, checked: isChecked } = e.target;
      if (isChecked) {
        selectedSet(id);
      } else {
        selectedSet('');
      }
    },
    [selectedSet]
  );

  const isScheduled = !!operator?.schedules?.id;

  return (
    <Container className={styles.application} fluid>
      <div className={styles.select}>
        <ApexCheckbox
          id={operator.id}
          checked={selected === operator.id}
          onChange={onSelectedChange}
        />
      </div>

      <div className={styles.avatar} />

      <div className={styles.operator}>
        <header>
          <p>{operator.company_name}</p>
        </header>
        <Container className={styles.container} fluid>
          <Row>
            <Col lg={3} className={styles.fieldCol}>
              <label>Status:</label>
              {isScheduled ? (
                <p>
                  <Celendar4Icon /> Scheduled
                </p>
              ) : (
                <p>Not scheduled</p>
              )}
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Profile:</label>
              <p>{operator.type_of_operation}</p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Location:</label>
              <p>{operator.address}</p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Package:</label>
              <p>{operator.verification_type}</p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Auditor in Charge:</label>
              <p>
                {' '}
                {`${operator?.auditor?.first_name} ${operator?.auditor?.last_name}`}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
};

export default Card;
