import React from 'react';
import { Row } from 'reactstrap';
import classnames from 'classnames';

import styles from './index.module.scss';

const Stepper = (props) => {
  return (
    <Row className={styles.stepperRow}>
      <div>
        <div
          className={classnames(styles.circle, {
            [styles.active]: props.step === 0,
          })}
        />
        Group Name
      </div>

      <div />
      <div>
        <div
          className={classnames(styles.circle, {
            [styles.active]: props.step === 1,
          })}
        />
        Permissions
      </div>
    </Row>
  );
};

export default Stepper;
