import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { FaSearch, FaRegCheckCircle } from 'react-icons/fa';
import classnames from 'classnames';

import { ReactComponent as EditorIcon } from 'assets/icons/editor-icon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-icon-outline.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel-icon.svg';

import styles from './filter-menu.module.scss';

const FilterMenu = ({
  state,
  onEdit,
  onDelete,
  onStatus,
  onRightBtnClick,
  rightBtnTitleID = 'btn',
  onFiltersChange,
  selectedRow,
}) => {
  const onChange = React.useCallback(
    (e) => {
      const { name, value } = e.target;
      onFiltersChange && onFiltersChange({ [name]: value, current_page: 1 });
    },
    [onFiltersChange]
  );

  const currentRow = state.list.find(
    ({ id }) => id.toString() === selectedRow.toString()
  );

  return (
    <Row className={styles.container}>
      <Col
        className={classnames(styles.col, styles.filtersCol)}
        xs={{ span: 12, order: 2 }}
        md={{ size: 5, order: 1 }}
        xl={{ size: 6, order: 1 }}
      >
        <span
          className={classnames(styles.actionIcon, { active: selectedRow })}
          onClick={selectedRow && onEdit ? onEdit : undefined}
        >
          <EditorIcon /> Edit
        </span>

        <span
          className={classnames(styles.actionIcon, { active: selectedRow })}
          onClick={selectedRow && onDelete ? onDelete : undefined}
        >
          <DeleteIcon /> Delete
        </span>

        {currentRow && !currentRow.is_active ? (
          <span
            className={classnames(styles.actionIcon, {
              active: selectedRow,
            })}
            onClick={selectedRow && onStatus ? onStatus : undefined}
          >
            <FaRegCheckCircle /> Activate
          </span>
        ) : (
          <span
            className={classnames(styles.actionIcon, {
              active: selectedRow,
            })}
            onClick={selectedRow && onStatus ? onStatus : undefined}
          >
            <CancelIcon /> Deactivate
          </span>
        )}
      </Col>

      <Col
        className={styles.col}
        xs={{ span: 12, order: 1 }}
        md={{ size: 7 }}
        xl={{ size: 6 }}
      >
        <Form inline>
          <Col
            xs={{ size: 4, order: 2 }}
            md={{ size: 3 }}
            className={styles.notpadCol}
          >
            <FormGroup className={classnames('mb-2 mr-sm-2 mb-sm-0')}>
              <Input
                className={styles.select}
                type="select"
                name="status"
                value={state.pagination.status}
                onChange={onChange}
              >
                <option value={''}> Filter by </option>
                <option value={'0'}> Deactivated </option>
                <option value={'1'}> Activated </option>
              </Input>
            </FormGroup>
          </Col>
          <Col
            xs={{ size: 8, order: 3 }}
            md={{ size: 6 }}
            className={styles.notpadCol}
          >
            <InputGroup
              className={classnames('mb-2 mr-sm-2 mb-sm-0', styles.input)}
            >
              <Input
                type="text"
                name="search"
                value={state.pagination.search}
                placeholder="search"
                onChange={onChange}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  {' '}
                  <FaSearch />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>

          <Col
            xs={{ size: 12, order: 1 }}
            md={{ size: 3, order: 3 }}
            className={styles.buttonCol}
          >
            <Button
              type="button"
              className={styles.button}
              onClick={onRightBtnClick}
            >
              <FormattedMessage id={rightBtnTitleID} />
            </Button>
          </Col>
        </Form>
      </Col>
    </Row>
  );
};

FilterMenu.propTypes = {
  // actions
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onStatus: PropTypes.func,
  onRightBtnClick: PropTypes.func,
  // input flags, to disable fields
  selectedRow: PropTypes.string,
  // searchable: PropTypes.bool,
  // filterable: PropTypes.bool,
  // input handler
  onFiltersChange: PropTypes.func.isRequired,
  // rest
  rightBtnTitleID: (props, propName, componentName) => {
    const value = props[propName];
    const isValid = typeof value === 'string' && value.length > 0;
    if (!isValid) {
      return new Error(
        `Invalid prop ${propName} passed to ${componentName}. Expected a valid message id.`
      );
    }
  },
};

export default FilterMenu;
