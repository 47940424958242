import React from 'react';
import { Row, Col, FormGroup, Label, Input, FormFeedback } from 'reactstrap';

const StepInfo = ({ formik }) => {
  return (
    <Row>
      <Col>
        <FormGroup>
          <Label for="name">Group Name</Label>
          <Input
            type="text"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            invalid={!!formik.errors.name}
          />
          <FormFeedback>{formik.errors.name}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for="description">Group Description</Label>
          <Input
            type="textarea"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            invalid={!!formik.errors.description}
          />
          <FormFeedback>{formik.errors.description}</FormFeedback>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default StepInfo;
