import React from 'react';
import {
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { updatedDiff } from 'deep-object-diff';

import { Creators as UsersCreators } from 'services/redux/users/actions';
import FloatedLabelInput from 'components/FloatedLabelInput';

import styles from './index.module.scss';

const validationSchema = Yup.object({
  first_name: Yup.string().required(),
  last_name: Yup.string().required(),
  email: Yup.string().email('Invalid email address').required(),
  msisdn: Yup.string().required('Phone number is required'),
  language: Yup.string().required(),
});

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  msisdn: '',
  language: 'en',
  groups: [],
};

const BasicInfoForm = ({ className, user, isLoading }) => {
  const dispatch = useDispatch();

  const onSubmit = React.useCallback(
    (values) => {
      const updated = updatedDiff(
        {
          ...user,
        },
        values
      );

      dispatch(
        UsersCreators.updateUserProfileRequest({ id: user.id, ...updated })
      );
    },
    [dispatch, user]
  );

  const formik = useFormik({
    onSubmit,
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...user,
    },
    validationSchema,
  });

  const [hasChanged, hasChangedSet] = React.useState(false);

  React.useEffect(() => {
    const updated = updatedDiff(
      {
        ...user,
      },
      formik.values
    );

    hasChangedSet(Object.keys(updated).length > 0);
  }, [formik, user, hasChangedSet]);

  return (
    <Col xs={12} md={6}>
      <Form className={className} onSubmit={formik.handleSubmit}>
        <h5>Basic Information</h5>

        <FloatedLabelInput
          label="users.first.name"
          name="first_name"
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.errors.first_name}
        />

        <FloatedLabelInput
          label="users.last.name"
          name="last_name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.errors.last_name}
        />

        <FloatedLabelInput
          label="users.phone.number"
          name="msisdn"
          value={formik.values.msisdn}
          onChange={formik.handleChange}
          error={formik.errors.msisdn}
        />

        <FloatedLabelInput
          label="users.email.address"
          type="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email}
        />

        <FormGroup>
          <Label>Preferred Language</Label>
          <Input
            type="select"
            name="language"
            value={formik.values.language}
            onChange={formik.handleChange}
            invalid={!!formik.errors.language}
          >
            <option value="en">EN</option>
            <option value="fr">FR</option>
          </Input>
          <FormFeedback>{formik.errors.language}</FormFeedback>
        </FormGroup>

        <FormGroup className={styles.infoSubmit}>
          <Button type="submit" disabled={!hasChanged || isLoading}>
            Save
          </Button>
        </FormGroup>
      </Form>
    </Col>
  );
};

export default BasicInfoForm;
