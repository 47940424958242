import React from 'react';
import { Col } from 'reactstrap';
import { useDispatch } from 'react-redux';

import GroupsandPermissionsPanel from 'components/GroupsandPermissionsPanel';
import { Creators as GroupsCreators } from 'services/redux/groups/actions';
import { Creators as UsersCreators } from 'services/redux/users/actions';

const GroupsForm = ({ className, options, user, edit }) => {
  const dispatch = useDispatch();

  const onAddGroup = React.useCallback(
    ({ label, value }) => {
      dispatch(
        UsersCreators.groupAddUserRequest({
          label,
          group: value,
          user: user.id,
        })
      );
    },
    [dispatch, user.id]
  );

  const onRemoveGroup = React.useCallback(
    (id) => {
      dispatch(
        UsersCreators.groupRemoveUserRequest({ group: id, user: user.id })
      );
    },
    [dispatch, user.id]
  );

  const onSearchGroups = React.useCallback(
    (search) => {
      dispatch(GroupsCreators.listGroupsRequest({ search }));
    },
    [dispatch]
  );

  return (
    <Col xs={12} md={6}>
      <GroupsandPermissionsPanel
        edit={edit}
        onAdd={onAddGroup}
        onRemove={onRemoveGroup}
        selected={user.user_groups || []}
        className={className}
        options={options}
        onSearch={onSearchGroups}
      />
    </Col>
  );
};

export default GroupsForm;
