import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    listSitesRequest: ['filters'],
    listSitesSuccess: ['sites'],
    listSitesFailure: ['error'],

    createSiteRequest: ['site'],
    createSiteSuccess: ['site'],
    createSiteFailure: ['error'],

    getSiteRequest: ['id'],
    getSiteSuccess: ['site'],
    getSiteFailure: ['error'],

    updateSiteRequest: ['site'],
    updateSiteSuccess: ['site'],
    updateSiteFailure: ['error'],

    deleteSiteRequest: ['site'],
    deleteSiteSuccess: ['site'],
    deleteSiteFailure: ['error'],
  },
  {}
);
