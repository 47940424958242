import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
} from 'reactstrap';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';

export const TabsContainer = ({ children }) => {
  const [panes, setPanes] = useState([]);
  const [tabsItem, setTabsItem] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  // TODO: handle routing state
  React.useEffect(() => {
    let tabsItem = [];
    const { pathname } = window.location;

    const newTabs = React.Children.toArray(children).map((elm, key) => {
      const isActive = elm.props.to === pathname;
      const tab = React.cloneElement(elm, { key, tabId: key, isActive });

      tabsItem.push({
        title: tab.props.title,
        id: tab.props.tabId,
        to: tab.props.to,
        isActive: tab.props.active || isActive,
        onClick: tab.props.onClick,
      });

      if (isActive) {
        setActiveTab(key);
      }
      return tab;
    });

    setPanes(newTabs);
    setTabsItem(tabsItem);
  }, [children]);

  return (
    <Container fluid>
      <Nav className={styles.navTabsContainer} tabs>
        {tabsItem.map(({ title, id, to, isActive, onClick }) => {
          return (
            <NavItem key={id}>
              <NavLink
                className={classnames({ active: isActive })}
                tag={to ? Link : undefined}
                to={to}
                id={id}
                onClick={onClick}
              >
                <FormattedMessage id={title} />
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>

      <TabContent className={styles.tabContent} activeTab={activeTab}>
        {panes.map((pane) => {
          return pane.props.isActive ? pane : null;
        })}
      </TabContent>
    </Container>
  );
};

TabsContainer.propTypes = {};

export const TabsPane = (props) => {
  const { tabId, subtitle, children } = props;
  return (
    <TabPane tabId={tabId}>
      {subtitle && (
        <small>
          <FormattedMessage id={subtitle} />
        </small>
      )}

      {children}
    </TabPane>
  );
};

TabsPane.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default {
  TabsContainer,
  TabsPane,
};
