import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  LOGIN_REQUEST
  LOGIN_SUCCESS
  LOGIN_FAILURE

  FORGOT_PASSWORD_REQUEST
  FORGOT_PASSWORD_SUCCESS
  FORGOT_PASSWORD_FAILURE

  RESET_PASSWORD_REQUEST
  RESET_PASSWORD_SUCCESS
  RESET_PASSWORD_FAILURE

  CHANGE_LANGUAGE_REQUEST
  CHANGE_LANGUAGE_SUCCESS
  CHANGE_LANGUAGE_FAILURE

  LOGOUT
`,
  {}
);
