import React, { useState } from 'react';
import { Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import applicationsIcon from 'assets/icons/applications-icon.svg';
import mandateIcon from 'assets/icons/mandate-icon.svg';
import planVerificationIcon from 'assets/icons/plan-verification-icon.svg';
import viewResultsIcon from 'assets/icons/view-results-icon.svg';
import companyListIcon from 'assets/icons/company-list-icon.svg';
import usersGroupsIcon from 'assets/icons/users-groups-icon.svg';
import { ROUTES } from 'constants.js';

import styles from './Sidebar.module.scss';
import { useOperator, useMenuPermission } from 'services/redux/auth/selectors';

const Sidebar = (props) => {
  const [currPath, setcurrPath] = useState('');

  React.useEffect(() => {
    const { pathname } = window.location;
    setcurrPath(pathname);
  }, []);

  const permissions = useMenuPermission();
  const isOperator = useOperator();
  return (
    <section
      className={classnames([
        styles.sidebar,
        { [styles.active]: props.isOpen },
      ])}
    >
      <Navbar color="faded" light expand="md" className={styles.navbar}>
        <Nav vertical navbar>
          <NavItem>
            {isOperator && (
              <NavLink
                className={styles.navLink}
                tag={Link}
                to={ROUTES.APPLICATIONS.BASE}
                active={currPath.startsWith(ROUTES.APPLICATIONS.BASE)}
              >
                <div className={styles.iconContainer}>
                  <img src={applicationsIcon} alt="sidebar icon" />
                </div>
                <p>Applications</p>
              </NavLink>
            )}
            {permissions && (
              <NavLink
                className={styles.navLink}
                tag={Link}
                to={ROUTES.MANDATE.LIST}
                active={currPath.startsWith(ROUTES.MANDATE.BASE)}
              >
                <div className={styles.iconContainer}>
                  <img src={mandateIcon} alt="sidebar icon" />
                </div>
                <p>Mandate</p>
              </NavLink>
            )}
            {permissions && (
              <NavLink
                className={styles.navLink}
                tag={Link}
                to={ROUTES.PLAN_VERFICATION.OPERATORS}
                active={currPath.startsWith(ROUTES.PLAN_VERFICATION.BASE)}
              >
                <div className={styles.iconContainer}>
                  <img src={planVerificationIcon} alt="sidebar icon" />
                </div>
                <p>Plan Verification</p>
              </NavLink>
            )}
            {permissions && (
              <NavLink
                className={styles.navLink}
                tag={Link}
                to={ROUTES.RESULTS.BASE}
                active={currPath.startsWith(ROUTES.RESULTS.BASE)}
              >
                <div className={styles.iconContainer}>
                  <img src={viewResultsIcon} alt="sidebar icon" />
                </div>
                <p>View Results</p>
              </NavLink>
            )}
            {permissions && (
              <NavLink
                className={styles.navLink}
                tag={Link}
                to={ROUTES.COMPANIES.CREATE}
                active={currPath.startsWith(ROUTES.COMPANIES.BASE)}
              >
                <div className={styles.iconContainer}>
                  <img src={companyListIcon} alt="sidebar icon" />
                </div>
                <p>Create Operator</p>
              </NavLink>
            )}
            {permissions && (
              <NavLink
                className={styles.navLink}
                tag={Link}
                to={ROUTES.USER_MANAGEMENT.BASE}
                active={currPath.startsWith(ROUTES.USER_MANAGEMENT.BASE)}
              >
                <div className={styles.iconContainer}>
                  <img src={usersGroupsIcon} alt="sidebar icon" />
                </div>
                <p>Users and Groups</p>
              </NavLink>
            )}
          </NavItem>
        </Nav>
      </Navbar>
    </section>
  );
};

export default Sidebar;
