import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

import styles from './delete-confirmation-modal.module.scss';

const DeleteConfirmationModal = (props) => {
  const { isOpen, setIsDeleteModal, onDelete } = props;

  const toggle = () => setIsDeleteModal(!isOpen);
  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      className={styles.modalContainer}
    >
      <ModalHeader toggle={toggle}>Delete User</ModalHeader>
      <ModalBody className={styles.modalBody}>
        <h5>Warning!</h5>
        <p>
          You are about to delete a user. <br /> This cannot be undone.
        </p>
        <Button color="secondary" onClick={onDelete}>
          Delete User
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default DeleteConfirmationModal;
