import React from 'react';
import { Row } from 'reactstrap';

import { TabsContainer, TabsPane } from 'components/TabsContainer';

import styles from './tabs.module.scss';

const Tabs = ({ tid, onChange }) => {
  const handleTabClick = React.useCallback(
    (e) => {
      const tid = +e.target.id;
      onChange({ status: tid });
    },
    [onChange]
  );

  return (
    <Row className={styles.container}>
      <TabsContainer>
        <TabsPane
          active={0 === tid}
          title="applications.status.all"
          onClick={handleTabClick}
        />

        <TabsPane
          active={1 === tid}
          title="applications.status.requested"
          onClick={handleTabClick}
        />

        <TabsPane
          active={2 === tid}
          title="applications.status.applied"
          onClick={handleTabClick}
        />

        <TabsPane
          active={3 === tid}
          title="applications.status.committed"
          onClick={handleTabClick}
        />

        <TabsPane
          active={4 === tid}
          title="applications.status.rejected"
          onClick={handleTabClick}
        />
      </TabsContainer>
    </Row>
  );
};

export default Tabs;
