import React from 'react';
import { Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import styles from './index.module.scss';

export const LoginCoverImage = () => {
  return (
    <React.Fragment>
      <Col className={`${styles.coverCol} d-none d-xl-block`} xl="8">
        <img
          alt="apex logo cover"
          className={styles.coverImg}
          src={require('assets/imgs/apex-cover.png')}
        />
        <h2 className={styles.coverImgHeader}>
          <FormattedMessage
            id={'users.login.coverimg.header'}
            values={{ break: <br /> }}
          />
        </h2>
      </Col>

      <Col className={`${styles.coverCol} d-none d-md-block d-xl-none`} xl="7">
        <img
          alt="apex logo cover"
          className={styles.coverImg}
          src={require('assets/imgs/tablet-apex-cover.png')}
        />
      </Col>
    </React.Fragment>
  );
};
