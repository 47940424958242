import { connect } from 'react-redux';
import { Creators } from '../../services/redux/auth/actions';
import LoginScreen from './components/login-screen';
import { history } from 'history.js';

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onForgotPassword: () => {
      history.push('/forgot-password');
    },
    onLogin: ({ email, password }) => {
      dispatch(Creators.loginRequest(email, password));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
