import { call, put, takeLeading, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  Creators as VerificationsCreators,
  Types as VerificationsTypes,
} from '../../redux/verifications/actions';
import {
  listVerifications as listVerificationsApi,
  addVerification as addVerificationApi,
  exemptVerification as exemptVerificationApi,
} from '../../api/verifications';
import { toastErrorResponse } from '../errors';

export function* listVerifications(action) {
  try {
    const response = yield call(listVerificationsApi, action.filters);
    yield put(
      VerificationsCreators.listVerificationsSuccess(response.data.data)
    );
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(VerificationsCreators.listVerificationsFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* addVerification(action) {
  try {
    const response = yield call(addVerificationApi, action.data);
    yield put(VerificationsCreators.addVerificationSuccess(response));
    toast.success(`Verification results added successfully`);

    const filters = yield select((store) => store.verifications.filters);
    yield put(VerificationsCreators.listVerificationsRequest(filters));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(VerificationsCreators.addVerificationFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* exemptVerification(action) {
  try {
    const response = yield call(exemptVerificationApi, action.data);
    yield put(VerificationsCreators.exemptVerificationSuccess(response));
    toast.success(`Verification exempted successfully`);

    const filters = yield select((store) => store.verifications.filters);
    yield put(VerificationsCreators.listVerificationsRequest(filters));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(VerificationsCreators.exemptVerificationFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* watchVerifications() {
  yield takeLeading(
    VerificationsTypes.LIST_VERIFICATIONS_REQUEST,
    listVerifications
  );
  yield takeLeading(
    VerificationsTypes.ADD_VERIFICATION_REQUEST,
    addVerification
  );
  yield takeLeading(
    VerificationsTypes.EXEMPT_VERIFICATION_REQUEST,
    exemptVerification
  );
}
