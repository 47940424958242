import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Form, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';

import { LoginCoverImage } from 'components/LoginCoverImage';
import { AppDescText } from 'components/AppDescText';

import styles from './reset-password-success-screen.module.scss';

const ResetPasswordSuccessScreen = ({ onLogin: onSubmit }) => {
  const formik = useFormik({
    onSubmit,
    initialValues: {},
  });

  return (
    <Container fluid>
      <Row>
        <Col
          className={styles.formCol}
          md={{ size: 6, offset: 2 }}
          xl={{ size: 3, offset: 1 }}
        >
          <img
            className={styles.logoImg}
            alt="apex logo"
            src={require('assets/imgs/apex-logo.png')}
          />
          <AppDescText />

          <h2 className={styles.formHeader}>
            <FormattedMessage id={'users.reset.password.success.header'} />
          </h2>

          <Form onSubmit={formik.handleSubmit}>
            <Button type="submit" className={styles.submitButton}>
              <FormattedMessage id={'users.login'} />
            </Button>
          </Form>
        </Col>

        <LoginCoverImage />
      </Row>
    </Container>
  );
};

ResetPasswordSuccessScreen.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default ResetPasswordSuccessScreen;
