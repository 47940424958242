import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  FormText,
  Button,
  Spinner,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { LoginCoverImage } from 'components/LoginCoverImage';
import { AppDescText } from 'components/AppDescText';

import styles from './login-screen.module.scss';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required(),
  password: Yup.string().required(),
});

const LoginScreen = ({ onForgotPassword, onLogin: onSubmit, auth }) => {
  const formik = useFormik({
    onSubmit,
    initialValues: {
      password: '',
      email: '',
    },
    validationSchema,
  });

  const onForgotPasswordClick = React.useCallback(
    (e) => {
      e.preventDefault();
      onForgotPassword();
    },
    [onForgotPassword]
  );

  return (
    <Container fluid>
      <Row>
        <Col
          className={styles.formCol}
          md={{ size: 6, offset: 2 }}
          xl={{ size: 3, offset: 1 }}
        >
          <img
            className={styles.logoImg}
            alt="apex logo"
            src={require('assets/imgs/apex-logo.png')}
          />

          <AppDescText />

          <h2 className={styles.header}>
            <FormattedMessage id={'users.login'} />
          </h2>

          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label className={styles.label} for="email">
                <FormattedMessage id={'users.email'} />
              </Label>
              <Input
                className={styles.input}
                type="email"
                name="email"
                id="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                invalid={!!formik.errors.email}
              />
              <FormFeedback>{formik.errors.email}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label className={styles.label} for="password">
                <FormattedMessage id={'users.password'} />
              </Label>
              <Input
                className={styles.input}
                type="password"
                name="password"
                id="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                invalid={!!formik.errors.password}
              />
              <FormFeedback>{formik.errors.password}</FormFeedback>
            </FormGroup>

            <FormText color="muted">
              <a
                href="/forgot-password"
                className={styles.forgotPassword}
                onClick={onForgotPasswordClick}
              >
                <FormattedMessage id={'users.forgot.password'} />
              </a>
            </FormText>

            {auth.isLoading ? (
              <Spinner className={styles.loginSpinner} />
            ) : (
              <Button type="submit" className={styles.button}>
                <FormattedMessage id={'users.login'} />
              </Button>
            )}
          </Form>
        </Col>

        <LoginCoverImage />
      </Row>
    </Container>
  );
};

LoginScreen.propTypes = {
  onForgotPassword: PropTypes.func,
  onLogin: PropTypes.func,
};

export default LoginScreen;
