import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Form,
  FormText,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Spinner,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import qs from 'qs';
import zxcvbn from 'zxcvbn';
import * as Yup from 'yup';

import { LoginCoverImage } from 'components/LoginCoverImage';
import { AppDescText } from 'components/AppDescText';

import styles from './reset-password-screen.module.scss';

const strength = {
  0: 'Worst',
  1: 'Bad',
  2: 'Weak',
  3: 'Good',
  4: 'Strong',
};

const validationSchema = Yup.object({
  password: Yup.string().min(7).required(),
});

const ResetPasswordScreen = ({ onResetPassword: onSubmit, auth }) => {
  const formik = useFormik({
    onSubmit,
    initialValues: {
      reset_code: qs.parse(window.location.search)['?reset_code'],
      password: '',
      strength: 0,
    },
    validationSchema,
  });

  const { values, setFieldValue } = formik;

  React.useEffect(() => {
    const { score } = zxcvbn(values.password);
    setFieldValue('strength', score);
  }, [setFieldValue, values.password]);

  return (
    <Container fluid>
      <Row>
        <Col
          className={styles.formCol}
          md={{ size: 6, offset: 2 }}
          xl={{ size: 3, offset: 1 }}
        >
          <img
            className={styles.logoImg}
            alt="apex logo"
            src={require('assets/imgs/apex-logo.png')}
          />
          <AppDescText />

          <h2 className={styles.formHeader}>
            <FormattedMessage id={'users.reset.password.header'} />
          </h2>
          <h5 className={styles.formSubheader}>
            <FormattedMessage id={'users.reset.password.subheader'} />
          </h5>

          <Form onSubmit={formik.handleSubmit}>
            <FormGroup>
              <Label className={styles.label} for="password">
                <FormattedMessage id={'users.new.password'} />
              </Label>
              <FormGroup
                className={`${
                  !formik.errors.password && styles.passwordStrengthGroup
                } ${styles[strength[formik.values.strength]]}`}
              >
                <Input
                  type="password"
                  name="password"
                  id="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  invalid={!!formik.errors.password}
                />
                <FormText
                  color="muted"
                  hidden={!formik.values.password || formik.errors.password}
                >
                  <p className={styles.passwordStrength}>
                    {strength[formik.values.strength]}
                  </p>
                </FormText>
                <FormFeedback>{formik.errors.password}</FormFeedback>
              </FormGroup>
            </FormGroup>

            <FormText color="muted">
              <p className={styles.resendPasswordHint}>
                <FormattedMessage id={'users.reset.password.hint'} />
              </p>
            </FormText>

            {auth.isLoading ? (
              <Spinner className={styles.spinner} />
            ) : (
              <Button type="submit" className={styles.submitButton}>
                <FormattedMessage id={'users.reset.password.submit'} />
              </Button>
            )}
          </Form>
        </Col>

        <LoginCoverImage />
      </Row>
    </Container>
  );
};

ResetPasswordScreen.propTypes = {
  onResetPassword: PropTypes.func.isRequired,
};

export default ResetPasswordScreen;
