import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withLayout } from 'containers/Layout';
import UserProfile from './components';
import { Creators as UsersCreators } from 'services/redux/users/actions';

const Screen = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const users = useSelector(({ users }) => users);
  const groups = useSelector(({ groups }) => groups);
  const user = users.list[0] || {};
  const { match } = props;

  React.useEffect(() => {
    dispatch(UsersCreators.getUserRequest(match.params.id));
  }, [dispatch, match.params.id]);

  const toggleUserStatus = React.useCallback(() => {
    dispatch(UsersCreators.updateUserStatusRequest(user));
  }, [dispatch, user]);

  return (
    <UserProfile
      auth={auth}
      users={users}
      user={user}
      groups={groups}
      toggleUserStatus={toggleUserStatus}
    />
  );
};

export default withLayout(Screen);
