import { apiRequest } from '../apiRequest';
import {
  GROUPS_URL,
  GROUPS_ACTIVATE_URL,
  GROUPS_PERMISSIONS_URL,
} from '../urls';

export const listGroups = ({
  limit: page_size,
  current_page: page = 1,
  search,
  status,
}) => {
  const filters = {
    page_size,
    page,
  };

  if (search) {
    filters.search = search;
  }

  if (status === '0' || status === '1') {
    filters.status = status;
  }

  const searchParams = new URLSearchParams(filters).toString();
  return apiRequest('GET', `${GROUPS_URL}?${searchParams}`);
};

export const listGroupsPermissions = () => {
  return apiRequest('GET', GROUPS_PERMISSIONS_URL);
};

export const createGroup = (group) => {
  return apiRequest('POST', GROUPS_URL, group);
};

export const getGroup = (id) => {
  return apiRequest('GET', `${GROUPS_URL}/${id}`);
};

export const deleteGroup = (id) => {
  return apiRequest('DELETE', `${GROUPS_URL}/${id}`);
};

export const updateGroup = ({ id, ...group }) => {
  return apiRequest('PATCH', `${GROUPS_URL}/${id}`, group);
};

export const updateGroupStatus = (group) => {
  return apiRequest('POST', GROUPS_ACTIVATE_URL, {
    group_id: group.id,
    is_active: !group.is_active,
  });
};
