import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { Button as MaterialButton } from '@material-ui/core';
import styles from './sites.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as SitesCreator } from 'services/redux/sites/actions';

import { initialValues as siteInitialValues } from './use-site-form';

import SiteForm from './site-form';
import { APPLICATION_STATUSES, COMPANY_TYPE } from '../../../constants';

const SitesScreen = (props) => {
  const sitesList = useSelector((store) => store.sites.list);
  const dispatch = useDispatch();
  const { operator } = props;
  const [sites, sitesSet] = React.useState([
    { id: 'test', name: 'asd', company_name: 'test' },
  ]);
  const { goBack, goNext } = props;
  const operatorData = useSelector((store) => store.operators.list[0] || {});

  const [hasNewSite, hasNewSiteSet] = React.useState(0);

  const addNewSite = React.useCallback(() => {
    const list = [...sites];
    siteInitialValues.is_new = true;
    siteInitialValues.operator = operator;
    list.push(siteInitialValues);
    sitesSet(list);
    hasNewSiteSet((n) => n++);
  }, [sites, sitesSet, operator, hasNewSiteSet]);

  React.useEffect(() => {
    const queryParam = { company: COMPANY_TYPE.SITES, operator: operator };
    dispatch(SitesCreator.listSitesRequest(queryParam));
  }, [dispatch, operator]);

  React.useEffect(() => {
    sitesSet(sitesList);
  }, [sitesList, sitesSet]);

  return (
    <Container fluid className={styles.sitesContent}>
      <Row>
        <Col xs={12} md={12} lg={12}>
          {operatorData.application_status ===
            APPLICATION_STATUSES.REQUESTED && (
            <MaterialButton
              className={styles.submitAdd}
              variant="contained"
              onClick={addNewSite}
              disabled={hasNewSite}
            >
              {' '}
              Add Sites{' '}
            </MaterialButton>
          )}
        </Col>
      </Row>

      {sites.map((site, idx) => {
        return (
          <Row key={idx}>
            <Col lg={12} md={12} xl={12}>
              <SiteForm
                operator={props.operator}
                countries={props.countries}
                operators={props.operators}
                operatorData={operatorData}
                site={site}
                hasNewSite={hasNewSite}
                hasNewSiteSet={hasNewSiteSet}
              />
            </Col>
          </Row>
        );
      })}
      <Row>
        <Container fluid className={styles.top20}>
          <Button className={styles.submitButtonBack} onClick={goBack}>
            Back
          </Button>
          <Button className={styles.submitButtonNext} onClick={goNext}>
            Next
          </Button>
        </Container>
      </Row>
    </Container>
  );
};

export default SitesScreen;
