import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    GET_AIRPORT_REQUEST
    GET_AIRPORT_SUCCESS
    GET_AIRPORT_FAILURE
  `,
  {}
);
