import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    GET_COUNTRIES_REQUEST
    GET_COUNTRIES_SUCCESS
    GET_COUNTRIES_FAILURE
  `,
  {}
);
