import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = {
  isLoading: false,
  list: [],
  filters: {},
  pagination: {},
  isError: false,
};

const LOADING_STATE = {
  isLoading: true,
  isError: false,
};

const SUCCESS_STATE = {
  isLoading: false,
  isError: false,
};

const ERROR_STATE = {
  isLoading: false,
  isError: true,
};

export const listVerificationsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    filters: { ...action.filters },
    ...LOADING_STATE,
  };
};

export const listVerificationsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    list: action.data.results,
    pagination: action.data.pagination,
    ...SUCCESS_STATE,
  };
};

export const listVerificationsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const addVerificationRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const addVerificationSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...SUCCESS_STATE,
  };
};

export const addVerificationFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const exemptVerificationRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const exemptVerificationSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...SUCCESS_STATE,
  };
};

export const exemptVerificationFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const HANDLERS = {
  [Types.LIST_VERIFICATIONS_REQUEST]: listVerificationsRequest,
  [Types.LIST_VERIFICATIONS_SUCCESS]: listVerificationsSuccess,
  [Types.LIST_VERIFICATIONS_FAILURE]: listVerificationsFailure,

  [Types.ADD_VERIFICATION_REQUEST]: addVerificationRequest,
  [Types.ADD_VERIFICATION_SUCCESS]: addVerificationSuccess,
  [Types.ADD_VERIFICATION_FAILURE]: addVerificationFailure,

  [Types.EXEMPT_VERIFICATION_REQUEST]: exemptVerificationRequest,
  [Types.EXEMPT_VERIFICATION_SUCCESS]: exemptVerificationSuccess,
  [Types.EXEMPT_VERIFICATION_FAILURE]: exemptVerificationFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
