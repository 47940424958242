import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { withLayout } from 'containers/Layout';
import CreateOperator from './components';
import { Creators as OperatorsCreators } from 'services/redux/operators/actions';

const validationSchema = Yup.object({
  first_name: Yup.string().required('First Name is a required field'),
  last_name: Yup.string().required('Last Name is a required field'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is a required field'),
  msisdn: Yup.string().required('Phone number is required'),
  language: Yup.string().required('Language is a required field'),
  company_name: Yup.string().required('Company Name is a required field'),
  address: Yup.string().required('Address is a required field'),
});

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  msisdn: '',
  language: 'en',
  company_name: '',
  type_of_operation: 'production',
  address: '',
};

const Screen = (props) => {
  const dispatch = useDispatch();
  const operators = useSelector(({ operators }) => operators);

  const onSubmit = React.useCallback(
    (values) => {
      dispatch(OperatorsCreators.createOperatorRequest(values));
    },
    [dispatch]
  );

  const formik = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });

  return <CreateOperator formik={formik} operators={operators} {...props} />;
};

export default withLayout(Screen);
