import React from 'react';
import { Container } from 'reactstrap';

import FiltersMenu from './filters-menu';
import CardsList from './cards-list';
import Paginator from './paginator';

import styles from './index.module.scss';

const OperatorsVerification = (props) => {
  return (
    <Container fluid>
      <header className={styles.header}>Plan Verification</header>

      <FiltersMenu {...props} />
      <CardsList {...props} />
      <Paginator {...props} />
    </Container>
  );
};

export default OperatorsVerification;
