import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import ApexCheckbox from 'components/ApexCheckbox';
import { ROUTES } from 'constants.js';

import styles from './card.module.scss';

const Card = (props) => {
  const { operator, selected, selectedSet } = props;
  const operatorURL = ROUTES.OPERATOR.PROFILE.replace(':id', operator.id);

  const onSelectedChange = React.useCallback(
    (e) => {
      const { id, checked: isChecked } = e.target;
      if (isChecked) {
        selectedSet(id);
      } else {
        selectedSet('');
      }
    },
    [selectedSet]
  );

  return (
    <Container className={styles.application} fluid>
      <div className={styles.select}>
        <ApexCheckbox
          id={operator.id}
          checked={selected === operator.id}
          onChange={onSelectedChange}
        />
      </div>

      <div className={styles.avatar} />

      <div className={styles.operator}>
        <header>
          <Link to={operatorURL}> {operator.company_name} </Link>
        </header>
        <Container className={styles.container} fluid>
          <Row>
            <Col lg={3} className={styles.fieldCol}>
              <label>Profile:</label>
              <p>{operator.type_of_operation}</p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Location:</label>
              <p>{operator.address}</p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Client Officer:</label>
              <p>
                {' '}
                {operator.client_officer?.first_name}{' '}
                {operator.client_officer?.last_name}{' '}
              </p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Mandated Hours :</label>
              <p> {operator.mandated_hours} </p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Status:</label>
              <p className={styles[operator.application_status]}>
                {operator.application_status === 'committed' && 'pending'}
                {operator.application_status === 'mandated' && 'mandated'}
              </p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Package:</label>
              <p>{operator.verification_type}</p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Mandated Auditor:</label>
              <p>
                {' '}
                {operator.auditor?.first_name} {operator.auditor?.last_name}{' '}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
};

export default Card;
