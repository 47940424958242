import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    listOrganizationsRequest: ['filters'],
    listOrganizationsSuccess: ['organizations'],
    listOrganizationsFailure: ['error'],

    createOrganizationRequest: ['organization'],
    createOrganizationSuccess: ['organization'],
    createOrganizationFailure: ['error'],

    getOrganizationRequest: ['id'],
    getOrganizationSuccess: ['organization'],
    getOrganizationFailure: ['error'],

    updateOrganizationRequest: ['organization'],
    updateOrganizationSuccess: ['organization'],
    updateOrganizationFailure: ['error'],

    deleteOrganizationRequest: ['organization'],
    deleteOrganizationSuccess: ['organization'],
    deleteOrganizationFailure: ['error'],
  },
  {}
);
