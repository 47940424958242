import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = {
  isLoading: false,
  list: [],
  pagination: {},
  filters: {},
  isError: false,
};

const LOADING_STATE = {
  isLoading: true,
  isError: false,
};

const SUCCESS_STATE = {
  isLoading: false,
  isError: false,
};

const ERROR_STATE = {
  isLoading: false,
  isError: true,
};

export const listSitesRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    filters: action.filters,
    ...LOADING_STATE,
  };
};

export const listSitesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    list: action.sites.data.results,
    pagination: action.sites.data.pagination,
    ...SUCCESS_STATE,
  };
};

export const listSitesFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const createSiteRequest = (state = INITIAL_STATE, action) => {
  console.log(action);
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const createSiteSuccess = (state = INITIAL_STATE, action) => {
  action.site.is_new = false;
  const list = [...state.list, action.site];

  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const createSiteFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const getSiteRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const getSiteSuccess = (state = INITIAL_STATE, action) => {
  let newState = {
    ...state,
    list: [action.operator],
    ...SUCCESS_STATE,
  };
  return newState;
};

export const getSiteFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const updateSiteRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const updateSiteSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.map((site) => {
    if (site.id === action.site.id) {
      return action.site;
    }
    return site;
  });

  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const updateSiteFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const deleteSiteRequest = (state = INITIAL_STATE, action) => {
  action.site.is_new = false;
  const list = state.list.filter((site) => site.id !== action.site.id);
  return {
    ...state,
    list,
    ...LOADING_STATE,
  };
};

export const deleteSiteSuccess = (state = INITIAL_STATE, action) => {
  const list = state.list.filter((value) => value.id !== action.site.id);
  return {
    ...state,
    list,
    ...SUCCESS_STATE,
  };
};

export const deleteSiteFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const HANDLERS = {
  [Types.LIST_SITES_REQUEST]: listSitesRequest,
  [Types.LIST_SITES_SUCCESS]: listSitesSuccess,
  [Types.LIST_SITES_FAILURE]: listSitesFailure,

  [Types.CREATE_SITE_REQUEST]: createSiteRequest,
  [Types.CREATE_SITE_SUCCESS]: createSiteSuccess,
  [Types.CREATE_SITE_FAILURE]: createSiteFailure,

  [Types.GET_SITE_REQUEST]: getSiteRequest,
  [Types.GET_SITE_SUCCESS]: getSiteSuccess,
  [Types.GET_SITE_FAILURE]: getSiteFailure,

  [Types.UPDATE_SITE_REQUEST]: updateSiteRequest,
  [Types.UPDATE_SITE_SUCCESS]: updateSiteSuccess,
  [Types.UPDATE_SITE_FAILURE]: updateSiteFailure,

  [Types.DELETE_SITE_REQUEST]: deleteSiteRequest,
  [Types.DELETE_SITE_SUCCESS]: deleteSiteSuccess,
  [Types.DELETE_SITE_FAILURE]: deleteSiteFailure,
};
export default createReducer(INITIAL_STATE, HANDLERS);
