import { call, put, takeLeading } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { Types as OrganizationTypes } from '../../redux/organizations/actions';

import {
  createOrganization as CreateOrganizationOperatorApi,
  getOrganizations as listOrganzationsApi,
  updateOrganization as updateOrganzationApi,
  deleteOrganization as deleteOrganizationApi,
} from '../../api/operators';
import { toastErrorResponse } from '../errors';
import { Creators as CreatorOrganization } from '../../redux/organizations/actions';

export function* listOrganizations(action) {
  try {
    console.log('success');
    const response = yield call(listOrganzationsApi, action.filters);
    yield put(CreatorOrganization.listOrganizationsSuccess(response.data));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(CreatorOrganization.listOrganizationsFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* createOrganization(action) {
  try {
    const response = yield call(
      CreateOrganizationOperatorApi,
      action.organization
    );
    yield put(
      CreatorOrganization.createOrganizationSuccess(response.data.data)
    );
    toast.success(`Organization created successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(CreatorOrganization.createOrganizationFailure(error_message));
    toastErrorResponse(error);
  }
}

/**
export function* getOperator({ id }) {
  try {
    const response = yield call(getOperatorApi, id);
    yield put(OperatorsCreators.getOperatorSuccess(response.data.data));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(OperatorsCreators.getOperatorFailure(error_message));
    toastErrorResponse(error);
  }
}
**/
export function* updateOrganization(data) {
  try {
    console.log(data);
    const response = yield call(updateOrganzationApi, data.organization);
    yield put(
      CreatorOrganization.updateOrganizationSuccess(response.data.data)
    );
    toast.success(`Organization profile updated successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(CreatorOrganization.updateOrganizationFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* deleteOrganization(data) {
  try {
    const response = yield call(deleteOrganizationApi, data.organization);
    yield put(CreatorOrganization.deleteOrganizationSuccess(response));
    toast.success(`Organizations  profile deleted successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(CreatorOrganization.deleteOrganizationFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* watchOrganizations() {
  yield takeLeading(
    OrganizationTypes.CREATE_ORGANIZATION_REQUEST,
    createOrganization
  );
  yield takeLeading(
    OrganizationTypes.LIST_ORGANIZATIONS_REQUEST,
    listOrganizations
  );
  yield takeLeading(
    OrganizationTypes.UPDATE_ORGANIZATION_REQUEST,
    updateOrganization
  );
  yield takeLeading(
    OrganizationTypes.DELETE_ORGANIZATION_REQUEST,
    deleteOrganization
  );
}
