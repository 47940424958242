import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { withLayout } from 'containers/Layout';
import Groups from './components';
import { Creators as GroupsCreators } from 'services/redux/groups/actions';

const Screen = () => {
  const dispatch = useDispatch();

  const groups = useSelector(({ groups }) => groups);

  const [modalType, modalTypeSet] = React.useState('');

  const [selected, setSelected] = React.useState('');

  const listGroups = React.useCallback(
    (...props) => {
      dispatch(GroupsCreators.listGroupsRequest(...props));
    },
    [dispatch]
  );

  const onSelectedChange = React.useCallback(
    (e) => {
      const { value = '' } = e.target;
      if (value === selected) {
        setSelected('');
      } else {
        setSelected(value);
      }
    },
    [selected]
  );

  const onEdit = React.useCallback(() => {
    modalTypeSet('edit');
  }, [modalTypeSet]);
  const onCreate = React.useCallback(() => {
    setSelected('');
    modalTypeSet('create');
  }, [modalTypeSet]);

  const onUpdateStatus = React.useCallback(() => {
    const group = groups.list.find(({ id }) => selected === id.toString());
    if (group) {
      dispatch(GroupsCreators.updateGroupStatusRequest(group));
    }
  }, [dispatch, selected, groups.list]);

  const onDelete = React.useCallback(() => {}, []);

  return (
    <Groups
      listGroups={listGroups}
      groups={groups}
      selected={selected}
      setSelected={setSelected}
      onSelectedChange={onSelectedChange}
      onEdit={onEdit}
      onCreate={onCreate}
      onUpdateStatus={onUpdateStatus}
      onDelete={onDelete}
      modalType={modalType}
      modalTypeSet={modalTypeSet}
    />
  );
};

export default withLayout(Screen);
