import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    listUsersRequest: [
      'filters', // { "limit", "page", "search", "status" }
    ],
    listUsersSuccess: ['responseData'],
    listUsersFailure: ['error'],

    getUserRequest: ['id'],
    getUserSuccess: ['user'],
    getUserFailure: ['error'],

    deleteUserRequest: ['id'],
    deleteUserSuccess: ['id'],
    deleteUserFailure: ['error'],

    updateUserStatusRequest: ['user'],
    updateUserStatusSuccess: ['user'],
    updateUserStatusFailure: ['error'],

    createUserProfileRequest: ['user'],
    createUserProfileSuccess: ['user'],
    createUserProfileFailure: ['error'],

    updateUserProfileRequest: ['user'],
    updateUserProfileSuccess: ['user'],
    updateUserProfileFailure: ['error'],

    updateUserPasswordRequest: ['user'],
    updateUserPasswordSuccess: ['user'],
    updateUserPasswordFailure: ['error'],

    groupAddUserRequest: ['data'],
    groupAddUserSuccess: ['data'],
    groupAddUserFailure: ['error'],

    groupRemoveUserRequest: ['data'],
    groupRemoveUserSuccess: ['data'],
    groupRemoveUserFailure: ['error'],
  },
  {}
);
