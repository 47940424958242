import React from 'react';
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';
import classnames from 'classnames';
import CsvDownloader from 'react-csv-downloader';
import { FaSearch, FaFileExport } from 'react-icons/fa';
import { ReactComponent as Celendar4Icon } from 'assets/icons/calendar4.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editor-icon.svg';

import styles from './filters-menu.module.scss';

const flattenObject = (ob) => {
  var toReturn = {};
  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == 'object' && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

const COLUMNS = [
  {
    id: 'company_name',
    displayName: 'Company Name',
  },
  {
    id: 'priority',
    displayName: 'Priority',
  },
  {
    id: 'scheduled',
    displayName: 'Scheduled',
  },
  {
    id: 'type_of_operation',
    displayName: 'Type of Operation',
  },
  {
    id: 'request_doc_review_date',
    displayName: 'Document Review Date',
  },
  {
    id: 'effective_doc_review_date',
    displayName: 'Effective Review Date',
  },
  {
    id: 'request_physical_audit_date',
    displayName: 'Physical Audit Requests Date',
  },
  {
    id: 'effective_physical_audit_date',
    displayName: 'Effective Physical Audit Date',
  },
];

const FiltersMenu = (props) => {
  const {
    onFiltersChange,
    selected,
    toggleScheduleModal,
    operator,
    operatorCompanies,
  } = props;

  const siteObject =
    operator?.id === selected
      ? operator
      : operatorCompanies?.find((c) => c.id === selected);
  const isNewSchedules = siteObject && !siteObject?.schedules;
  const isEditSchedules = siteObject && !!siteObject?.schedules;

  const onSearch = React.useCallback(
    (e) => {
      onFiltersChange({ search: e.target.value });
    },
    [onFiltersChange]
  );

  const onFilterScheduled = React.useCallback(
    (e) => {
      onFiltersChange({ scheduled: e.target.value });
    },
    [onFiltersChange]
  );

  const getExportSchedule = () => {
    const operatorObj = flattenObject(operator);

    if (operatorObj.schedules !== null) {
      operatorObj.scheduled = 'YES';
    } else {
      operatorObj.scheduled = 'No';
    }

    const companies = [operatorObj];
    operatorCompanies.map((company) => {
      company.type_of_operation = company.company_type;
      if (company.schedules) {
        company.scheduled = 'YES';
      } else {
        company.scheduled = 'No';
      }
      companies.push(flattenObject(company));
      return company;
    });
    return companies;
  };

  return (
    <Row className={styles.container}>
      <Col lg={7}>
        <div
          className={classnames(styles.actionTab, {
            [styles.active]: isNewSchedules,
          })}
          onClick={isNewSchedules ? toggleScheduleModal : undefined}
        >
          <Celendar4Icon />
          Schedule Company Verification
        </div>

        <div
          className={classnames(styles.actionTab, {
            [styles.active]: isEditSchedules,
          })}
          onClick={isEditSchedules ? toggleScheduleModal : undefined}
        >
          <EditIcon />
          Edit Verification Schedule
        </div>

        <div className={classnames(styles.actionTab, styles.active)}>
          <CsvDownloader
            datas={getExportSchedule()}
            filename="schedules.csv"
            separator=","
            columns={COLUMNS}
          >
            <FaFileExport /> Export Csv
          </CsvDownloader>
        </div>
      </Col>

      <Col lg={2}>
        <Input
          type="select"
          name="status"
          value={props.filters.scheduled}
          placeholder="search"
          onChange={onFilterScheduled}
        >
          <option value="">Filter By</option>
          <option value="1">Scheduled</option>
          <option value="0">Not Scheduled</option>
        </Input>
      </Col>

      <Col lg={3}>
        <InputGroup
          className={classnames('mb-2 mr-sm-2 mb-sm-0', styles.input)}
        >
          <Input
            type="text"
            name="search"
            value={props.filters?.search}
            placeholder="search"
            onChange={onSearch}
          />

          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              {' '}
              <FaSearch />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </Col>
    </Row>
  );
};

export default FiltersMenu;
