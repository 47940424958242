import React from 'react';
import classnames from 'classnames';

import styles from './index.module.scss';

const ApexCheckbox = ({ className, ...props }) => {
  return (
    <input
      type="checkbox"
      className={classnames(styles.checkbox, className)}
      {...props}
    />
  );
};

export default ApexCheckbox;
