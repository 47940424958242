import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = {
  isLoading: false,
  list: [],
  isError: false,
};

const LOADING_STATE = {
  isLoading: true,
  isError: false,
};

const SUCCESS_STATE = {
  isLoading: false,
  isError: false,
};

const ERROR_STATE = {
  isLoading: false,
  isError: true,
};

export const getAirportRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const getAirportSuccess = (state = INITIAL_STATE, action) => {
  let newState = {
    ...state,
    list: action.airports,
    ...SUCCESS_STATE,
  };
  return newState;
};

export const getAiportFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const HANDLERS = {
  [Types.GET_AIRPORT_REQUEST]: getAirportRequest,
  [Types.GET_AIRPORT_SUCCESS]: getAirportSuccess,
  [Types.GET_AIRPORT_FAILURE]: getAiportFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
