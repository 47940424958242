import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import classnames from 'classnames';
import { useIntl } from 'react-intl';

import styles from './index.module.scss';

const FloatedLabelInput = ({ className = '', label: id, error, ...props }) => {
  const { formatMessage } = useIntl();
  const label = formatMessage({ id });

  return (
    <FormGroup className={classnames(styles.floatLabel, className)}>
      <Input placeholder={label} invalid={!!error} {...props} />
      <Label>{label}</Label>
      <FormFeedback>{error}</FormFeedback>
    </FormGroup>
  );
};

FloatedLabelInput.defaultProps = {
  className: '',
};

FloatedLabelInput.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FloatedLabelInput;
