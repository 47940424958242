import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = {
  isLoading: false,
  list: [],
  isError: false,
};

const LOADING_STATE = {
  isLoading: true,
  isError: false,
};

const SUCCESS_STATE = {
  isLoading: false,
  isError: false,
};

const ERROR_STATE = {
  isLoading: false,
  isError: true,
};

export const getCountryRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...LOADING_STATE,
  };
};

export const getCountrySuccess = (state = INITIAL_STATE, action) => {
  let newState = {
    ...state,
    list: action.countries,
    ...SUCCESS_STATE,
  };
  return newState;
};

export const getCountryFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...ERROR_STATE,
    error_message: action.error,
  };
};

export const HANDLERS = {
  [Types.GET_COUNTRIES_REQUEST]: getCountryRequest,
  [Types.GET_COUNTRIES_SUCCESS]: getCountrySuccess,
  [Types.GET_COUNTRIES_FAILURE]: getCountryFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
