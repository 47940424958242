import { call, put, takeLeading } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  Creators as OperatorsCreators,
  Types as OperatorsTypes,
} from '../../redux/operators/actions';
import {
  listOperators as listOperatorsApi,
  createOperator as createOperatorApi,
  mandateOperator as mandateOperatorApi,
  statusOperator as statusOperatorApi,
} from '../../api/operators';
import { toastErrorResponse } from '../errors';
import {
  getOperator as getOperatorApi,
  updateOperator as updateOperatorApi,
  commitOrRejectOperator as commitOrRejectOperatorApi,
} from '../../api/operators';

export function* listOperators(action) {
  try {
    const response = yield call(listOperatorsApi, action.filters);
    yield put(OperatorsCreators.listOperatorsSuccess(response.data.data));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(OperatorsCreators.listOperatorsFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* createOperator({ operator }) {
  try {
    yield call(createOperatorApi, operator);
    yield put(OperatorsCreators.createOperatorSuccess(operator));
    toast.success(`Operator profile created successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(OperatorsCreators.createOperatorFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* getOperator({ id }) {
  try {
    const response = yield call(getOperatorApi, id);
    yield put(OperatorsCreators.getOperatorSuccess(response.data.data));
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(OperatorsCreators.getOperatorFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* updateOperator(data) {
  try {
    const response = yield call(updateOperatorApi, data.operator);
    yield put(OperatorsCreators.updateOperatorSuccess(response.data));
    toast.success(`Operator profile updated successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(OperatorsCreators.updateOperatorFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* commitOrRejectOperator(action) {
  try {
    const response = yield call(commitOrRejectOperatorApi, action.data);
    yield put(OperatorsCreators.updateOperatorSuccess(response.data));
    toast.success(`Operator profile updated successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(OperatorsCreators.updateOperatorFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* mandateOperator(data) {
  try {
    yield call(mandateOperatorApi, data.operator);
    yield put(OperatorsCreators.mandateOperatorSuccess(data.operator.operator));
    toast.success(`Operator mandate updated successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(OperatorsCreators.mandateOperatorFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* statusOperator(data) {
  try {
    yield call(statusOperatorApi, data.operator);
    yield put(OperatorsCreators.statusOperatorSuccess(data.operator.operator));
    toast.success(`Operator status updated successfully`);
  } catch (error) {
    const error_message = error?.response?.data?.data?.non_field_errors;
    yield put(OperatorsCreators.statusOperatorFailure(error_message));
    toastErrorResponse(error);
  }
}

export function* watchOperators() {
  yield takeLeading(OperatorsTypes.LIST_OPERATORS_REQUEST, listOperators);
  yield takeLeading(OperatorsTypes.CREATE_OPERATOR_REQUEST, createOperator);
  yield takeLeading(
    OperatorsTypes.COMMIT_OR_REJECT_OPERATOR_REQUEST,
    commitOrRejectOperator
  );
  yield takeLeading(OperatorsTypes.GET_OPERATOR_REQUEST, getOperator);
  yield takeLeading(OperatorsTypes.UPDATE_OPERATOR_REQUEST, updateOperator);
  yield takeLeading(OperatorsTypes.MANDATE_OPERATOR_REQUEST, mandateOperator);
  yield takeLeading(OperatorsTypes.STATUS_OPERATOR_REQUEST, statusOperator);
}
