import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Form,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import Stepper from './stepper';
import StepInfo from './step-info';
import StepPermissions from './step-permissions';
import { Creators as GroupsCreators } from 'services/redux/groups/actions';

import styles from './index.module.scss';

const validationSchema = Yup.object({
  name: Yup.string().required('Group Name is a required field'),
  description: Yup.string().required('Group Description is a required field'),
});

const initialValues = {
  name: '',
  description: '',
  permissions: [],
};

const normalizeGroup = (group) => {
  return {
    id: group.id,
    permissions: (group.user_permissions || []).map((g) => g.id),
    name: group.name || '',
    description: group.description || '',
  };
};

const GroupModalForm = (props) => {
  const { gid, type, onClose } = props;
  const dispatch = useDispatch();
  const { permissions, selected: group } = useSelector((store) => store.groups);

  const [step, stepSet] = React.useState(0);
  const formik = useFormik({
    validationSchema,
    initialValues,
  });

  const { setValues } = formik;
  React.useEffect(() => {
    if (type === 'edit') {
      setValues(normalizeGroup(group));
    }
  }, [type, group, setValues]);

  const onNext = React.useCallback(async () => {
    const errors = await formik.validateForm();
    if (!errors.name) {
      stepSet(step + 1);
    }
  }, [step, stepSet, formik]);

  const onSubmit = React.useCallback(() => {
    if (type === 'create') {
      dispatch(GroupsCreators.createGroupRequest(formik.values));
    } else if (type === 'edit') {
      dispatch(
        GroupsCreators.updateGroupRequest({
          id: group.id,
          name: formik.values.name,
          description: formik.values.description,
          permissions: formik.values.permissions,
        })
      );
    }
    onClose();
  }, [dispatch, formik, onClose, type, group]);

  React.useEffect(() => {
    if (type === 'edit') {
      dispatch(GroupsCreators.getGroupRequest(gid));
    }
  }, [dispatch, type, gid]);

  React.useEffect(() => {
    dispatch(GroupsCreators.listGroupsPermissionsRequest());
  }, [dispatch]);

  return (
    <Modal isOpen toggle={onClose} className={styles.modal} size="xl" centered>
      <ModalHeader className={styles.modalHeader}>
        <FormattedMessage id={`groups.${type}`} />
      </ModalHeader>

      <ModalBody>
        <Container>
          <Stepper step={step} />

          <Form>
            {step === 0 && <StepInfo formik={formik} />}
            {step === 1 && (
              <StepPermissions permissions={permissions} formik={formik} />
            )}
          </Form>
        </Container>
      </ModalBody>

      <ModalFooter className={styles.modalFooter}>
        {step === 0 && (
          <Button color="primary" onClick={onNext}>
            <FormattedMessage id="next" />
          </Button>
        )}

        {step === 1 && (
          <Button color="primary" onClick={onSubmit}>
            <FormattedMessage id={`groups.${type}`} />
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default (props) => {
  if (props.type === 'create' || (props.type === 'edit' && props.gid)) {
    return <GroupModalForm {...props} />;
  }

  return null;
};
