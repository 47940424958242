import React from 'react';
import { Row } from 'reactstrap';

import Paginator from 'components/Paginator';

export default (props) => {
  return (
    <Row>
      <Paginator
        page_size={+props.filters.page_size}
        num_pages={props.pagination.num_pages}
        current_page={props.pagination.current_page}
        onPagination={props.onFiltersChange}
      />
    </Row>
  );
};
