// global app state for things like internationalization, etc...
import { createReducer } from 'reduxsauce';

import { locales } from 'lang';
import Types from './actionTypes';

const appDefaultLanguage = 'en';
const userCachedPreference = localStorage.getItem('bhg-language');
const userLanguage = '';
// const userLanguage = navigator.language;

export const INITIAL_STATE = {
  language: locales[userCachedPreference || userLanguage]
    ? userCachedPreference || userLanguage
    : appDefaultLanguage,
};

export const languageChange = (state = INITIAL_STATE, { language }) => {
  if (locales[language]) {
    return { ...state, language };
  }

  return state;
};

export const HANDLERS = {
  [Types.LANGUAGE_CHANGE]: languageChange,
};

export default createReducer(INITIAL_STATE, HANDLERS);
