import { connect } from 'react-redux';
import { Creators } from '../../services/redux/auth/actions';
import ForgotPasswordScreen from './components/forgot-password-screen';
// import { history } from 'history.js';

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onForgotPassword: ({ email }) => {
      dispatch(Creators.forgotPasswordRequest(email));
      // history.push('/forgot-password');
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordScreen);
