import React from 'react';
import { Container } from 'reactstrap';

import Tabs from './tabs';
import FiltersMenu from './filters-menu';
import Paginator from './paginator';
import ExemptModal from './exempt-modal';
import AddResultsModal from './add-results-modal';
import CardsList from './cards-list';

export const ViewResults = (props) => {
  return (
    <Container fluid>
      <ExemptModal {...props} />
      <AddResultsModal {...props} />

      <Tabs
        tid={props.filters?.verification}
        onChange={props.onFiltersChange}
      />
      <FiltersMenu {...props} />
      <CardsList {...props} />
      <Paginator {...props} />
    </Container>
  );
};

export default ViewResults;
