import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

import { Creators as VerificationsCreators } from 'services/redux/verifications/actions';
import { htmlFormatDate, getTomorrow } from 'services/intl/datetimeformat';

import styles from './add-results-modal.module.scss';

const validationSchema = Yup.object({
  outcomes: Yup.number()
    .min(0, 'Outcomes must be within range 0-100.')
    .max(100, 'Outcomes must be within range 0-100.')
    .required('Outcomes is required field.'),
  verification_validity: Yup.date()
    .min(
      htmlFormatDate(getTomorrow()),
      'Verification Validity must be future date.'
    )
    .required('Verification Validity Date is required field.'),
  next_verification: Yup.date()
    .min(
      htmlFormatDate(getTomorrow()),
      'Next Verification must be future date.'
    )
    .required('Next Verification Date is required field.'),
});

const initialValues = {
  outcomes: 0,
  verification_validity: '',
  next_verification: '',
  status: 'finalized',
  operator: '',
};

export const AddResultsModal = (props) => {
  const { toggleAddModal } = props;
  const dispatch = useDispatch();

  const onSubmit = React.useCallback(
    (values) => {
      dispatch(VerificationsCreators.addVerificationRequest(values));
      toggleAddModal();
    },
    [dispatch, toggleAddModal]
  );

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      ...initialValues,
      operator: props.verification?.id,
      id: props.verification?.verification?.id,
    },
    onSubmit,
  });

  return (
    <Modal
      centered
      isOpen
      toggle={toggleAddModal}
      className={styles.modal}
      size="lg"
    >
      <Form onSubmit={formik.handleSubmit}>
        <ModalHeader toggle={toggleAddModal} className={styles.modalHeader}>
          Add Results
        </ModalHeader>

        <ModalBody className={styles.modalBody}>
          <Row>
            <Col>
              <FormGroup>
                <Label>Company name</Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label>{props.verification?.company_name}</Label>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label>Outcomes</Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Input
                  type="number"
                  min={0}
                  max={100}
                  name="outcomes"
                  value={formik.values.outcomes}
                  onChange={formik.handleChange}
                  invalid={!!formik.errors.outcomes}
                />
                <FormFeedback>{formik.errors.outcomes}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label>Verification Validity Date</Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Input
                  type="date"
                  name="verification_validity"
                  min={htmlFormatDate(new Date())}
                  value={formik.values.verification_validity}
                  onChange={formik.handleChange}
                  invalid={!!formik.errors.verification_validity}
                />
                <FormFeedback>
                  {formik.errors.verification_validity}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label>Next Verification Date</Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Input
                  type="date"
                  name="next_verification"
                  min={htmlFormatDate(new Date())}
                  value={formik.values.next_verification}
                  onChange={formik.handleChange}
                  invalid={!!formik.errors.next_verification}
                />
                <FormFeedback>{formik.errors.next_verification}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter className={styles.modalFooter}>
          <Button type="submit" color="primary">
            Add
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default (props) => {
  if (!props.isAddOpen) {
    return null;
  }

  return <AddResultsModal {...props} />;
};
