import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import ApexCheckbox from 'components/ApexCheckbox';
import { ReactComponent as Celendar4Icon } from 'assets/icons/calendar4.svg';

import styles from './card.module.scss';

const Card = (props) => {
  const { operator, selected, selectedSet } = props;
  const onSelectedChange = React.useCallback(
    (e) => {
      const { id, checked: isChecked } = e.target;
      if (isChecked) {
        selectedSet(id);
      } else {
        selectedSet('');
      }
    },
    [selectedSet]
  );

  const isScheduled = (operator) => {
    console.log(operator);
    console.log(operator);

    if (operator.schedules === null) {
      return false;
    } else if (operator.schedules === {}) {
      return false;
    } else if (!operator.schedules?.hasOwnProperty('id')) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Container
      className={classnames(styles.application, {
        [styles.operator]: props.isOperator,
      })}
      fluid
    >
      <div className={styles.select}>
        <ApexCheckbox
          id={operator.id}
          checked={selected === operator.id}
          onChange={onSelectedChange}
        />
      </div>
      <div className={styles.avatar} />
      <div className={styles.operator}>
        <header>
          <p>{operator.company_name}</p>
        </header>
        <Container className={styles.container} fluid>
          <Row>
            <Col lg={3} className={styles.fieldCol}>
              <label>Status:</label>
              {isScheduled(operator) && (
                <p>
                  <Celendar4Icon /> Scheduled
                </p>
              )}

              {!isScheduled(operator) && <p>Not scheduled</p>}
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Profile:</label>
              <p>{operator.company_type || operator.type_of_operation}</p>
            </Col>

            {isScheduled(operator) && (
              <Col lg={3} className={styles.fieldCol}>
                <label>Priority:</label>
                <p
                  className={classnames(styles.priority, {
                    [styles[operator.schedules?.priority]]: !!operator.schedules
                      ?.priority,
                  })}
                >
                  {operator.schedules.priority}
                </p>
              </Col>
            )}

            {isScheduled(operator) && (
              <Col lg={3} className={styles.fieldCol}>
                <label>Document Review Request:</label>
                <p>{operator.schedules.request_doc_review_date}</p>
              </Col>
            )}

            {operator.schedules?.effective_doc_review_date && (
              <Col lg={3} className={styles.fieldCol}>
                <label>Document Effective Review Date:</label>
                <p>{operator.schedules.effective_doc_review_date}</p>
              </Col>
            )}

            {isScheduled(operator) && (
              <Col lg={3} className={styles.fieldCol}>
                <label>Physical Audit Request:</label>
                <p>{operator.schedules.request_physical_audit_date}</p>
              </Col>
            )}

            {operator.schedules?.effective_physical_audit_date && (
              <Col lg={3} className={styles.fieldCol}>
                <label>Effective Physical Audit:</label>
                <p>{operator.schedules.effective_physical_audit_date}</p>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </Container>
  );
};

export default Card;
