import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import classnames from 'classnames';

import BasicInfoForm from './info-form';
import GroupsForm from './groups-form';
import PasswordForm from './passowrd-form';

import styles from './index.module.scss';

const UserProfile = (props) => {
  const { auth, user, groups, toggleUserStatus } = props;
  const isMyProfile = auth.user.user.id === user.id;
  const isSuperUser = auth.user.user.is_superuser;

  return (
    <Container className={styles.container} fluid>
      <header className={styles.header}>User Profile</header>
      <Row>
        <Col className={styles.imageAvatar}>
          <img
            src={require('assets/imgs/user-avatar-394.png')}
            alt="user-avatar-394.png"
          />
        </Col>
        {isSuperUser && !isMyProfile && (
          <Col className={styles.statusToggle} lg={2}>
            <Button onClick={toggleUserStatus}>
              {!user.is_active ? 'Activate User' : 'Deactivate User'}
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <BasicInfoForm user={user} className={styles.formGroupPane} />

        {!user.is_operator && (
          <GroupsForm
            edit={isSuperUser}
            user={user}
            className={classnames(styles.formGroupPane, styles.groupsPane)}
            options={groups.list}
          />
        )}

        {isMyProfile && (
          <PasswordForm
            user={user}
            className={classnames(styles.formGroupPane)}
          />
        )}
      </Row>
    </Container>
  );
};

export default UserProfile;
