export const formateDate = (value) => {
  try {
    if (!value) {
      return '';
    }

    const date = value instanceof Date ? value : new Date(value);

    return new Intl.DateTimeFormat('en-US', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    }).format(date);
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const htmlFormatDate = (value) => {
  try {
    if (!value) {
      return '';
    }

    const date = value instanceof Date ? value : new Date(value);

    const [
      { value: month },
      ,
      { value: day },
      ,
      { value: year },
    ] = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).formatToParts(date);

    return `${year}-${month}-${day}`;
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const getTomorrow = () => {
  const dt = new Date();
  dt.setDate(dt.getDate() + 1);
  return dt;
};
