import { createReducer } from 'reduxsauce';
import Types from './actionTypes';
import localStorage from '../../localStorage';

export const INITIAL_STATE = {
  language: 'EN',
  isLoading: false,
  isError: false,
  user: localStorage.getUser(),
  forgotPassword: null,
};

export const logout = () => {
  localStorage.setUser({});
  return { ...INITIAL_STATE };
};

export const login = (state = INITIAL_STATE, action) => {
  return { ...state, isLoading: true, isError: false };
};

export const loginSuccess = (state = INITIAL_STATE, action) => {
  const userData = action.responseData.data;
  localStorage.setUser(userData);
  return {
    ...state,
    isLoading: false,
    isError: false,
    user: userData,
  };
};

export const loginFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: true,
    error_message: action.error,
  };
};

export const forgotPassword = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: true,
    isError: false,
  };
};

export const forgotPasswordSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: false,
    forgotPassword: action.responseData,
  };
};

export const forgotPasswordFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: true,
    error_message: action.error,
  };
};

export const resetPassword = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: true,
    isError: false,
  };
};

export const resetPasswordSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: false,
    resetPassword: action.responseData,
  };
};

export const resetPasswordFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: true,
    error_message: action.error,
  };
};

export const changeLanguageRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: true,
    isError: false,
    language: action.lang,
  };
};

export const changeLanguageSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: false,
  };
};

export const changeLanguageFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    isError: true,
    error_message: action.error,
  };
};

export const HANDLERS = {
  [Types.LOGOUT]: logout,

  [Types.LOGIN_REQUEST]: login,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,

  [Types.FORGOT_PASSWORD_REQUEST]: forgotPassword,
  [Types.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [Types.FORGOT_PASSWORD_FAILURE]: forgotPasswordFailure,

  [Types.RESET_PASSWORD_REQUEST]: resetPassword,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,

  [Types.CHANGE_LANGUAGE_REQUEST]: changeLanguageRequest,
  [Types.CHANGE_LANGUAGE_SUCCESS]: changeLanguageSuccess,
  [Types.CHANGE_LANGUAGE_FAILURE]: changeLanguageFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
