import { combineReducers } from 'redux';

import auth from 'services/redux/auth/reducers';
import app from 'services/redux/app/reducers';
import users from 'services/redux/users/reducers';
import groups from 'services/redux/groups/reducers';
import operators from 'services/redux/operators/reducers';
import countries from 'services/redux/countries/reducers';
import airports from 'services/redux/airports/reducers';
import sites from 'services/redux/sites/reducers';
import collectors from 'services/redux/collectors/reducers';
import organizations from 'services/redux/organizations/reducers';
import schedule from 'services/redux/schedule/reducers';
import verifications from 'services/redux/verifications/reducers';

const rootReducer = combineReducers({
  app,
  auth,
  users,
  groups,
  operators,
  countries,
  airports,
  sites,
  collectors,
  organizations,
  schedule,
  verifications,
});

export default rootReducer;
