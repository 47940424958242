import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    LIST_OPERATORS_REQUEST
    LIST_OPERATORS_SUCCESS
    LIST_OPERATORS_FAILURE

    CREATE_OPERATOR_REQUEST
    CREATE_OPERATOR_SUCCESS
    CREATE_OPERATOR_FAILURE

    COMMIT_OR_REJECT_OPERATOR_REQUEST
    COMMIT_OR_REJECT_OPERATOR_SUCCESS
    COMMIT_OR_REJECT_OPERATOR_FAILURE

    GET_OPERATOR_REQUEST
    GET_OPERATOR_SUCCESS
    GET_OPERATOR_FAILURE

    UPDATE_OPERATOR_FAILURE
    UPDATE_OPERATOR_SUCCESS
    UPDATE_OPERATOR_REQUEST

    MANDATE_OPERATOR_FAILURE
    MANDATE_OPERATOR_SUCCESS
    MANDATE_OPERATOR_REQUEST
    
    STATUS_OPERATOR_REQUEST
    STATUS_OPERATOR_SUCCESS
    STATUS_OPERATOR_FAILURE
  `,
  {}
);
