import React from 'react';
import { Row, Container, Col } from 'reactstrap';

import ApexCheckbox from 'components/ApexCheckbox';

import styles from './application-card.module.scss';
import { Link } from 'react-router-dom';

const ApplicationCard = ({ selected, selectedSet, operator }) => {
  const operatorUrl = '/operators/profile/';
  const onSelectedChange = React.useCallback(
    (e) => {
      const { id, checked: isChecked } = e.target;
      if (isChecked) {
        selectedSet(id);
      } else {
        selectedSet('');
      }
    },
    [selectedSet]
  );

  return (
    <Container className={styles.application} fluid>
      <div className={styles.select}>
        <ApexCheckbox
          id={operator.id}
          checked={selected}
          onChange={onSelectedChange}
        />
      </div>

      <div className={styles.avatar} />

      <div className={styles.operator}>
        <header>
          <Link to={operatorUrl + operator.id}>{operator.company_name} </Link>
        </header>
        <Container className={styles.container} fluid>
          <Row>
            <Col lg={3} className={styles.fieldCol}>
              <label>Profile:</label>
              <p>{operator.type_of_operation}</p>
            </Col>
            <Col lg={3} className={styles.fieldCol}>
              <label>Package:</label>
              <p>{operator.verification_type}</p>
            </Col>

            <Col lg={3} className={styles.fieldCol}>
              <label>Status:</label>
              <p>{operator.application_status}</p>
            </Col>
            <Col lg={3} className={styles.fieldCol}>
              <label>Location:</label>
              <p>{operator.address}</p>
            </Col>
            <Col lg={3} className={styles.fieldCol}>
              <label>Scope:</label>
              <p>{operator.scope}</p>
            </Col>
            <Col lg={4} md={4} className={styles.fieldCol}>
              <label>Verification before:</label>
              <p>{operator.verification_before}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
};

export default ApplicationCard;
